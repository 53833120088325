import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionRepository } from '../../core';
import { BLINK_SERVICE_HTTP_TOKEN, BlinkService } from '../api-manager';
import { blinkUrls, BlinkUrls } from '@blink/util';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  blinkUrls: BlinkUrls;

  constructor(private sessionRepo: SessionRepository) {
    this.sessionRepo.system$.subscribe(
      system => this.blinkUrls = blinkUrls(system)
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/assets/i18n/') ||
      request.url.includes('assets.blink.online/appVersions') ||
      request.url.includes('onlinestatus.blink.online/online.html')) {
      return next.handle(request);
    }

    const preventBaseUrlAdd =
      Object.values(this.blinkUrls).some(url => request.url.startsWith(url));

    if (!preventBaseUrlAdd) {
      switch (request.context.get(BLINK_SERVICE_HTTP_TOKEN)) {
        case BlinkService.Core:
          request = request.clone({ url: `${this.blinkUrls.blinkApi}/${request.url}` });
          break;
        case BlinkService.Check:
          request = request.clone({ url: `${this.blinkUrls.check}/${request.url}` });
          break;
        case BlinkService.Active:
          request = request.clone({ url: `${this.blinkUrls.active}/${request.url}` });
          break;
        case BlinkService.BaseData:
          request = request.clone({ url: `${this.blinkUrls.baseData}/${request.url}` });
          break;
        case BlinkService.Automation:
          request = request.clone({ url: `${this.blinkUrls.automation}/${request.url}` });
          break;
        case BlinkService.Ai:
          request = request.clone({ url: `${this.blinkUrls.ai}/${request.url}` });
          break;
        case BlinkService.ELearning:
          request = request.clone({ url: `${this.blinkUrls.eLearning}/${request.url}` });
          break;
        case BlinkService.Insight:
          request = request.clone({ url: `${this.blinkUrls.insight}/${request.url}` });
          break;
        case BlinkService.Toolsense:
          request = request.clone({ url: `${this.blinkUrls.toolsense}/${request.url}` });
          break;
        case BlinkService.Invoicing:
          request = request.clone({ url: `${this.blinkUrls.invoicing}/${request.url}` });
          break;
        case BlinkService.WorkOrder:
          request = request.clone({ url: `${this.blinkUrls.workOrder}/${request.url}` });
          break;
      }
    }

    return next.handle(request);
  }

}
