export default {
  blinkSlogan: 'Blink - Die App für Gebäudereiniger',
  all: 'All',
  hello: 'Hello',
  description: 'description',
  ok: 'OK',
  cancel: 'Cancel',
  reset: 'Reset',
  edit: 'To edit',
  finish: 'Finish',
  notice: 'Note',
  content: 'Content',
  back: 'return',
  close: 'Close',
  backToHome: 'Back to home',
  proceed: 'Continue',
  done: 'Done',
  delete: 'Delete',
  successful: 'Successful',
  error: 'Failure',
  ERROR_OCCURRED: 'An error occured or the data is incorrect. Please try again.',
  internetRequired: 'Internet required',
  configuration: 'Configuration',
  logo: 'Logo',
  uploadLogo: 'Upload Logo',
  deleteLogo: 'Delete Logo',
  duplicate: 'Duplicate',
  importExport: 'Import / Export',
  import: 'Import',
  doImport: 'Import',
  export: 'Export',
  doExport: 'Export',
  excelExport: 'Excel-Export',
  excelExportDownloaded: 'Excel-Export downloaded',
  excelExportError: 'Excel-Export failed',
  pdfDownload: 'PDF Download',
  pdfDownloaded: 'PDF downloaded',
  configSaved: 'Configuration saved',
  send: 'Send',
  connecting: 'Connecting...',
  or: 'or',
  select: 'Select',
  today: 'Today',
  invalidValue: 'Invalid value',
  camera: 'Camera',
  gallery: 'Gallery',
  files: 'Files',
  upload: 'Upload files',
  chooseFiles: 'Choose files',
  filesTransferring: 'Sending files...',
  filesTransferred: 'Files transferred successfully.',
  download: 'Download',
  showInactive: 'Show inactive',
  copyLink: 'Copy link',
  linkCopied: 'The link was copied to the clipboard.',
  permissions: 'Permissions',
  email: 'E-Mail',
  title: 'Titel',
  user: 'User',
  logout: 'Sign out',
  system: 'System',
  appVersion: 'Version (App)',
  apiVersionCore: 'Version\n(Core-API)',
  apiVersionCheck: 'Version\n(Check-API)',
  deviceInfo: 'Device information',
  installBlinkTime: 'Install Blink Time',
  installBlinkMe: 'Install Blink Me',
  installing: 'Version {{version}} is being installed...',
  companyRequired: 'Select company',
  companyRequiredInfo: 'Please select a company to continue as administrator.',
  pleaseChoose: 'Please choose',
  pleaseFill: 'Please enter',
  noEntitiesFound: 'No elements found.',
  addCustom: 'Create element:',
  typeToSearchText: 'Please enter 3 letters or more.',
  loading: 'loading data...',
  offline: 'Offline',
  youAreOffline: 'You are offline!',
  by: 'from',
  date: 'Date',
  time: 'Time',
  searchTerm: 'Search term',
  searchLoginUser: 'Search user',
  searchLoginUserPlaceholder: 'Search for firstname or lastname',
  location: 'Object',
  searchLocation: 'Search object',
  searchLocationPlaceholder: 'Search for object name, number or tags',
  deactivate: 'Deactivate',
  active: 'Active',
  inactive: 'Inactive',
  tag: 'Tag',
  tags: 'Tags',
  color: 'Color',
  icon: 'Icon',
  language: 'Language',
  general: 'General',
  yes: 'Yes',
  no: 'No',
  searchThrough: 'Search',
  name: 'Name',
  create: 'Create',
  save: 'Save',
  home: 'Home',
  profile: 'Profile',
  templates: 'Templates',
  noAssignment: 'Not assigned',
  sort: { change: 'Change order', save: 'Save order', saved: 'Order saved' },
  signature: {
    signature: 'Signature',
    pleaseSign: 'Please sign',
    clickHereToSign: 'Click to sign',
    addSignature: 'Add signature',
    save: 'Save',
    delete: 'Delete'
  },
  formError: {
    min: 'Value must be greater than {{value}}.',
    min2: 'Value must be greater than or equal to {{value}}.',
    max: 'Value must be less than or equal to {{value}}.',
    requiredBecause: 'Value required because {{name}} is filled out.',
    isRequired: '{{name}} is required.',
    numberInUse: 'Personnel number is already in use.',
    maxlength: '{{name}} cannot contain more than {{value}} characters.',
    email: 'Please enter a valid e-mail-address.',
    faultyBlinkId: 'Blink ID incorrect'
  },
  scan: {
    choose: 'Assign a new blink ID',
    text: 'Scan the QR code on the registration card.',
    scan_now: 'Scan now',
    enter_manually: 'Enter manually'
  },
  removeImage: 'Delete image',
  tickets: { assignee: 'Assignee', dueTo: 'Due date' },
  ngSelect: {
    typeToSearch: 'Please enter 3 letters or more.',
    loading: 'Loading data...',
    notFound: 'No {{title}} found.',
    clearAll: 'Reset to default'
  },
  help: {
    pageTitle: 'Help',
    text1: 'Our support team will be happy to answer any questions you may have:',
    phone: 'Phone',
    mail: 'E-Mail',
    information: 'Info',
    documentation: 'Online documentation',
    documentationText: 'In our online documentation, you will find instructions and videos on all important aspects of Blink.',
    support: 'Support'
  },
  REGISTRATION: {
    WELCOME: 'Welcome!',
    chooseMethod: 'Which method do you want to use to register?',
    withBlinkId: 'BLINK ID card',
    withCredentials: 'Username / Password',
    REGISTER: 'To register',
    ALREADY_REGISTERED: 'I have a Blink account.',
    INVALID_CARD: 'Card not valid.',
    noBlinkId: 'The scanned QR code is not a valid blink ID',
    scanBlinkId: 'Scan blink ID',
    ERROR: 'Registration could not be completed. Please enter a different e-mail address or mobile number.',
    NO_INTERNET_EXPLORER: 'Unfortunately, Blink does not support Internet Explorer. Please use Microsoft Edge, Firefox or Chrome. Thank you for your understanding.',
    loginNow: 'Register now',
    help: 'Help with registration',
    helpText: "The Blink Manager allows you to manage time recordings & schedules, employees and the system configuration. The Blink Manager app is only for superiors, object managers, team leads and administration! For time recording, use Blink Time or Blink me! <br/> <br/> There are two methods you can use to log in: <br/> <br/> <b> Log in with a Blink ID card < / b> <br/> <br/> If you have a Blink ID card then use this method. When you register for the first time, you will be asked for an e-mail address or mobile phone number. We need this to send you a one-time password for registration. You don't need to remember the one-time password. When you log in again, we will send you a new one-time password. <br/> <br/> <b> Login with username & password </b> <br/> <br/> You will only receive your username and password upon request from your system administrator. This method is only available to users with administrative rights. <br/> <br/> <b> Support </b> <br/> <br/> If you have problems logging in, write an e-mail to support@blink.de or call 0911 240 330 22.",
    userOffline: 'Please establish an internet connection in order to be able to use Blink me.',
    login: 'Register now',
    codeError: 'Please check your entry and try again.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Congratulation!',
    YOU_DID_IT: 'You did it.',
    SIGNED_IN: 'Registered',
    SIGNED_IN_TEXT: 'You have successfully logged in and can now use the app.',
    START_USAGE: 'You can now use the app.',
    START: 'Begin'
  },
  FORGOT_PASSWORD: {
    emailSent: 'An e-mail with which you can change your access data has been sent to you.',
    FORGOT_PASSWORD: 'Forgot your password?',
    errors: {
      notAllowed: 'A password cannot be reset for this user.',
      invalidToken: 'The code to reset your password has expired. Please request a new code.'
    },
    reset: {
      backToRoot: 'Back to the login page',
      renewCode: 'Request new code',
      resetPassword: 'Reset password',
      success: 'Your password has been reset successfully.',
      password1: 'Your new password',
      password2: 'Repeat password',
      validationError: 'The password does not comply with our password guidelines!',
      passwordsNotEqual: 'The passwords are not identical! Please check your entry.',
      passwordValidation: 'The password must be at least 6 characters long and contain a letter, a number and a special character.'
    },
    FORGOT_PASSWORD_TEXT: 'Please enter your email address and the target system to reset your password.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-mail'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Check data',
    TEXT: 'If we need to reset your password, we need your e-mail address and optionally your mobile number.',
    EMAIL: 'E-mail address',
    MOBILE: 'Mobile phone number'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Enter the code',
    EMAIL_TEXT: 'Enter the code that we have sent to the following e-mail address:',
    SMS_TEXT: 'Enter the code that we have sent to the following mobile number:',
    NO_CODE: "I haven't received a code.",
    CODE_LENGTH_ERROR: 'The code entered is longer than six characters. Please re-enter your code.',
    CODE_SENT: 'The code has been sent.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'No code',
    TEXT1: "Didn't get a code?",
    RESEND_CODE: 'Send the code again',
    TEXT2: 'If your e-mail address or mobile number stored in the system has changed, please contact your administrator.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Please enter your Blink ID and your code. You can find all the information on your Blink ID Card.',
    textCredentials: 'Your system administrator must provide you with your username and password.',
    BLINK_ID_INVALID: 'Incorrect Blink ID',
    loginCardNotFound: 'The Blink ID is either already assigned or does not exist',
    WRONG_CREDENTIALS: 'The information you entered is incorrect. Please check your entry.',
    loginViaBlinkId: 'Registration with Blink ID',
    loginViaCredentials: 'Registration with access data',
    CODE: 'code',
    codePlaceholder: 'Your personal access code',
    company: 'system',
    password: 'password',
    FORGOT_PASSWORD: 'I have forgotten my password',
    username: 'e-mail'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'ID',
    TEXT_1: 'So that we can clearly identify you, we will send you a one-time code by SMS or e-mail.',
    TEXT_2: 'Please enter your mobile number or e-mail address.'
  },
  update: {
    update: 'Update',
    latestVersion: 'A new version is available. Please update your app to take advantage of all the benefits.',
    warnVersion: 'You are using an outdated version of this app. Please update as soon as possible.',
    blockVersion: 'You are using an outdated version of this app that is no longer supported. Please update the app now.'
  },
  filesTransferringWait: 'Please wait while the files are transmitted.',
  emailConfig: 'E-mail configuration',
  searchTitle: 'Search',
  inProgress: 'In Progress',
  video_offline: 'Videos cannot be viewed in offline mode, please connect to the internet.',
  location_offline: 'The object is not saved offline, please connect to the internet to retrieve the QR code.',
  invalidObjectCode: 'No valid object code.',
  ERROR: {
    ERROR_400: 'An error has occurred. Please try again later. (Error code: 400)',
    ERROR_403: 'You do not have permission to perform this action',
    REFRESH_TOKEN_403: 'Your access data is no longer valid. Please sign in again!',
    ERROR_500: 'An error has occurred. Please try again later. (Error code: 500)'
  },
  languages: {
    ar: 'Arabic',
    de: 'German',
    es: 'Spanish',
    fr: 'French',
    hr: 'Croatian',
    it: 'Italian',
    pt: 'Portuguese',
    ru: 'Russian',
    bg: 'Bulgarian',
    en: 'English',
    fa: 'Persian',
    el: 'Greek',
    hu: 'Hungarian',
    pl: 'Polish',
    ro: 'Romanian',
    tr: 'Turkish',
    uk: 'Ukrainian',
    cs: 'Czech',
    sk: 'Slovak'
  },
  list: {
    entityPluralDefault: 'Results',
    emptyState: 'No {{entityPlural}} exists.',
    noResults: 'No {{entityPlural}} could be found.',
    selectAll: 'Select all {{entityPlural}}',
    massAction: 'Promotion for {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Select all {{count}}',
    massActionDeSelectAllGlobal: 'Deselect all {{count}}'
  },
  now: 'Now',
  allCompanies: 'All clients',
  createDate: 'Creation date',
  company: 'client',
  new: 'New',
  of: 'from',
  request: {
    deleteTitle: 'Delete {{item}}',
    confirmDelete: "Do you really want to delete the entry '{{itemName}}'?",
    itemAdded: '{{item}} has been created.',
    itemAddedError: '{{item}} could not be created.',
    itemSaved: '{{item}} has been saved.',
    itemSavedError: '{{item}} could not be saved.',
    itemRemoved: '{{item}} has been deleted.',
    itemRemovedError: '{{item}} could not be deleted.'
  },
  news: { pageTitle: 'News', edit: 'Edit news reports' },
  profiles: {
    loginProfiles: 'Login Profile',
    add: 'Login Profile',
    logout: 'If you log out, all local data and profiles will be deleted! Do you want to log out?',
    login: 'Register',
    deleteConfirmation: 'Do you really want to delete the profile?',
    changed: 'Profile changed successfully',
    deleted: 'Profile successfully deleted'
  },
  deleteAttachment: {
    confirmTitle: 'Delete attachment',
    confirmText: 'Are you sure you want to delete this attachment?'
  },
  comments: {
    addComment: 'add comment',
    addCommentInfo: 'Comment is visible to anyone who can see this ticket.',
    comment: 'comment',
    deleteComment: 'Delete comment',
    deleteCommentConfirmation: 'Are you sure you want to delete the comment?'
  },
  confirm: 'Confirm',
  firstName: 'First name',
  lastName: 'Last name',
  status: 'status',
  attachment: 'Attachment'
};
