import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "dayjs";

@Pipe({
  name: 'dayjs'
})
export class DayjsPipe implements PipeTransform {
  transform(value: string | number | Date, format: string) {
    return value ? dayjs(value).format(format) : null;
  }
}
