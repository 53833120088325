<ui-page [title]="t.global.configuration"
         [menuButton]="true"
         [saveButtonVisible]="isOnline"
         [saveButtonDisabled]="!(form && form.valid)"
         (saveButtonClick)="save()">
  <div class="ion-padding">

    <ng-container *ngIf="!isOnline">
      <ui-empty-state [icon]="BlinkIcon.Offline">
        {{ t.results.offline | translate }}
      </ui-empty-state>
    </ng-container>

    <ng-container *ngIf="isOnline">

      <h3 style="margin: 0;">{{ companyName }}</h3>

      <form *ngIf="form" [formGroup]="form">

        <h5 style="margin: 10px 0 5px">{{ t.global.logo | translate }}</h5>
        <div>
          <small class="text-gray">{{ t.companyConfig.logoInfo | translate }}</small>
        </div>

        <div>
          <img *ngIf="form && form.value.ReportLogoBase64Encoded" [src]="form.value.ReportLogoBase64Encoded"/>
        </div>


        <ion-button (click)="uploadImage()">
          <ui-icon [icon]="BlinkIcon.Camera" class="mr-5"></ui-icon>
          {{ t.global.uploadLogo | translate }}
        </ion-button>
        <ion-button *ngIf="form && form.value.ReportLogoBase64Encoded"
                    color="danger"
                    (click)="removeLogo()">
          <ui-icon [icon]="BlinkIcon.Delete" class="mr-5"></ui-icon>
          {{ t.global.deleteLogo | translate }}
        </ion-button>

        <ui-input type="text"
                  formControlName="ReportFooterText"
                  [label]="t.companyConfig.footer | translate"
                  labelPlacement="floating"
        ></ui-input>
        <small class="ion-padding-start">{{ t.companyConfig.footerInfo | translate }}</small>

      </form>
    </ng-container>

  </div>

</ui-page>
