export default {
  home: {
    pageTitle: 'Домашня сторінка',
    welcome: 'Ласкаво просимо до програми Blink me. Розважайтеся в чаті,\n' +
      '\n' +
      'Ваша команда Blink',
    startChat: 'почати',
    ticket: 'квиток',
    timeLicenseMissing: 'Вам не призначили ліцензію Blink Time!'
  },
  conversations: { pageTitle: 'Новини', yourChats: 'Ваші чати', chat: 'Чат' },
  settings: {
    settings: 'Налаштування',
    pushEnabled: 'Отримувати push-повідомлення',
    system: 'система',
    languageSelection: 'вибір мови',
    currentSettings: 'Поточні налаштування',
    user: 'користувача',
    appVersion: 'Версія (програма)',
    apiVersion: 'Версія (API)',
    deviceInfo: 'Версія пристрою',
    buildVersion: 'версія (збірка)',
    helpAndDataPolicy: 'Допомога та захист даних',
    text1: 'Наша служба підтримки буде рада допомогти вам, якщо у вас виникнуть запитання:',
    phone: 'телефон',
    mail: 'електронна пошта',
    information: 'інформація',
    documentation: 'Онлайн документація',
    documentationText: 'У нашій онлайн-документації ви знайдете інструкції та відео для всіх важливих областей Blink.',
    dataPolicy: 'захист даних',
    support: 'Підтримка',
    moreActions: 'Подальші дії',
    protocol: 'протокол',
    showChatUserSecret: 'Поділіться чатом з іншим пристроєм',
    scanCodeAnotherDevice: 'Відскануйте наведений нижче QR-код на своєму новому пристрої або введіть свій особистий код чату на новому пристрої!',
    yourPersonalCode: 'Ваш особистий код чату',
    permissions: {
      title: 'Дозволи',
      grantPermission: 'надати дозвіл',
      camera: 'камера',
      cameraHint: "Необхідний для запису часу через об'єктний код.",
      nfc: 'NFC',
      nfcHint: 'Необхідний для запису часу через тег NFC.',
      media: 'Візьміть зображення та зображення з бібліотеки',
      mediaHint: 'Необхідно для зйомки зображень і документів, пов’язаних із вашим часом і квитками.',
      location: 'Місцезнаходження',
      locationHint: 'Необхідно записати ваше місцезнаходження під час запису часу.',
      locationDisabledOnSystem: 'Служби локації вимкнено. Активуйте це в налаштуваннях свого пристрою.',
      notifications: 'Push-повідомлення',
      notificationsHint: 'Необхідно для отримання push-сповіщень для повідомлень чату, блимаючих сповіщень і наклейок.'
    }
  },
  connectionState: {
    connecting: 'Підключити...',
    connected: "Зв'язані разом.",
    disconnecting: "З'єднання розірвано...",
    disconnected: "зв'язок втрачений",
    denied: "Не вдалося встановити з'єднання."
  },
  noOneToFind: 'Жодного не знайдено.',
  nothingToFind: 'Нічого не знайдено.',
  noLocationFound: "Об'єктів не знайдено.",
  searchPlaceholder: 'шукати...',
  employeeSearchPlaceholder: "Ім'я. Ім'я",
  typeToSearch: 'Натисніть, щоб шукати...',
  typeToSearchEmployee: 'Введіть ім’я людини, щоб розпочати чат',
  createChatWithEmployee: 'Шукає працівників',
  createChatWithLocationTeam: "Об'єкт пошуку",
  createTeamleaderChat: 'Усі мої працівники',
  yourLanguageSelection: 'Ваш вибір мови:',
  loginInfo: 'Щоб зареєструватися, відскануйте свій особистий QR-код:',
  updating: 'Blink me оновлюється.',
  notice: 'Повідомлення',
  importantNotice: 'Важлива ПРИМІТКА',
  continue: 'Далі',
  intro: {
    slide1: {
      header: 'Ласкаво просимо',
      text: 'Розважайтеся з додатком Blink me.'
    },
    slide2: {
      header: 'Чат',
      text: 'Спілкуйтеся безпосередньо зі своїми колегами або дізнавайтеся останні новини в цікавих групах.'
    },
    slide3: {
      header: 'квитки',
      text: 'Тут ви можете переглядати свої завдання та створювати програми.'
    }
  },
  pleaseWait: 'Будь ласка, зачекайте...',
  skip: 'Пропустити',
  startAgain: 'Почати заново',
  lock: {
    createPinHeader: 'Ваш особистий PIN-код',
    createPinInfo: 'Щоб захистити свої дані, тут можна встановити PIN-код, який потрібно вводити кожного разу, коли ви використовуєте програму Blink me.',
    validatePin: 'Введіть PIN ще раз.',
    tryAgain: 'PIN-код не збігається. Будь ласка, спробуйте ще раз.',
    triedToOften: 'На жаль, ваш PIN-код не збігається. Бажаєте перезапустити процес чи пропустити його та зробити це пізніше?',
    enterPinHeader: 'PIN-код',
    enterPinInfo: 'Введіть PIN-код, щоб розблокувати програму.',
    remainingTrys: 'Ще {{amount}} спроб. Потім вам доведеться знову увійти в програму.',
    tooManyEnters: 'На жаль, реєстрація за допомогою PIN-коду більше неможлива. Будь ласка, увійдіть ще раз за допомогою своєї картки Blink ID.',
    reSignIn: 'Нова реєстрація',
    setpin: 'Введення PIN-коду під час використання програми',
    skip: 'Ви не хочете призначити PIN-код зараз? Ви можете будь-коли активувати цю функцію в налаштуваннях програми, в тому числі пізніше.',
    dontUsePin: 'не використовуйте PIN-код',
    deactivatePinHeader: 'Вимкнути PIN-код',
    deactivatePinInfo: 'Введіть PIN-код, щоб вимкнути захист.'
  },
  active: {
    yourTickets: 'Ваші квитки',
    createNewTask: 'Створіть новий квиток',
    assignee: 'редактор',
    location: "об'єкт",
    dueDate: 'Термін погашення',
    createTask: 'Створити квиток',
    taskCreated: 'Квиток створено.',
    backToTasks: 'Повернемося до квитків',
    createdAt: 'створено на',
    attachments: 'Додатки',
    addFileOrImage: 'Додайте файл або фото',
    addImage: 'додати фото',
    comments: 'Коментарі',
    addComment: 'додати коментар',
    addCommentInfo: 'Коментар доступний для всіх, хто може переглядати цей квиток.',
    commentCreated: 'Коментар збережено.',
    showClosed: 'Переглянути готові квитки',
    resetFilter: 'скинути всі фільтри',
    changeTicketStatusTo: 'Змінити статус заявки на:',
    editTask: 'Редагувати квиток',
    saveTask: 'Зберегти квиток',
    taskSaved: 'Квиток збережено.',
    deleteImage: 'Чітке зображення',
    deleteImageConfirmation: 'Ви справді хочете видалити зображення?',
    deleteAttachment: 'Видалити вкладення',
    deleteAttachmentConfirmation: 'Ви впевнені, що бажаєте видалити цей вкладений файл?',
    deleteComment: 'Видалити коментар',
    deleteCommentConfirmation: 'Ви справді хочете видалити коментар?',
    changeAssignee: 'Змінити редактор',
    duplicateTask: 'Дублікат квитка',
    duplicateTaskConfirmation: 'Хочете зробити дублікат цього квитка?',
    taskDuplicated: 'Квиток був дублікат.',
    deleteTask: 'Видалити квиток',
    deleteTaskConfirmation: 'Ви впевнені, що хочете видалити цей квиток?',
    taskDeleted: 'Квиток видалено.',
    assigneeChanged: 'Процесор поміняний.',
    statusChanged: 'Статус квитка змінено.',
    startDate: 'почати',
    endDate: 'Кінець',
    doctorVisitDate: 'Дата відвідування лікаря',
    doctorVisitDescription: 'Дата візиту лікаря: {{date}}',
    chooseDate: 'Виберіть дату',
    comment: "Коментар (необов'язково)",
    dateError: 'Кінцева дата не може передувати даті початку',
    employee: 'Співробітники',
    pleaseConnectToLoad: 'Щоб завантажити квитки, підключіться до Інтернету.'
  },
  emptyState: {
    chats: 'Створіть чат!',
    chat: 'Напиши перше повідомлення :)',
    tasks: 'Немає квитків.',
    attachments: 'Немає вкладень.',
    comments: 'Немає коментарів.',
    vacation: 'На цей рік відпустка не введена'
  },
  formError: {
    min: 'Значення має бути більше ніж {{value}}.',
    min2: 'Значення має бути більше або дорівнювати {{value}}.',
    max: 'Значення має бути менше або дорівнювати {{value}}.',
    requiredBecause: 'Необхідне значення, оскільки {{name}} заповнено.',
    isRequired: '{{name}} є обов’язковим.',
    numberInUse: 'Табельний номер вже використовується.',
    maxlength: '{{name}} не може містити більше {{value}} символів.',
    faultyBlinkId: 'Blink ID неправильний'
  },
  ngSelect: {
    typeToSeach: 'Будь ласка, введіть 3 літери або більше.',
    loading: 'завантаження даних...',
    notFound: '{{title}} не знайдено.',
    clearAll: 'Скинути до замовчування'
  },
  placeholder: {
    employee: 'Пошук по імені та прізвищу, табельному номеру, Blink ID',
    location: "Пошук за назвою, номером або кодом об'єкта",
    date: 'Виберіть дату',
    searchTasks: 'Пошук квитків...'
  },
  hasChat: 'Розпочати чат зараз',
  hasNoChatYet: 'На жаль, я ще не встановив додаток Blink me.',
  noBlinkActiveLicence: 'Без ліцензії',
  noBlinkActiveLicenceInfo: 'Зараз у вас немає ліцензії на цю програму. Будь ласка, зверніться до свого керівника.',
  noBlinkMeLicence: 'Без ліцензії',
  noBlinkMeLicenceInfo: 'Цю програму не можна використовувати з вашою системою. Будь ласка, використовуйте Blink Time із програми або Playstore.',
  noSuitableAppFound: 'Не знайдено програми для відкриття файлу.',
  myChats: 'Ваші чати',
  hasNoChatsHome: 'Почніть спілкуватися зараз! Ви можете спілкуватися з усіма колегами та брати участь у об’єктових і командних групах.',
  hasNoTicketsHome: 'Немає відкритих квитків.',
  myTickets: 'Ваші квитки',
  myEmployees: 'Мої співробітники',
  group: 'група',
  confirmLogoutWithChat: 'Якщо ви вийдете, ваші повідомлення чату буде видалено. Бажаєте продовжити?',
  confirmLogout: 'Ви дійсно хочете вийти?',
  message: 'Новини',
  messageNotDecryptable: 'Не вдалося розшифрувати повідомлення.',
  chat: {
    userAlreadyExists: 'Ви вже налаштували чат на іншому пристрої. Щоб мати можливість використовувати чат на цьому пристрої, ви повинні зв’язати пристрої. Крім того, ви можете перезапустити налаштування, але тоді чат більше не можна буде використовувати на іншому пристрої.',
    linkDevices: 'Пристрої підключення',
    withoutChat: 'Продовжити без чату',
    setupAgain: 'Скинути чат',
    setupAgainText: 'Якщо ви знову налаштуєте чат, усі повідомлення з існуючих окремих чатів буде видалено, і чат більше не можна буде використовувати на інших пристроях! Бажаєте продовжити?',
    LinkDevicesText: 'Щоб зв’язати пристрої для чату, відкрийте «Налаштування» на іншому пристрої та виберіть «Поділитися чатом з іншим пристроєм».',
    personalToken: 'Ваш особистий код чату',
    wrongCode: 'Наданий код чату неправильний.',
    beingPrepared: 'Чат готується.',
    twilioAccessDenied: 'Не вдалося встановити підключення до служби чату. Зверніться до служби підтримки Blink.',
    notificationsDenied: 'Сповіщення вимкнено на вашому пристрої/браузері. Перейдіть у налаштування та ввімкніть сповіщення, щоб Blink me отримував сповіщення. Потім ви можете активувати сповіщення в особистих налаштуваннях.',
    deleted: 'Чат видалено',
    unavailable: 'Чат недоступний',
    participant: 'Учасник',
    teamChatDescription: 'Усі співробітники, для яких ви зареєстровані як керівник, додаються до цієї групи чату.',
    locationChatDescription: 'Співробітники додаються автоматично, якщо вони мають розклад для цього об’єкта протягом останніх чотирьох тижнів або наступних чотирьох тижнів.',
    createEmployeeChatDescription: 'З вибраним співробітником буде створено приватний чат.',
    teamChatCreationTitle: 'Створити групу чату',
    createTeamChatDescription: 'Створюється чат-група, в яку автоматично додаються всі співробітники, для яких ви зареєстровані як керівник.',
    createLocationChatDescription: "Для вибраного об'єкта створюється група чату, куди автоматично додаються співробітники, якщо у них є план на це протягом останніх чотирьох тижнів або наступних чотирьох тижнів."
  },
  protocol: {
    SEND_EMAIL: 'Надіслати електронною поштою',
    DELETE: 'Очистити журнал',
    deleteMessage: 'Ви справді хочете видалити журнал? Цю дію не можна скасувати.',
    Send: 'Надіслати',
    SuccessMessage: 'Журнал успішно надіслано до служби підтримки Blink.',
    ConfirmMessage: 'Спочатку зв’яжіться з командою підтримки та надішліть журнал лише за запитом.',
    Description: 'опис проблеми'
  },
  timeTracking: {
    title: 'Відстеження часу',
    start: 'старт',
    startDescription: 'Натисніть «Пуск», щоб почати відлік часу',
    started: 'розпочато',
    stop: 'Зупинитися',
    duration: 'Тривалість часу',
    syncButtonLabel: 'Час завантаження записується в режимі офлайн',
    offlineSyncMessage: 'Щоб передати збережений час, ви повинні бути онлайн.',
    successfulSync: 'Часи завантажуються',
    scanNowQrCode: 'QR код',
    scanNowQrCodeDescription: "Відскануйте QR-код в об'єкті.",
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Притуліть смартфон до наклейки NFC на об’єкті.',
    nfcDescription: 'Виявлення за допомогою NFC: притуліть свій мобільний телефон до об’єктного коду',
    noTimeTrackingMethodAvailableDescription: 'Для цього користувача не налаштовано метод відстеження часу. Будь ласка, зверніться до свого керівника групи.',
    noLocationCode: 'Немає доступного коду',
    noLocationCodeDescription: "Виберіть цей параметр, якщо на об’єкті немає QR-коду чи наклейки NFC. Надання коментаря обов'язкове.",
    noLocationCodeConfirm: 'Ви впевнені, що хочете почати без об’єктного коду? Ви повинні надати коментар, щоб зупинити.',
    wrongCode: "Відсканований QR-код не є об'єктним кодом!",
    readNotice: 'Будь ласка, прочитайте інструкції!',
    warning: 'УВАГА',
    lessThanAMinuteTitle: 'Повний запис часу',
    lessThanAMinute: 'Ви почали менше 1 хвилини тому. Ви впевнені, що хочете зупинитися?',
    continue: 'Продовжити запис',
    timeAlert: {
      differenceInMinutesHint: 'Час на вашому смартфоні відрізняється від часу на сервері. Різниця реєструється',
      differentTimeZoneHint: 'Часовий пояс вашого смартфона відрізняється від часового поясу сервера. Різниця реєструється.'
    },
    leaveComment: {
      withCode: "Інформація про об'єкт/послуги була нечіткою. Скажіть, будь ласка, в якому закладі/службі ви працювали.",
      withoutCode: "Жоден об'єктний код не був захоплений під час запуску чи зупинки. Будь ласка, поясніть, чому ви почали/зупинили без об’єкта."
    },
    comment: 'коментар',
    enterComment: 'Прокоментуйте відлік часу',
    differentQrCode: {
      title: "Змінити об'єкт?",
      continueInLocation: 'Продовжуйте працювати в {{locationName}}.',
      automaticStopInLocation: 'Запис часу в {{locationName}} припиниться автоматично.',
      stopInLocation: 'Зупинити запис часу через {{locationName}}.',
      stop: 'Зупинитися',
      stopNoLocation: 'Зупинити запис часу.',
      continueNoOldLocation: "Запис часу для попереднього об'єкта автоматично зупиняється.",
      continueWorking: 'Продовжувати працювати',
      continueWorkingInNewLocation: 'Продовжуйте працювати в новій власності.',
      stopWithLocationActivity: 'Зупинити запис часу {{locationActivity}}.',
      continueWithNewLocationActivity: 'Продовжити роботу з {{locationActivity}}',
      stopOldLocationActivity: 'Запис часу на {{locationActivity}} зупиняється автоматично.',
      stopOldNoLocationActivity: 'Запис часу без виконання автоматично припиняється.',
      continueNoLocationActivity: 'Продовжити роботу без продуктивності.',
      sameLocationTitle: 'Змінити продуктивність?',
      differentLocationTitle: "Змінити об'єкт?"
    },
    saveAndStop: 'Зберегти та зупинити',
    noLocationScanned: "- Жодного об'єкта -",
    noLocationFound: "- Об'єкт незрозумілий -",
    timerDifference: '{{h}} годин {{mm}} хвилин',
    stopSuccess: 'Час успішно записано',
    stopSuccessToast: 'Запис часу успішно зупинено.',
    stopTimeTracking: 'Зупинитися',
    manual: {
      time: 'час',
      locationSelect: "Виберіть об'єкт",
      loadMore: 'завантажити ще',
      trackTime: 'Рекордний час',
      location: "об'єкт",
      locationActivity: 'Продуктивність',
      date: 'Дата',
      startTime: 'Від',
      endTime: 'Поки',
      create: 'Підтвердити',
      createAgain: "Зафіксуйте додатковий час для цього об'єкта",
      errorUntil: 'Від часу має бути менше, ніж До часу',
      worktimeDifference: 'Робочі години:',
      worktimeDifferenceValue: '{{hours}} годин {{minutes}} хвилин',
      worktimeDifferenceValueMinutes: '{{minutes}} хвилин',
      success: 'Час успішно записано',
      break: 'Перерва:',
      breakMinutes: '{{minutes}} хвилин',
      breakHoursAndMinutes: '{{hours}} годин {{minutes}} хвилин',
      noActivity: '- немає продуктивності -',
      locationPlaceHolder: "Пошук за назвою, номером або кодом об'єкта",
      conflictError: 'У вказані періоди вже є запис часу. Перекриття не допускаються. Будь ласка, перевірте свою інформацію.',
      currentDayDuration: 'Вже записані',
      expectedDuration: 'Очікувані години роботи',
      expectedDurationIncludedCurrent: 'включаючи поточний вхід'
    },
    differenceInMinutesHint: 'Час на вашому смартфоні відрізняється від часу на сервері. Різниця реєструється',
    differentTimeZoneHint: 'Часовий пояс вашого смартфона відрізняється від часового поясу сервера. Різниця реєструється.',
    codeNotAssignable: 'Відсканований QR-код не можна призначити!',
    proposed: {
      capture: 'захоплення',
      time: 'час',
      captureTime: 'Рекордний час',
      button: 'Підтвердити',
      title: 'Підтвердити години роботи',
      includingBreak: '(включаючи перерву)',
      from: 'Від',
      to: 'Поки',
      break: 'Перерва',
      hour: 'Годинник',
      confirm: {
        description: 'Підтвердьте ваш робочий час',
        confirm: 'Так, правильно',
        editButton: 'Ні, це неправильно'
      },
      edit: {
        description: 'Будь ласка, вкажіть тривалість',
        breakIncluded: 'Включаючи перерву {{ duration }}',
        comment: 'коментар',
        yourComment: 'ваш коментар',
        minutes: 'хвилин',
        hours: 'години'
      },
      reasons: {
        overtime: 'Понаднормова робота за рахунок клієнта',
        faster: 'Готово швидше',
        other: 'Різне',
        representative: 'Я був на заміні'
      },
      description: 'Підтвердьте свій робочий час, як тільки ви закінчите роботу в об’єкті.'
    },
    running: 'Триває запис часу'
  },
  timeFramePicker: {
    title: 'Виберіть період',
    today: 'Сьогодні',
    currentWeek: 'Поточний тиждень',
    currentMonth: 'Поточний місяць',
    custom: 'Custom',
    startDate: 'почати',
    endDate: 'Кінець'
  },
  timeSheet: {
    title: 'Час і планування',
    noPlannings: 'Ніякого планування',
    planned: 'Планується',
    workingTime: 'робочі години',
    workloadDuration: 'Тижневий час роботи',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Слід',
    workedDuration: 'Є',
    hasWorkload: 'Робота в цей день',
    pleaseConnectToLoad: 'Будь ласка, підключіться до Інтернету, щоб завантажити ваш час.',
    footer: {
      planned: 'Планується',
      worklogsTotal: 'Загальна кількість годин',
      workloadTotal: 'Години згідно договору',
      difference: 'різниця'
    },
    homepageTitle: 'Ваш робочий день'
  },
  planning: {
    modifyMinutes: 'Єдина ставка:',
    planRoute: 'Плануйте маршрут',
    title: 'планування',
    includingPause: 'Перерва:',
    noNavAppFound: 'Навігаційну програму не знайдено'
  },
  worklog: {
    title: 'Записаний час',
    absenceTitle: 'відсутність',
    workingTime: 'робочі години',
    breakDuration: 'Перерва',
    reduce: 'єдина ставка',
    attachments: 'Додатки',
    comments: 'Коментарі',
    comment: 'коментар',
    noLocation: "Об'єкт не вказано",
    changedBy: 'Час змінено з {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Ви впевнені, що бажаєте видалити цей вкладений файл?',
    attachmentDeleted: 'Вкладення видалено',
    confirmCommentDelete: 'Ви впевнені, що хочете видалити цей коментар?',
    commentDeleted: 'Коментар видалено'
  },
  tabs: {
    home: 'додому',
    times: 'разів',
    chat: 'Чат',
    tickets: 'квитки',
    more: 'більше'
  },
  profile: {
    title: 'профіль',
    testing: 'Тестування',
    testingDescription: 'Ця область видима лише при підключенні до тестової системи.',
    testingAllowQrCodeOnWeb: 'Дозволити ручне введення QR-коду',
    pushNotifications: 'Push-повідомлення',
    reloadUserContext: 'Перезавантажте UserContext',
    pushNotificationSuccessful: 'Push-повідомлення успішно отримано',
    editProfilePicture: 'змінити зображення профілю',
    editProfilePictureTitle: 'Виберіть розділ',
    pushNotificationNotice: 'Для тесту потрібна авторизація «Редагувати налаштування клієнта».',
    send: 'Надіслати',
    profilePicture: 'Зображення профілю'
  },
  help: { title: 'Довідка' },
  myVacations: {
    vacation: 'Відпустка',
    amountAvailableVacationDays: '{{amount}} днів відпустки ще доступно',
    totalVacationDays: 'Право на відпустку {{ total }} днів',
    remainingVacationDays: '({{ total }} дн. {{ sign }} {{ remaining }} дн. залишилася відпустка {{ previousYear }})',
    xDaysVacations: '{{ total }} днів відпустки',
    title: 'Ваша відпустка'
  },
  externalLinks: {
    title: 'зовнішні посилання',
    notice: 'Це посилання надано вашою компанією. Ви залишаєте систему Blink.'
  },
  stickers: {
    title: 'Ваші наклейки',
    sticker: 'наклейки',
    sender: 'одержувач',
    text: 'Щодо',
    date: 'Дата',
    emptyState: 'На жаль, у вас ще немає наклейок',
    senderDescription: 'Цю наклейку надіслав вам {{sender}} {{date}}',
    readed: 'читати',
    notReaded: 'ще не читав',
    newStickerToaster: 'Нова наклейка Blink!',
    open: 'ВІДЧИНЕНО',
    next: 'Далі',
    titleCases: {
      case1: 'Вітаємо з першою наклейкою!',
      case2: 'Чудово, тепер у вас уже є <b>{{count}}</b> наклейки!',
      case3: 'Вітаємо з 10-ю наклейкою!',
      case4: '30 наклейок - вау!',
      case5: 'Тепер тут стає багатолюдно: <b>{{count}}</b> наклейки!'
    },
    confirmDelete: 'Ви справді хочете видалити ці наклейки?'
  },
  autoTranslate: {
    isTranslated: 'Автоматично перекладено',
    translationError: 'Автоматичний переклад наразі неможливий'
  },
  migration: {
    wrongVersion: 'У поточній версії Blink me вхід через програму Blink Time не підтримується. Оновіть програму Blink me до останньої версії'
  },
  eLearning: {
    pendingTab: 'Завдання ({{value}})',
    completedTab: 'Готово ({{value}})',
    completed: 'Виконано',
    pending: 'ВІДЧИНЕНО',
    dueFalling: 'Прострочена',
    title: 'Електронне навчання',
    course: 'курс',
    dueDate: 'Дійсний до',
    topics: 'предметів',
    completeDate: 'Закінчив далі',
    status: { Pending: 'ВІДЧИНЕНО', Done: 'завершено' },
    infos: {
      pendingExists: 'Ви можете знайти всі свої відкриті навчальні курси тут.',
      finishOverdue: 'Будь ласка, якнайшвидше пройдіть навчання, яке вже призначено.',
      allCompleted: 'Чудово! Ви успішно пройшли всі навчальні курси.',
      emptyState: 'Вам не призначено навчання.'
    }
  },
  checklists: {
    title: 'Форми',
    description: "Заповнити форму для об'єкта",
    button: "Виберіть об'єкт"
  },
  pendingTab: 'Виконання ({{value}})',
  completedTab: 'Завершено ({{value}})',
  workOrders: {
    title: 'Замовлення',
    homepageTitle: 'Ваші замовлення',
    responsible: 'Відповідальний',
    customerContact: 'Контакт з клієнтом',
    trackedTime: 'Запис часу для замовлення №{{value}}',
    show: 'Переглянути замовлення',
    trackInfo: {
      confirmAndAcknowledge: 'Підтвердьте позиції після впровадження та проведіть приймальне випробування на місці.',
      acknowledgeOnly: 'Будь ласка, проведіть перевірку на місці.',
      confirmOnly: 'Будь ласка, підтвердіть позиції після впровадження.'
    },
    pleaseConnectToLoad: 'Підключіться до Інтернету, щоб завантажити замовлення.',
    status: { new: 'новий', inProgress: 'Виконання', completed: 'Виконано' },
    emptyState: 'Немає відкритих замовлень.',
    emptyStateCompleted: 'Немає виконаних замовлень.',
    details: {
      title: 'Деталі замовлення',
      positionsTab: 'Позиції ({{value}})',
      acknowledgementsTab: 'зменшується ({{value}})'
    },
    acknowledgement: {
      title: 'прийняття',
      start: 'Почати прийом',
      acknowledgedAtDate: 'Видалено на',
      itemSelectDescription: 'Виберіть усі позиції, які потрібно видалити.',
      customerInformationDescription: 'Будь ласка, надайте контактні дані замовника (покупця). Після прийняття на цю електронну адресу буде надіслано підтвердження.',
      created: 'Прийняття успішно збережено',
      selectAll: 'Вибрати все',
      emptyState: 'Приймань немає.'
    },
    items: {
      items: 'позиції',
      acknowledge: 'Підтвердити позицію',
      confirmAcknowledge: 'Цим ви підтверджуєте, що робота була виконана. Статус позиції оновлюється на «Завершено».',
      status: {
        open: 'ВІДЧИНЕНО',
        planned: 'Планується',
        performed: 'Готово',
        acknowledged: 'Видалено',
        billed: 'Виставлений рахунок'
      },
      emptyState: 'Позицій немає.'
    },
    trackTime: {
      description: 'Виберіть роботу, для якої ви хочете відстежувати час.',
      chooseWorkOrder: 'Виберіть порядок',
      choosePosition: 'Виберіть позицію'
    }
  }
};
