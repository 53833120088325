export default {
  cancel: 'Elvetél',
  blinkSlogan: 'Blink - Az alkalmazás az épülettakarítók számára',
  all: 'Minden',
  hello: 'Hello',
  description: 'Leírás',
  ok: 'Oké',
  reset: 'Reset',
  edit: 'Szerkesztés',
  finish: 'Lock',
  notice: 'Megjegyzés:',
  content: 'Tartalom',
  back: 'Vissza',
  close: 'Zárja be a',
  backToHome: 'Vissza a honlapra',
  proceed: 'További',
  done: 'Kész',
  delete: 'Törölje a  címet.',
  successful: 'Sikeres',
  error: 'Hiba',
  ERROR_OCCURRED: 'Hiba történt, vagy az adatok nem helyesek. Kérjük, próbálja meg újra.',
  internetRequired: 'Internet szükséges',
  configuration: 'Konfiguráció',
  logo: 'Logó',
  uploadLogo: 'Logó feltöltése',
  deleteLogo: 'Logó törlése',
  duplicate: 'Duplikátum',
  importExport: 'Import / export',
  import: 'Import',
  doImport: 'Import',
  export: 'Exportálás',
  doExport: 'Exportálás',
  excelExport: 'Excel exportálás',
  excelExportDownloaded: 'Excel export letöltve',
  excelExportError: 'Az Excel exportálás sikertelen',
  pdfDownload: 'PDF letöltés',
  pdfDownloaded: 'PDF letöltve',
  configSaved: 'A konfiguráció mentésre került.',
  send: 'Küldje el a',
  connecting: 'Csatlakozás...',
  or: 'vagy',
  select: 'Válassza ki a  címet.',
  today: 'Ma',
  camera: 'Kamera',
  gallery: 'Galéria',
  files: 'Fájlok',
  upload: 'Fájlok feltöltése',
  chooseFiles: 'Fájlok kiválasztása',
  filesTransferring: 'Fájlok továbbítása...',
  filesTransferringWait: 'Kérjük, várjon, amíg a fájlokat továbbítjuk.',
  filesTransferred: 'Sikeresen továbbított fájlok.',
  download: 'Letöltés',
  showInactive: 'Mutasd inaktív',
  copyLink: 'Link másolása',
  linkCopied: 'A hivatkozás a vágólapra lett másolva.',
  permissions: 'Engedélyek',
  email: 'E-mail',
  emailConfig: 'E-mail konfiguráció',
  title: 'Cím',
  user: 'Felhasználó',
  logout: 'Kijelentkezés',
  system: 'Rendszer',
  appVersion: 'Verzió (alkalmazás)',
  apiVersionCore: 'Verzió\n(Alap API)',
  apiVersionCheck: 'Verzió\n(API ellenőrzése)',
  deviceInfo: 'Eszközinformáció',
  installBlinkTime: 'Install Blink Time telepítése',
  installBlinkMe: 'Telepítse Blink Me',
  installing: 'A {{version}} verzió telepítve van ...',
  companyRequired: 'Válassza ki az ügyfelet',
  companyRequiredInfo: 'Ki kell választania egy ügyfelet, hogy rendszergazdaként folytathassa.',
  pleaseChoose: 'Kérjük, válasszon',
  pleaseFill: 'Kérjük, töltse ki',
  noEntitiesFound: 'Nem találtak elemeket.',
  addCustom: 'Elem létrehozása:',
  typeToSearchText: 'Kérjük, adjon meg két vagy több karaktert...',
  loading: 'Az adatok betöltődnek...',
  offline: 'Offline',
  youAreOffline: 'Offline vagy!',
  by: 'a  címről',
  date: 'Dátum',
  time: 'Idő',
  searchTitle: 'Keresés',
  searchTerm: 'Keresési kulcsszó',
  searchLoginUser: 'Alkalmazottak keresése',
  searchLoginUserPlaceholder: 'Keresés név vagy keresztnév alapján',
  location: 'Objektum',
  searchLocation: 'Tárgy keresése',
  searchLocationPlaceholder: 'Keresés objektum neve, száma vagy címkék alapján',
  deactivate: 'A  deaktiválása',
  active: 'Aktív',
  inactive: 'Inaktív',
  tag: 'Nap',
  tags: 'Címkék',
  color: 'Színes',
  icon: 'Ikon',
  language: 'Nyelv',
  general: 'Általános',
  yes: 'Igen',
  no: 'Nem',
  searchThrough: 'Böngésszen a  oldalon.',
  name: 'Név',
  create: 'Hozzon létre',
  save: 'Mentés',
  home: 'Home',
  profile: 'Profil',
  templates: 'Sablonok',
  noAssignment: 'Nem kijelölt',
  sort: {
    change: 'Változtatási megbízás',
    save: 'Szekvencia mentése',
    saved: 'A szekvencia mentésre került.'
  },
  signature: {
    signature: 'Aláírás',
    pleaseSign: 'Kérjük, írja alá',
    clickHereToSign: 'Kattintson ide az aláíráshoz',
    addSignature: 'Aláírás hozzáadása',
    save: 'Vedd át',
    delete: 'Törölje a  címet.'
  },
  formError: {
    min: 'Az értéknek nagyobbnak kell lennie, mint {{value}}.',
    min2: 'Az értéknek nagyobbnak vagy egyenlőnek kell lennie, mint {{value}}.',
    max: 'Az értéknek kisebbnek vagy egyenlőnek kell lennie {{value}}.',
    requiredBecause: 'Érték szükséges, mert a {{name}} ki van töltve.',
    isRequired: '{{name}} kötelező.',
    numberInUse: 'A személyzeti szám már használatban van.',
    maxlength: '{{name}} nem tartalmazhat többet, mint {{value}} karaktereket.',
    email: 'Kérjük, adjon meg egy érvényes e-mail címet.',
    faultyBlinkId: 'Blink ID hibás'
  },
  scan: {
    choose: 'Új villogási azonosító hozzárendelése',
    text: 'Szkennelje be a regisztrációs kártyán található QR-kódot.',
    scan_now: 'Szkennelés most',
    enter_manually: 'Kézi bevitel'
  },
  removeImage: 'Kép törlése',
  tickets: { assignee: 'Szerkesztő', dueTo: 'Esedékes, amíg' },
  ngSelect: {
    typeToSearch: 'Kérjük, adjon meg legalább 3 betűt.',
    loading: 'adatok betöltése...',
    notFound: 'Nem találtunk {{title}}.',
    clearAll: 'Reset'
  },
  help: {
    pageTitle: 'Segítség',
    text1: 'Támogató csapatunk szívesen válaszol minden kérdésére:',
    phone: 'Telefon',
    mail: 'E-mail',
    information: 'Info',
    documentation: 'Online dokumentáció',
    documentationText: 'Online dokumentációnkban a Blink minden fontos területére vonatkozóan talál utasításokat és videókat.',
    support: 'Támogatás'
  },
  REGISTRATION: {
    WELCOME: 'Üdvözöljük!',
    chooseMethod: 'Melyik módszert szeretné használni a regisztrációhoz?',
    withBlinkId: 'BLINK ID kártya',
    withCredentials: 'Felhasználónév / Jelszó',
    REGISTER: 'Regisztráció',
    ALREADY_REGISTERED: 'Van egy Blink-fiókom.',
    INVALID_CARD: 'A kártya nem érvényes.',
    noBlinkId: 'A beolvasott QR-kód nem egy érvényes Blink ID',
    scanBlinkId: 'Blink ID szkennelés',
    ERROR: 'A regisztrációt nem sikerült befejezni. Kérjük, adjon meg egy másik e-mail címet vagy mobilszámot.',
    NO_INTERNET_EXPLORER: 'Sajnos a Blink nem támogatja az Internet Explorert. Kérjük, használja a Microsoft Edge, Firefox vagy Chrome böngészőt. Köszönjük megértését.',
    loginNow: 'Regisztráljon most',
    help: 'Segítség a regisztrációhoz',
    helpText: 'A Blink Manager alkalmazás lehetővé teszi az időpontok, a személyzet és a rendszer konfigurációjának kezelését. A Blink Manager csak felügyelőknek, ingatlankezelőknek, menedzsmentnek és adminisztrációnak szól! Puszta időnyilvántartáshoz használja a "Blink Time App"-t!<br/><br/>A regisztrációhoz kétféle módszer áll rendelkezésére:<br/><br/><br/><b>Regisztráció Blink ID kártyával</b><br/><br/><br/>Ha rendelkezik Blink ID kártyával, akkor válassza ezt a módszert. Az első regisztrációkor megkérdezik az e-mail címet vagy a mobiltelefonszámot. Erre azért van szükségünk, hogy egyszeri jelszót küldhessünk Önnek a regisztrációhoz. Az egyszeri jelszót nem kell megjegyeznie. Amikor újra regisztrál, új egyszeri jelszót küldünk Önnek.<br/><br/><b>Belépés felhasználónévvel és jelszóval</b><br/><br/><br/>A felhasználónevet és jelszót csak kérésre kapja meg a rendszergazdától. Ez a módszer csak rendszergazdai jogokkal rendelkező felhasználók számára érhető el.<br/><br/><br/><b>Support</b><br/><br/><br/>Ha problémái vannak a bejelentkezéssel, írjon e-mailt a support@blink-time.de címre, vagy hívja a 0911 240 330 0 telefonszámot.',
    userOffline: 'Kérjük, csatlakozzon az internetre a Blink me használatához.',
    login: 'Regisztráljon most',
    codeError: 'Kérjük, ellenőrizze a bejegyzést, és próbálja meg újra.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Gratulálunk!',
    YOU_DID_IT: 'Megcsináltad.',
    SIGNED_IN: 'Regisztrált',
    SIGNED_IN_TEXT: 'Sikeresen regisztrált, és most már használhatja az alkalmazást.',
    START_USAGE: 'Most már használhatja az alkalmazást.',
    START: 'Indítsa el a  oldalt.'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Egy e-mailt küldtünk Önnek, amellyel megváltoztathatja hozzáférési adatait.',
    FORGOT_PASSWORD: 'Elfelejtette a jelszavát?',
    errors: {
      notAllowed: 'A felhasználó jelszava nem állítható vissza.',
      invalidToken: 'A jelszó visszaállításához szükséges kód lejárt. Kérjük, kérjen új kódot.'
    },
    reset: {
      backToRoot: 'Vissza a bejelentkezési oldalra',
      renewCode: 'Új kód kérése',
      resetPassword: 'Jelszó visszaállítása',
      success: 'A jelszavát sikeresen visszaállították.',
      password1: 'Az új jelszó',
      password2: 'Jelszó megismétlése',
      validationError: 'A jelszó nem felel meg a jelszóirányelveinknek!',
      passwordsNotEqual: 'A jelszavak nem azonosak! Kérjük, ellenőrizze a bejegyzést.',
      passwordValidation: 'A jelszónak legalább 6 karakter hosszúnak kell lennie, és tartalmaznia kell egy betűt, egy számot és egy speciális karaktert.'
    },
    FORGOT_PASSWORD_TEXT: 'Kérjük, adja meg e-mail címét és a célrendszert a jelszó visszaállításához.',
    FORGOT_PASSWORD_PLACEHOLDER: 'email'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Adatok ellenőrzése',
    TEXT: 'Ha vissza kell állítanunk a jelszavát, szükségünk lesz az e-mail címére és opcionálisan a mobilszámára.',
    EMAIL: 'E-mail cím',
    MOBILE: 'Mobilszám'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Adja meg a kódot',
    EMAIL_TEXT: 'Írja be az alábbi e-mail címre küldött kódot:',
    SMS_TEXT: 'Írja be az alábbi mobilszámra küldött kódot:',
    NO_CODE: 'Nem kaptam kódot.',
    CODE_LENGTH_ERROR: 'A beírt kód hosszabb, mint hat karakter. Kérjük, adja meg újra a kódot.',
    CODE_SENT: 'A kód elküldve.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Nincs kód',
    TEXT1: 'Nem kapott kódot?',
    RESEND_CODE: 'Küldje el újra a kódot',
    TEXT2: 'Ha a rendszerben tárolt e-mail címe vagy mobilszáma megváltozott, kérjük, lépjen kapcsolatba a rendszergazdával.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Kérjük, adja meg a Blink azonosítóját és kódját. Minden információt megtalál a regisztrációs kártyáján.',
    textCredentials: 'A rendszergazdának kell megadnia a felhasználónevet és a jelszót.',
    BLINK_ID_INVALID: 'Blink ID hibás',
    loginCardNotFound: 'A Blink ID vagy már ki van adva, vagy nem létezik.',
    WRONG_CREDENTIALS: 'A megadott adatok nem helyesek. Kérjük, ellenőrizze a bejegyzést.',
    loginViaBlinkId: 'Regisztráció a BlinkID-vel',
    loginViaCredentials: 'Bejelentkezés a hozzáférési adatokkal',
    CODE: 'Kód:',
    codePlaceholder: 'Az Ön személyes hozzáférési kódja',
    company: 'Rendszer',
    password: 'Jelszó',
    FORGOT_PASSWORD: 'Elfelejtettem a jelszavam',
    username: 'email'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'Azonosítás',
    TEXT_1: 'Annak érdekében, hogy egyedileg azonosítani tudjuk Önt, SMS-ben vagy e-mailben küldünk Önnek egy egyszer használatos kódot.',
    TEXT_2: 'Ehhez kérjük, adja meg mobilszámát vagy e-mail címét.'
  },
  update: {
    update: 'Frissítés',
    latestVersion: 'Egy új verzió áll rendelkezésre. Kérjük, frissítse az alkalmazást, hogy teljes mértékben kihasználhassa annak előnyeit.',
    warnVersion: 'Ön az alkalmazás egy elavult verzióját használja. Kérjük, frissítse a lehető leghamarabb.',
    blockVersion: 'Ön az alkalmazás egy elavult verzióját használja, amely már nem támogatott. Kérjük, frissítsen most.'
  },
  inProgress: 'Folyamatban',
  video_offline: 'A videók offline módban nem tekinthetők meg, kérjük, csatlakozzon az internethez.',
  location_offline: 'Az objektum nincs offline módban mentve. Kérjük, csatlakozzon az internethez a QR-kód lekéréséhez.',
  invalidObjectCode: 'Nincs érvényes objektumkód.',
  invalidValue: 'helytelen érték',
  ERROR: {
    ERROR_400: 'Hiba történt. Kérlek, próbáld újra később. (Hibakód: 400)',
    ERROR_403: 'Nincs engedélye ennek a műveletnek a végrehajtására',
    REFRESH_TOKEN_403: 'Hozzáférési adatai már nem érvényesek. Kérjük, jelentkezzen be újra!',
    ERROR_500: 'Hiba történt. Kérlek, próbáld újra később. (Hibakód: 500)'
  },
  list: {
    entityPluralDefault: 'Eredmények',
    emptyState: 'Nem létezik {{entityPlural}}.',
    noResults: 'Nem található {{entityPlural}}.',
    selectAll: 'Az összes kijelölése {{entityPlural}}',
    massAction: 'Promóció a következőhöz: {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Az összes kijelölése {{count}}',
    massActionDeSelectAllGlobal: 'Az összes kijelölés törlése {{count}}'
  },
  languages: {
    ar: 'arab',
    de: 'német',
    es: 'spanyol',
    fr: 'Francia',
    hr: 'horvát',
    it: 'olasz',
    pt: 'portugál',
    ru: 'orosz',
    bg: 'bolgár',
    en: 'angol',
    fa: 'perzsa',
    el: 'görög',
    hu: 'Magyar',
    pl: 'fényesít',
    ro: 'román',
    tr: 'török',
    uk: 'ukrán',
    cs: 'cseh',
    sk: 'szlovák'
  },
  now: 'Most',
  allCompanies: 'Minden ügyfél',
  createDate: 'Készítés ideje',
  company: 'ügyfél',
  new: 'Új',
  of: 'tól től',
  request: {
    deleteTitle: 'Törlés {{item}}',
    confirmDelete: "Biztosan törölni szeretné a(z) '{{itemName}}' bejegyzést?",
    itemAdded: '{{item}} létrehozva.',
    itemAddedError: '{{item}} nem hozható létre.',
    itemSaved: '{{item}} mentése megtörtént.',
    itemSavedError: '{{item}} nem menthető.',
    itemRemoved: '{{item}} törölve.',
    itemRemovedError: '{{item}} nem törölhető.'
  },
  news: { pageTitle: 'hírek', edit: 'Hírek szerkesztése' },
  profiles: {
    loginProfiles: 'Bejelentkezési profilok',
    add: 'Bejelentkezési profil',
    logout: 'Ha kijelentkezik, minden helyi adat és profil törlődik! Le szeretne iratkozni?',
    login: 'Regisztráció',
    deleteConfirmation: 'Biztosan törölni akarod a profilt?',
    changed: 'A profil sikeresen módosult',
    deleted: 'A profil sikeresen törölve'
  },
  deleteAttachment: {
    confirmTitle: 'Melléklet törlése',
    confirmText: 'Biztosan törli ezt a mellékletet?'
  },
  comments: {
    addComment: 'Megjegyzés hozzáadása',
    addCommentInfo: 'A megjegyzést bárki láthatja, aki megtekintheti ezt a jegyet.',
    comment: 'megjegyzés',
    deleteComment: 'Megjegyzés törlése',
    deleteCommentConfirmation: 'Biztosan törölni szeretnéd a megjegyzést?'
  },
  confirm: 'Erősítse meg',
  firstName: 'Keresztnév',
  lastName: 'Vezetéknév',
  status: 'állapot',
  attachment: 'Melléklet'
};
