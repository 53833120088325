export default {
  home: {
    pageTitle: 'Pagina iniziale',
    welcome: "Benvenuto nell'app Blink me. Divertiti a chiacchierare,\n" +
      '\n' +
      'La tua squadra Blink',
    startChat: 'inizio',
    ticket: 'biglietto',
    timeLicenseMissing: 'Non ti è stata assegnata una licenza Blink Time!'
  },
  conversations: {
    pageTitle: 'Notizia',
    yourChats: 'Le tue chat',
    chat: 'Chiacchierata'
  },
  settings: {
    settings: 'Impostazioni',
    pushEnabled: 'Ricevi notifiche push',
    system: 'sistema',
    languageSelection: 'selezione della lingua',
    currentSettings: 'Impostazioni attuali',
    user: 'utente',
    appVersion: 'Versione (applicazione)',
    apiVersion: 'Versione (API)',
    deviceInfo: 'Versione del dispositivo',
    buildVersion: 'versione (costruita)',
    helpAndDataPolicy: 'Aiuto e protezione dei dati',
    text1: 'Il nostro team di supporto sarà felice di aiutarti in caso di domande:',
    phone: 'telefono',
    mail: 'e-mail',
    information: 'informazioni',
    documentation: 'Documentazione in linea',
    documentationText: 'Nella nostra documentazione online troverai istruzioni e video per tutte le aree importanti di Blink.',
    dataPolicy: 'protezione dati',
    support: 'Supporto',
    moreActions: 'Ulteriori azioni',
    protocol: 'protocollo',
    showChatUserSecret: 'Condividi la chat con un altro dispositivo',
    scanCodeAnotherDevice: 'Scansiona il seguente codice QR sul tuo nuovo dispositivo o inserisci il tuo codice chat personale sul nuovo dispositivo!',
    yourPersonalCode: 'Il tuo codice di chat personale',
    permissions: {
      title: 'Autorizzazioni',
      grantPermission: "concedere l'autorizzazione",
      camera: 'telecamera',
      cameraHint: 'Necessario per la registrazione del tempo tramite codice oggetto.',
      nfc: 'NFC',
      nfcHint: 'Necessario per la registrazione del tempo tramite tag NFC.',
      media: 'Prendi immagine e immagine dalla libreria',
      mediaHint: 'Necessario per acquisire immagini e documenti relativi ai tuoi orari e biglietti.',
      location: 'Posizione',
      locationHint: "Necessario per registrare la tua posizione al momento della registrazione dell'orario.",
      notifications: 'Le notifiche push',
      notificationsHint: 'Necessario per ricevere notifiche push per messaggi di chat, notifiche lampeggianti e adesivi.',
      locationDisabledOnSystem: 'I servizi di localizzazione sono disabilitati. Attivalo nelle impostazioni del tuo dispositivo.'
    }
  },
  connectionState: {
    connecting: 'Collegare...',
    connected: 'Legati insieme.',
    disconnecting: 'La connessione è interrotta...',
    disconnected: 'Collegamento perso',
    denied: 'Impossibile stabilire la connessione.'
  },
  noOneToFind: 'Nessuno da trovare.',
  nothingToFind: 'Niente da trovare.',
  noLocationFound: 'Nessun oggetto trovato.',
  searchPlaceholder: 'Cercare...',
  employeeSearchPlaceholder: 'Dì il nome',
  typeToSearch: 'Tocca per cercare...',
  typeToSearchEmployee: 'Inserisci il nome della persona per avviare una chat',
  createChatWithEmployee: 'Alla ricerca di dipendenti',
  createChatWithLocationTeam: 'Cerca oggetto',
  createTeamleaderChat: 'Tutti i miei dipendenti',
  yourLanguageSelection: 'La tua selezione della lingua:',
  loginInfo: 'Per registrarti, scansiona il tuo codice QR personale:',
  updating: 'Blink me è in fase di aggiornamento.',
  notice: 'Avviso',
  importantNotice: 'Nota importante',
  continue: 'Ulteriore',
  intro: {
    slide1: { header: 'Benvenuto', text: 'Divertiti con la tua app Blink me.' },
    slide2: {
      header: 'Chiacchierata',
      text: 'Chatta direttamente con i tuoi colleghi o scopri le ultime novità da gruppi interessanti.'
    },
    slide3: {
      header: 'Biglietti',
      text: 'Qui puoi vedere le tue attività e creare applicazioni.'
    }
  },
  pleaseWait: 'Attendere prego...',
  skip: 'Saltare',
  startAgain: 'Cominciare da capo',
  lock: {
    createPinHeader: 'Il tuo PIN personale',
    createPinInfo: "Per proteggere i tuoi dati, puoi impostare qui un PIN che dovrai inserire ogni volta che utilizzi l'app Blink me.",
    validatePin: 'Inserisci nuovamente il PIN.',
    tryAgain: 'Il PIN non corrisponde. Per favore riprova.',
    triedToOften: 'Purtroppo il tuo PIN non corrisponde. Vuoi riavviare il processo o saltarlo e farlo più tardi?',
    enterPinHeader: 'Inserimento pin',
    enterPinInfo: "Inserisci il tuo PIN per sbloccare l'app.",
    remainingTrys: "Altri {{amount}} tentativi. Successivamente è necessario accedere nuovamente all'app.",
    tooManyEnters: "Purtroppo la registrazione con PIN non è più possibile. Effettua nuovamente l'accesso con la tua carta d'identità Blink.",
    reSignIn: 'Nuova registrazione',
    setpin: "Inserimento del PIN durante l'utilizzo dell'app",
    skip: "Non vuoi assegnare un PIN adesso? Puoi attivare questa funzione in qualsiasi momento tramite le impostazioni dell'app, anche in seguito.",
    dontUsePin: 'non utilizzare un PIN',
    deactivatePinHeader: 'Disabilita PIN',
    deactivatePinInfo: 'Inserisci il tuo PIN per disattivare la protezione.'
  },
  active: {
    yourTickets: 'I tuoi biglietti',
    createNewTask: 'Crea un nuovo biglietto',
    assignee: 'editore',
    location: 'oggetto',
    dueDate: 'Dovuto per',
    createTask: 'Crea biglietto',
    taskCreated: 'Il biglietto è stato creato.',
    backToTasks: 'Torniamo ai biglietti',
    createdAt: 'creato',
    attachments: 'Allegati',
    addFileOrImage: 'Aggiungi file o foto',
    addImage: 'aggiungere una foto',
    comments: 'Commenti',
    addComment: 'Aggiungi un commento',
    addCommentInfo: 'Il commento è visibile a chiunque possa visualizzare questo ticket.',
    commentCreated: 'Il commento è stato salvato.',
    showClosed: 'Visualizza i biglietti completati',
    resetFilter: 'reimpostare tutti i filtri',
    changeTicketStatusTo: 'Cambia lo stato del biglietto in:',
    editTask: 'Modifica biglietto',
    saveTask: 'Salva biglietto',
    taskSaved: 'Il biglietto è stato salvato.',
    deleteImage: 'Immagine chiara',
    deleteImageConfirmation: "Vuoi davvero eliminare l'immagine?",
    deleteAttachment: 'Elimina allegato',
    deleteAttachmentConfirmation: 'Sei sicuro di voler eliminare questo allegato?',
    deleteComment: 'Elimina commento',
    deleteCommentConfirmation: 'Vuoi davvero eliminare il commento?',
    changeAssignee: 'Cambia redattore',
    duplicateTask: 'Biglietto duplicato',
    duplicateTaskConfirmation: 'Vuoi duplicare questo biglietto?',
    taskDuplicated: 'Il biglietto è stato duplicato.',
    deleteTask: 'Elimina biglietto',
    deleteTaskConfirmation: 'Sei sicuro di voler eliminare questo ticket?',
    taskDeleted: 'Il biglietto è stato eliminato.',
    assigneeChanged: 'Il processore è stato cambiato.',
    statusChanged: 'Lo stato del biglietto è stato modificato.',
    startDate: 'inizio',
    endDate: 'FINE',
    doctorVisitDate: 'Data della visita dal medico',
    doctorVisitDescription: 'Data della visita dal medico: {{date}}',
    chooseDate: 'Scegli una data',
    comment: 'Commento (facoltativo)',
    dateError: 'La data di fine non può essere anteriore alla data di inizio',
    employee: 'Dipendenti',
    pleaseConnectToLoad: 'Stabilisci una connessione Internet per caricare i tuoi biglietti.'
  },
  emptyState: {
    chats: 'Crea una chat!',
    chat: 'Scrivi il primo messaggio :)',
    tasks: 'Nessun biglietto da trovare.',
    attachments: 'Nessun allegato disponibile.',
    comments: 'Nessun commento disponibile',
    vacation: "Nessuna vacanza inserita per quest'anno"
  },
  formError: {
    min: 'Il valore deve essere maggiore di {{value}}.',
    min2: 'Il valore deve essere maggiore o uguale a {{value}}.',
    max: 'Il valore deve essere inferiore o uguale a {{value}}.',
    requiredBecause: 'Valore richiesto perché {{name}} è compilato.',
    isRequired: '{{name}} è obbligatorio.',
    numberInUse: 'Il numero del personale è già in uso.',
    maxlength: '{{name}} non può contenere più di {{value}} caratteri.',
    faultyBlinkId: 'ID lampeggiante errato'
  },
  ngSelect: {
    typeToSeach: 'Inserisci 3 o più lettere.',
    loading: 'caricamento dati...',
    notFound: 'Nessun {{title}} trovato.',
    clearAll: 'Riportare alle condizioni originali'
  },
  placeholder: {
    employee: 'Cerca per nome, cognome, ID dipendente, ID Blink',
    location: 'Cerca per nome, numero o codici oggetto',
    date: 'Scegli una data',
    searchTasks: 'Cerca biglietti...'
  },
  hasChat: 'Inizia subito a chattare',
  hasNoChatYet: "Sfortunatamente non ho ancora installato l'app Blink me.",
  noBlinkActiveLicence: 'Nessuna licenza',
  noBlinkActiveLicenceInfo: 'Al momento non disponi di una licenza per questa app. Si prega di contattare il proprio supervisore.',
  noBlinkMeLicence: 'Nessuna licenza',
  noBlinkMeLicenceInfo: "Questa app non può essere utilizzata con il tuo sistema. Utilizza Blink Time dall'app o dal Playstore.",
  noSuitableAppFound: 'Nessuna app trovata per aprire il file.',
  myChats: 'le tue chat',
  hasNoChatsHome: 'Inizia a chattare adesso! Puoi parlare con tutti i colleghi e partecipare a gruppi di oggetti e team.',
  hasNoTicketsHome: 'Nessun biglietto aperto disponibile.',
  myTickets: 'I tuoi biglietti',
  myEmployees: 'I miei dipendenti',
  group: 'gruppo',
  confirmLogoutWithChat: 'Se esci, i tuoi messaggi di chat verranno eliminati. Vuoi continuare?',
  messageNotDecryptable: 'Impossibile decifrare il messaggio.',
  chat: {
    userAlreadyExists: "Hai già configurato la chat su un altro dispositivo. Per poter utilizzare la chat su questo dispositivo, devi collegare i dispositivi. In alternativa è possibile riavviare la configurazione, ma in questo caso la chat non potrà più essere utilizzata sull'altro dispositivo.",
    linkDevices: 'Collegare i dispositivi',
    withoutChat: 'Continua senza chattare',
    setupAgain: 'Configura di nuovo la chat',
    setupAgainText: 'Se imposti nuovamente la chat, tutti i messaggi delle chat individuali esistenti verranno eliminati e la chat non potrà più essere utilizzata sugli altri dispositivi! Vuoi continuare?',
    LinkDevicesText: `Per collegare i dispositivi per la chat, apri le impostazioni sull'altro dispositivo e seleziona "Condividi chat con altro dispositivo`,
    personalToken: 'Il tuo codice di chat personale',
    wrongCode: 'Il codice chat fornito non è corretto.',
    beingPrepared: 'La chat è in preparazione.',
    twilioAccessDenied: 'Non è stato possibile stabilire la connessione al servizio chat. Contatta il supporto Blink.',
    notificationsDenied: 'Le notifiche sono disabilitate sul tuo dispositivo/browser. Vai su Impostazioni e attiva le notifiche per Blink me per ricevere notifiche. Potrai quindi attivare le notifiche nelle tue impostazioni personali.',
    deleted: 'La chat è stata eliminata',
    unavailable: 'La chat non è disponibile',
    participant: 'Partecipante',
    teamChatDescription: 'Tutti i dipendenti per i quali sei registrato come manager vengono aggiunti a questo gruppo di chat.',
    locationChatDescription: 'I dipendenti vengono aggiunti automaticamente se hanno una pianificazione per questo oggetto nelle ultime quattro settimane o nelle quattro settimane successive.',
    createEmployeeChatDescription: 'Verrà creata una chat privata con il dipendente selezionato.',
    teamChatCreationTitle: 'Crea gruppo di chat',
    createTeamChatDescription: 'Viene creato un gruppo di chat in cui vengono aggiunti automaticamente tutti i dipendenti per i quali sei registrato come manager.',
    createLocationChatDescription: "Viene creato un gruppo di chat per l'oggetto selezionato, in cui i dipendenti vengono aggiunti automaticamente se hanno un piano per esso nelle ultime quattro settimane o nelle quattro settimane successive."
  },
  protocol: {
    SEND_EMAIL: 'Inviare per email',
    DELETE: 'Pulisci il registro',
    deleteMessage: 'Vuoi davvero eliminare il registro? Questa azione non può essere annullata.',
    Send: 'Inviare',
    SuccessMessage: 'Il registro è stato inviato con successo al supporto Blink.',
    ConfirmMessage: 'Contatta prima il team di supporto e invia il registro solo se richiesto.',
    Description: 'Descrizione del problema'
  },
  timeTracking: {
    title: 'monitoraggio del tempo',
    start: 'Inizio',
    startDescription: 'Fare clic su Avvia per avviare il monitoraggio del tempo',
    started: 'Iniziato',
    stop: 'Fermare',
    duration: 'Periodo di tempo',
    syncButtonLabel: 'Tempi di caricamento registrati offline',
    offlineSyncMessage: 'Per trasferire i tempi salvati devi essere online.',
    successfulSync: 'Gli orari vengono caricati',
    scanNowQrCode: 'QR Code',
    scanNowQrCodeDescription: "Scansiona il codice QR nell'oggetto.",
    scanNowNfc: 'NFC',
    scanNowNfcDescription: "Avvicina il tuo smartphone all'adesivo NFC nell'oggetto.",
    nfcDescription: 'Rilevamento tramite NFC: avvicina il tuo cellulare al codice oggetto',
    noTimeTrackingMethodAvailableDescription: 'Non è configurato alcun metodo di monitoraggio del tempo per questo utente. Si prega di contattare il leader della squadra.',
    noLocationCode: 'Nessun codice disponibile',
    noLocationCodeDescription: "Seleziona questa opzione se nell'oggetto non è presente alcun codice QR o adesivo NFC. Fornire un commento è obbligatorio.",
    noLocationCodeConfirm: 'Sei sicuro di voler iniziare senza codice oggetto? È necessario fornire un commento per interrompere.',
    wrongCode: 'Il codice QR scansionato non è un codice oggetto!',
    readNotice: 'Si prega di leggere le istruzioni!',
    warning: 'avvertimento',
    lessThanAMinuteTitle: 'Registrazione completa del tempo',
    lessThanAMinute: 'Hai iniziato meno di 1 minuto fa. Sei sicuro di volerti fermare?',
    continue: 'Continua la registrazione',
    timeAlert: {
      differenceInMinutesHint: "L'ora sullo smartphone è diversa dall'ora sul server. La differenza viene registrata",
      differentTimeZoneHint: 'Il fuso orario del tuo smartphone è diverso dal fuso orario del server. La differenza viene registrata.'
    },
    leaveComment: {
      withCode: "Le informazioni sull'oggetto/servizio non erano chiare. Per favore dicci in quale struttura/servizio hai lavorato.",
      withoutCode: "Nessun codice oggetto è stato acquisito all'avvio o all'arresto. Spiega perché hai iniziato/interrotto senza un oggetto."
    },
    comment: 'commento',
    enterComment: 'Commento sul monitoraggio del tempo',
    differentQrCode: {
      title: 'cambiare oggetto?',
      continueInLocation: 'Continua a lavorare in {{locationName}}.',
      automaticStopInLocation: "La registrazione dell'orario in {{locationName}} si interromperà automaticamente.",
      stopInLocation: "Interrompi la registrazione dell'ora tra {{locationName}}.",
      stop: 'Fermare',
      stopNoLocation: 'Arresta la registrazione del tempo.',
      continueNoOldLocation: "La registrazione del tempo per l'oggetto precedente viene interrotta automaticamente.",
      continueWorking: 'Continuare a lavorare',
      continueWorkingInNewLocation: 'Continua a lavorare nella nuova proprietà.',
      stopWithLocationActivity: "Interrompi la registrazione dell'ora il {{locationActivity}}.",
      continueWithNewLocationActivity: 'Continua a lavorare con {{locationActivity}}',
      stopOldLocationActivity: "La registrazione dell'ora su {{locationActivity}} viene interrotta automaticamente.",
      stopOldNoLocationActivity: 'La registrazione del tempo senza esecuzione viene interrotta automaticamente.',
      continueNoLocationActivity: 'Continua a lavorare senza prestazioni.',
      sameLocationTitle: 'Cambiare prestazione?',
      differentLocationTitle: 'Cambiare oggetto?'
    },
    saveAndStop: 'Salva e ferma',
    noLocationScanned: '- Nessun oggetto -',
    noLocationFound: '- Oggetto non chiaro -',
    timerDifference: '{{h}} ore {{mm}} minuti',
    stopSuccess: 'Tempo registrato con successo',
    stopSuccessToast: "La registrazione dell'orario è stata interrotta correttamente.",
    stopTimeTracking: 'Fermare',
    manual: {
      time: 'Tempo',
      locationSelect: 'Seleziona oggetto',
      loadMore: 'carica altro',
      trackTime: 'Tempo record',
      location: 'oggetto',
      locationActivity: 'Prestazione',
      date: 'Data',
      startTime: 'Da',
      endTime: 'Fino a',
      create: 'Confermare',
      createAgain: 'Registra tempo aggiuntivo per questo oggetto',
      errorUntil: 'Da tempo deve essere inferiore a A tempo',
      worktimeDifference: 'Ore lavorative:',
      worktimeDifferenceValue: '{{hours}} ore {{minutes}} minuti',
      worktimeDifferenceValueMinutes: '{{minutes}} minuti',
      success: 'Tempo registrato con successo',
      break: 'Rottura:',
      breakMinutes: '{{minutes}} minuti',
      breakHoursAndMinutes: '{{hours}} ore {{minutes}} minuti',
      noActivity: '- nessuna prestazione -',
      locationPlaceHolder: 'Cerca per nome, numero o codici oggetto',
      conflictError: 'È già presente una registrazione degli orari nei periodi specificati. Non sono ammesse sovrapposizioni. Per favore controlla le tue informazioni.',
      currentDayDuration: 'Già registrato',
      expectedDuration: 'Orario di lavoro previsto',
      expectedDurationIncludedCurrent: "compreso l'ingresso di corrente"
    },
    differenceInMinutesHint: "L'ora sullo smartphone è diversa dall'ora sul server. La differenza viene registrata",
    differentTimeZoneHint: 'Il fuso orario del tuo smartphone è diverso dal fuso orario del server. La differenza viene registrata.',
    proposed: {
      capture: 'Catturare',
      time: 'Tempo',
      captureTime: 'Tempo record',
      button: 'Confermare',
      title: "Conferma l'orario di lavoro",
      includingBreak: '(compresa la pausa)',
      from: 'Da',
      to: 'Fino a',
      break: 'Rottura',
      hour: 'Orologio',
      confirm: {
        description: "Si prega di confermare l'orario di lavoro",
        confirm: 'Sì, è giusto',
        editButton: 'No, non è giusto'
      },
      edit: {
        description: 'Si prega di specificare la durata',
        breakIncluded: 'Inclusa {{ duration }} pausa',
        comment: 'commento',
        yourComment: 'il tuo commento',
        minutes: 'minuti',
        hours: 'Ore'
      },
      reasons: {
        overtime: 'Straordinari dovuti al cliente',
        faster: 'Fatto più velocemente',
        other: 'Varie',
        representative: 'Ero un sostituto'
      },
      description: 'Conferma il tuo orario di lavoro non appena hai finito di lavorare presso la struttura.'
    },
    codeNotAssignable: 'Il codice QR scansionato non può essere assegnato!',
    running: 'La registrazione del tempo è in esecuzione'
  },
  timeFramePicker: {
    title: 'Seleziona un periodo',
    today: 'Oggi',
    currentWeek: 'Settimana corrente',
    currentMonth: 'Corrente mese',
    custom: 'Costume',
    startDate: 'inizio',
    endDate: 'FINE'
  },
  timeSheet: {
    title: 'Tempi e pianificazione',
    noPlannings: 'Nessuna pianificazione',
    planned: 'Pianificato',
    workingTime: 'ore lavorative',
    workloadDuration: 'orario di lavoro settimanale',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Dovrebbe',
    workedDuration: 'È',
    hasWorkload: 'Lavora quel giorno',
    pleaseConnectToLoad: 'Stabilisci una connessione Internet per caricare i tuoi tempi.',
    footer: {
      planned: 'Pianificato',
      worklogsTotal: 'Ore totali',
      workloadTotal: 'ore da contratto',
      difference: 'differenza'
    },
    homepageTitle: 'La tua giornata lavorativa'
  },
  planning: {
    planRoute: 'pianificare il percorso',
    title: 'pianificazione',
    modifyMinutes: 'Forfettario:',
    includingPause: 'Rottura:',
    noNavAppFound: 'Nessuna app di navigazione trovata'
  },
  worklog: {
    title: 'Tempo registrato',
    absenceTitle: 'assenza',
    workingTime: 'ore lavorative',
    breakDuration: 'Rottura',
    reduce: 'forfettario',
    attachments: 'Allegati',
    comments: 'Commenti',
    comment: 'commento',
    noLocation: 'Nessun oggetto specificato',
    changedBy: "L'orario è cambiato da {{firstName}} {{lastName}}.",
    confirmAttachmentDelete: 'Sei sicuro di voler eliminare questo allegato?',
    attachmentDeleted: "L'allegato è stato eliminato",
    confirmCommentDelete: 'Sei sicuro di voler eliminare questo commento?',
    commentDeleted: 'Il commento è stato eliminato'
  },
  tabs: {
    home: 'Casa',
    times: 'volte',
    chat: 'chiacchierata',
    tickets: 'Biglietti',
    more: 'Di più'
  },
  profile: {
    title: 'profilo',
    testing: 'Test',
    testingDescription: "Quest'area è visibile solo quando è collegata a un sistema di test.",
    testingAllowQrCodeOnWeb: "Consenti l'inserimento manuale del codice QR",
    pushNotifications: 'Le notifiche push',
    reloadUserContext: 'Ricarica contesto utente',
    pushNotificationSuccessful: 'Notifica push ricevuta con successo',
    editProfilePicture: 'cambia immagine del profilo',
    editProfilePictureTitle: 'Seleziona sezione',
    pushNotificationNotice: "Per effettuare il test è necessaria l'autorizzazione “Modifica impostazioni client”.",
    send: 'Inviare',
    profilePicture: 'Immagine del profilo'
  },
  help: { title: 'Aiuto' },
  myVacations: {
    vacation: 'Vacanza',
    amountAvailableVacationDays: '{{amount}} giorni di ferie ancora disponibili',
    totalVacationDays: 'Diritto alle ferie {{ total }} giorni',
    remainingVacationDays: '({{ total }} giorni {{ sign }} {{ remaining }} giorni di ferie rimanenti {{ previousYear }})',
    xDaysVacations: '{{ total }} giorni di ferie',
    title: 'Le tue vacanze'
  },
  externalLinks: {
    title: 'link esterno',
    notice: 'Questo collegamento è stato fornito dalla tua azienda. Stai uscendo dal sistema Blink.'
  },
  stickers: {
    title: 'I tuoi adesivi',
    sticker: 'Adesivi',
    sender: 'Destinatario',
    text: 'Per quanto riguarda',
    date: 'Data',
    emptyState: 'Sfortunatamente non hai ancora alcun adesivo',
    senderDescription: 'Questo adesivo ti è stato inviato da {{sender}} il {{date}}',
    readed: 'Leggere',
    notReaded: 'non averlo ancora letto',
    newStickerToaster: 'Nuovi adesivi lampeggianti!',
    open: 'Aprire',
    next: 'Ulteriore',
    titleCases: {
      case1: 'Congratulazioni per il tuo primo adesivo!',
      case2: 'Fantastico, ora hai già <b>{{count}}</b> adesivi!',
      case3: 'Congratulazioni per il tuo decimo adesivo!',
      case4: '30 adesivi - wow!',
      case5: "Ora qui c'è sempre più gente: <b>{{count}}</b> adesivi!"
    },
    confirmDelete: 'Vuoi davvero eliminare questi adesivi?'
  },
  autoTranslate: {
    isTranslated: 'Tradotto automaticamente',
    translationError: 'La traduzione automatica non è attualmente possibile'
  },
  confirmLogout: 'Vuoi davvero disconnetterti?',
  message: 'Notizia',
  migration: {
    wrongVersion: "Nella versione attuale di Blink me, l'accesso tramite l'app Blink Time non è supportato. Aggiorna l'app Blink me alla versione più recente"
  },
  eLearning: {
    pendingTab: 'Da fare ({{value}})',
    completedTab: 'Fatto ({{value}})',
    completed: 'Completato',
    pending: 'Aprire',
    dueFalling: 'In ritardo',
    title: 'E-learning',
    course: 'corso',
    dueDate: 'Valido fino a',
    topics: 'soggetti',
    completeDate: 'Finito il',
    status: { Pending: 'aprire', Done: 'completato' },
    infos: {
      pendingExists: 'Qui puoi trovare tutti i corsi di formazione aperti.',
      finishOverdue: 'Si prega di seguire la formazione già prevista il più rapidamente possibile.',
      allCompleted: 'Eccellente! Hai completato con successo tutti i tuoi corsi di formazione.',
      emptyState: 'Non ti è stata assegnata alcuna formazione.'
    }
  },
  checklists: {
    title: 'Forme',
    description: 'Compila un modulo per un oggetto',
    button: 'Seleziona oggetto'
  },
  pendingTab: 'Esecuzione ({{value}})',
  completedTab: 'Completato ({{value}})',
  workOrders: {
    title: 'Ordini',
    homepageTitle: 'I tuoi ordini',
    responsible: 'Responsabile',
    customerContact: 'Contatto con il cliente',
    trackedTime: "Registrazione del tempo per l'ordine n.{{value}}",
    show: 'Visualizza ordine',
    trackInfo: {
      confirmAndAcknowledge: "Si prega di confermare le posizioni dopo l'implementazione ed effettuare un'ispezione in loco.",
      acknowledgeOnly: 'Si prega di effettuare un sopralluogo sul posto.',
      confirmOnly: "Si prega di confermare le posizioni dopo l'implementazione."
    },
    pleaseConnectToLoad: 'Stabilisci una connessione Internet per caricare i tuoi ordini.',
    status: { new: 'Nuovo', inProgress: 'Esecuzione', completed: 'Completato' },
    emptyState: 'Nessun ordine aperto disponibile.',
    emptyStateCompleted: 'Nessun ordine completato disponibile.',
    details: {
      title: "Dettagli dell'ordine",
      positionsTab: 'Posizioni ({{value}})',
      acknowledgementsTab: 'diminuisce ({{value}})'
    },
    acknowledgement: {
      title: 'accettazione',
      start: "Inizia l'accettazione",
      acknowledgedAtDate: 'Rimosso il',
      itemSelectDescription: 'Seleziona tutte le posizioni da rimuovere.',
      customerInformationDescription: "Si prega di fornire i dettagli di contatto del cliente (acquirente). Dopo l'accettazione verrà inviata una conferma a questa email.",
      created: 'Accettazione salvata con successo',
      selectAll: 'Seleziona tutto',
      emptyState: 'Non ci sono accettazioni.'
    },
    items: {
      items: 'posizioni',
      acknowledge: 'Conferma la posizione',
      confirmAcknowledge: 'Con la presente confermi che il lavoro è stato eseguito. Lo stato della posizione viene aggiornato a “Completato”.',
      status: {
        open: 'Aprire',
        planned: 'Pianificato',
        performed: 'Fatto',
        acknowledged: 'RIMOSSO',
        billed: 'Fatturato'
      },
      emptyState: 'Non ci sono posizioni disponibili.'
    },
    trackTime: {
      description: 'Seleziona un lavoro per il quale desideri monitorare il tempo.',
      chooseWorkOrder: "Seleziona l'ordine",
      choosePosition: 'Seleziona la posizione'
    }
  }
};
