export default {
  home: {
    pageTitle: 'الصفحة الرئيسية',
    welcome: 'مرحبًا بك في تطبيق Blink me. استمتع بالدردشة،\n\nفريق بلينك الخاص بك',
    startChat: 'يبدأ',
    ticket: 'تذكرة',
    timeLicenseMissing: 'لم يتم منحك ترخيص Blink Time!'
  },
  conversations: {
    pageTitle: 'أخبار',
    yourChats: 'الدردشات الخاصة بك',
    chat: 'محادثة'
  },
  settings: {
    settings: 'إعدادات',
    pushEnabled: 'تلقي إشعارات الدفع',
    system: 'نظام',
    languageSelection: 'اختيار اللغة',
    currentSettings: 'الاعدادات الحالية',
    user: 'مستخدم',
    appVersion: 'الإصدار (التطبيق)',
    apiVersion: 'الإصدار (واجهة برمجة التطبيقات)',
    deviceInfo: 'إصدار الجهاز',
    buildVersion: 'نسخة (بناء)',
    helpAndDataPolicy: 'المساعدة وحماية البيانات',
    text1: 'سيكون فريق الدعم لدينا سعيدًا بمساعدتك إذا كانت لديك أي أسئلة:',
    phone: 'هاتف',
    mail: 'بريد إلكتروني',
    information: 'معلومات',
    documentation: 'التوثيق عبر الإنترنت',
    documentationText: 'ستجد في وثائقنا عبر الإنترنت تعليمات ومقاطع فيديو لجميع المجالات المهمة في Blink.',
    dataPolicy: 'حماية البيانات',
    support: 'يدعم',
    moreActions: 'مزيد من الإجراءات',
    protocol: 'بروتوكول',
    showChatUserSecret: 'مشاركة الدردشة مع جهاز آخر',
    scanCodeAnotherDevice: 'قم بمسح رمز الاستجابة السريعة التالي على جهازك الجديد أو أدخل رمز الدردشة الشخصية الخاص بك على الجهاز الجديد!',
    yourPersonalCode: 'رمز الدردشة الشخصية الخاصة بك',
    permissions: {
      title: 'الأذونات',
      grantPermission: 'يعطي الأذن',
      camera: 'آلة تصوير',
      cameraHint: 'ضروري لتسجيل الوقت عبر رمز الكائن.',
      nfc: 'نفك',
      nfcHint: 'ضروري لتسجيل الوقت عبر علامة NFC.',
      media: 'التقاط صورة وصورة من المكتبة',
      mediaHint: 'من الضروري التقاط الصور والمستندات المتعلقة بالأوقات والتذاكر الخاصة بك.',
      location: 'موقع',
      locationHint: 'من الضروري تسجيل موقعك في وقت تسجيل الوقت.',
      notifications: 'دفع الإخطارات',
      notificationsHint: 'ضروري لتلقي إشعارات الدفع لرسائل الدردشة والإشعارات الوامضة والملصقات.',
      locationDisabledOnSystem: 'تم تعطيل خدمات الموقع. يرجى تفعيل هذا في إعدادات جهازك.'
    }
  },
  connectionState: {
    connecting: 'يتصل...',
    connected: 'مرتبطة ببعضها البعض.',
    disconnecting: 'تم قطع الاتصال...',
    disconnected: 'فقدت الاتصال',
    denied: 'لا يمكن تأسيس الاتصال.'
  },
  noOneToFind: 'لا يمكن العثور على أي شيء.',
  nothingToFind: 'لا شيء يمكن العثور عليه.',
  noLocationFound: 'لم يتم العثور على أي كائنات.',
  searchPlaceholder: 'يطلب...',
  employeeSearchPlaceholder: 'سمى الاسم الاول',
  typeToSearch: 'اضغط للبحث...',
  typeToSearchEmployee: 'أدخل اسم الشخص لبدء الدردشة',
  createChatWithEmployee: 'أبحث عن موظفين',
  createChatWithLocationTeam: 'كائن البحث',
  createTeamleaderChat: 'جميع موظفيني',
  yourLanguageSelection: 'اختيارك للغة:',
  loginInfo: 'للتسجيل، يرجى مسح رمز الاستجابة السريعة الشخصي الخاص بك:',
  updating: 'يتم تحديث Blink me.',
  notice: 'يلاحظ',
  importantNotice: 'ملاحظة مهمة',
  continue: 'متابعة',
  intro: {
    slide1: {
      header: 'اهلا وسهلا',
      text: 'استمتع مع تطبيق Blink me الخاص بك.'
    },
    slide2: {
      header: 'محادثة',
      text: 'قم بالدردشة مباشرة مع زملائك أو تعرف على آخر الأخبار من المجموعات المثيرة للاهتمام.'
    },
    slide3: {
      header: 'التذاكر',
      text: 'هنا يمكنك رؤية مهامك وإنشاء التطبيقات.'
    }
  },
  pleaseWait: 'يرجى الانتظار...',
  skip: 'يتخطى',
  startAgain: 'البدء من جديد',
  lock: {
    createPinHeader: 'رقم التعريف الشخصي الشخصي الخاص بك',
    createPinInfo: 'لحماية بياناتك، يمكنك تعيين رمز PIN هنا والذي يجب عليك إدخاله في كل مرة تستخدم فيها تطبيق Blink me.',
    validatePin: 'الرجاء إدخال رقم التعريف الشخصي مرة أخرى.',
    tryAgain: 'رقم التعريف الشخصي غير متطابق. من فضلك حاول مرة أخرى.',
    triedToOften: 'لسوء الحظ، رقم التعريف الشخصي الخاص بك غير متطابق. هل تريد إعادة تشغيل العملية أم تخطيها والقيام بذلك لاحقًا؟',
    enterPinHeader: 'إدخال دبوس',
    enterPinInfo: 'الرجاء إدخال رقم التعريف الشخصي الخاص بك لفتح التطبيق.',
    remainingTrys: '{{amount}} من المحاولات الإضافية. ثم يتعين عليك تسجيل الدخول إلى التطبيق مرة أخرى.',
    tooManyEnters: 'لسوء الحظ، لم يعد التسجيل باستخدام رقم التعريف الشخصي (PIN) ممكنًا. يرجى تسجيل الدخول مرة أخرى باستخدام بطاقة هوية Blink الخاصة بك.',
    reSignIn: 'تسجيل جديد',
    setpin: 'إدخال رقم التعريف الشخصي (PIN) عند استخدام التطبيق',
    skip: 'ألا تريد تعيين رقم PIN الآن؟ يمكنك تفعيل هذه الوظيفة في أي وقت عبر إعدادات التطبيق، بما في ذلك لاحقًا.',
    dontUsePin: 'لا تستخدم رقم التعريف الشخصي',
    deactivatePinHeader: 'تعطيل رقم التعريف الشخصي',
    deactivatePinInfo: 'الرجاء إدخال رقم التعريف الشخصي (PIN) الخاص بك لإلغاء تنشيط الحماية.'
  },
  active: {
    yourTickets: 'التذاكر الخاصة بك',
    createNewTask: 'إنشاء تذكرة جديدة',
    assignee: 'محرر',
    location: 'هدف',
    dueDate: 'بسبب',
    createTask: 'إنشاء تذكرة',
    taskCreated: 'تم إنشاء التذكرة.',
    backToTasks: 'العودة إلى التذاكر',
    createdAt: 'تم إنشاؤها على',
    attachments: 'المرفقات',
    addFileOrImage: 'إضافة ملف أو صورة',
    addImage: 'إضافة صورة',
    comments: 'تعليقات',
    addComment: 'أضف تعليق',
    addCommentInfo: 'التعليق مرئي لأي شخص يمكنه مشاهدة هذه التذكرة.',
    commentCreated: 'تم حفظ التعليق.',
    showClosed: 'عرض التذاكر المغلقة',
    resetFilter: 'إعادة ضبط جميع المرشحات',
    changeTicketStatusTo: 'تغيير حالة التذكرة إلى:',
    editTask: 'تحرير التذكرة',
    saveTask: 'حفظ التذكرة',
    taskSaved: 'تم حفظ التذكرة.',
    deleteImage: 'صورة واضحة',
    deleteImageConfirmation: 'هل تريد حقا حذف الصورة؟',
    deleteAttachment: 'حذف المرفق',
    deleteAttachmentConfirmation: 'هل تريد حقًا حذف هذا المرفق؟',
    deleteComment: 'حذف تعليق',
    deleteCommentConfirmation: 'هل تريد حقا حذف التعليق؟',
    changeAssignee: 'تغيير المحرر',
    duplicateTask: 'تذكرة مكررة',
    duplicateTaskConfirmation: 'هل تريد تكرار هذه التذكرة',
    taskDuplicated: 'تم تكرار التذكرة.',
    deleteTask: 'حذف التذكرة',
    deleteTaskConfirmation: 'هل تريد حقًا حذف هذه التذكرة؟',
    taskDeleted: 'تم حذف التذكرة.',
    assigneeChanged: 'تم تغيير المحرر.',
    statusChanged: 'تم تغيير حالة التذكرة.',
    startDate: 'يبدأ',
    endDate: 'نهاية',
    doctorVisitDate: 'تاريخ زيارة الطبيب',
    doctorVisitDescription: 'تاريخ زيارة الطبيب: {{date}}',
    chooseDate: 'اختر تاريخًا',
    comment: 'التعليق (اختياري)',
    dateError: 'لا يمكن أن يكون تاريخ الانتهاء قبل تاريخ البدء',
    employee: 'موظفين',
    pleaseConnectToLoad: 'يرجى إنشاء اتصال بالإنترنت لتحميل التذاكر الخاصة بك.'
  },
  emptyState: {
    chats: 'إنشاء دردشة!',
    chat: 'اكتب الرسالة الأولى :)',
    tasks: 'لا يمكن العثور على التذاكر.',
    attachments: 'لا توجد مرفقات متاحة.',
    comments: 'لا توجد تعليقات متاحة.',
    vacation: 'لم تدخل أي إجازة لهذا العام'
  },
  formError: {
    min: 'يجب أن تكون القيمة أكبر من {{value}}.',
    min2: 'يجب أن تكون القيمة أكبر من أو تساوي {{value}}.',
    max: 'يجب أن تكون القيمة أقل من أو تساوي {{value}}.',
    requiredBecause: 'القيمة مطلوبة لأن {{name}} ممتلئة.',
    isRequired: '{{name}} إلزامي.',
    numberInUse: 'رقم الموظف قيد الاستخدام بالفعل.',
    maxlength: 'لا يمكن أن يحتوي {{name}} على أكثر من {{value}} حرفًا.',
    faultyBlinkId: 'معرف وميض غير صحيح'
  },
  ngSelect: {
    typeToSeach: 'الرجاء إدخال 3 أحرف أو أكثر.',
    loading: 'تحميل البيانات...',
    notFound: 'لم يتم العثور على {{title}}.',
    clearAll: 'إعادة تعيين إلى الافتراضي'
  },
  placeholder: {
    employee: 'البحث حسب الاسم الأول والأخير ورقم الموظف ومعرف Blink',
    location: 'البحث حسب الاسم أو الرقم أو رموز الكائن',
    date: 'اختر تاريخًا',
    searchTasks: 'البحث عن التذاكر...'
  },
  hasChat: 'ابدأ الدردشة الآن',
  hasNoChatYet: 'لسوء الحظ، لم أقم بتثبيت تطبيق Blink me بعد.',
  noBlinkActiveLicence: 'لا يوجد ترخيص',
  noBlinkActiveLicenceInfo: 'ليس لديك حاليًا ترخيص لهذا التطبيق. يرجى الاتصال بالمشرف الخاص بك.',
  noBlinkMeLicence: 'لا يوجد ترخيص',
  noBlinkMeLicenceInfo: 'لا يمكن استخدام هذا التطبيق مع نظامك. الرجاء استخدام Blink Time من التطبيق أو Playstore.',
  noSuitableAppFound: 'لم يتم العثور على تطبيق لفتح الملف.',
  myChats: 'الدردشات الخاصة بك',
  hasNoChatsHome: 'ابدأ الدردشة الآن! يمكنك التحدث مع جميع الزملاء والمشاركة في مجموعات الكائنات والفرق.',
  hasNoTicketsHome: 'لا توجد تذاكر مفتوحة متاحة.',
  myTickets: 'التذاكر الخاصة بك',
  myEmployees: 'موظفيني',
  group: 'مجموعة',
  confirmLogoutWithChat: 'إذا قمت بتسجيل الخروج، سيتم حذف رسائل الدردشة الخاصة بك. هل ترغب في الاستمرار؟',
  messageNotDecryptable: 'لا يمكن فك تشفير الرسالة.',
  chat: {
    userAlreadyExists: 'لقد قمت بالفعل بإعداد الدردشة على جهاز آخر. لتتمكن من استخدام الدردشة على هذا الجهاز، يجب عليك ربط الأجهزة. وبدلاً من ذلك، يمكنك إعادة تشغيل الإعداد، ولكن بعد ذلك لن يعد من الممكن استخدام الدردشة على الجهاز الآخر.',
    linkDevices: 'ربط الأجهزة',
    withoutChat: 'تواصل بدون دردشة',
    setupAgain: 'إعادة ضبط الدردشة',
    setupAgainText: 'إذا قمت بإعداد الدردشة مرة أخرى، فسيتم حذف جميع الرسائل من الدردشات الفردية الموجودة ولن يعد من الممكن استخدام الدردشة على الأجهزة الأخرى! هل ترغب في الاستمرار؟',
    LinkDevicesText: 'لربط الأجهزة للدردشة، افتح الإعدادات على جهازك الآخر وحدد "مشاركة الدردشة مع جهاز آخر".',
    personalToken: 'رمز الدردشة الشخصية الخاصة بك',
    wrongCode: 'رمز الدردشة المقدم غير صحيح.',
    beingPrepared: 'جارٍ إعداد الدردشة.',
    twilioAccessDenied: 'تعذر تأسيس الاتصال بخدمة الدردشة. يرجى الاتصال بدعم بلينك.',
    notificationsDenied: 'تم تعطيل الإشعارات على جهازك/متصفحك. انتقل إلى الإعدادات وقم بتمكين الإشعارات لـ Blink me لتلقي الإشعارات. يمكنك بعد ذلك تفعيل الإشعارات في إعداداتك الشخصية.',
    deleted: 'تم حذف الدردشة',
    unavailable: 'الدردشة غير متوفرة',
    participant: 'مشارك',
    teamChatDescription: 'تتم إضافة جميع الموظفين الذين قمت بالتسجيل لهم كمدير إلى مجموعة الدردشة هذه.',
    locationChatDescription: 'تتم إضافة الموظفين تلقائيًا إذا كان لديهم جدول لهذا الكائن في الأسابيع الأربعة الأخيرة أو الأسابيع الأربعة التالية.',
    createEmployeeChatDescription: 'سيتم إنشاء محادثة خاصة مع الموظف المحدد.',
    teamChatCreationTitle: 'إنشاء مجموعة دردشة',
    createTeamChatDescription: 'يتم إنشاء مجموعة دردشة تتم فيها إضافة جميع الموظفين الذين قمت بالتسجيل لهم كمدير تلقائيًا.',
    createLocationChatDescription: 'يتم إنشاء مجموعة دردشة للكائن المحدد، حيث تتم إضافة الموظفين تلقائيًا إذا كانت لديهم خطة لذلك في الأسابيع الأربعة الأخيرة أو الأسابيع الأربعة التالية.'
  },
  protocol: {
    SEND_EMAIL: 'ارسل بالبريد الإلكترونى',
    DELETE: 'سجل نظيف',
    deleteMessage: 'هل تريد حقا حذف السجل؟ لا يمكن التراجع عن هذا الإجراء.',
    Send: 'يرسل',
    SuccessMessage: 'تم إرسال السجل بنجاح إلى Blink Support.',
    ConfirmMessage: 'اتصل أولاً بفريق الدعم وأرسل السجل فقط إذا طلب ذلك.',
    Description: 'وصف المشكلة'
  },
  timeTracking: {
    title: 'تتبع الوقت',
    start: 'يبدأ',
    startDescription: 'انقر فوق ابدأ لبدء تتبع الوقت',
    started: 'بدأت',
    stop: 'للتوقف',
    duration: 'طول الوقت',
    syncButtonLabel: 'أوقات التحميل المسجلة دون اتصال بالإنترنت',
    offlineSyncMessage: 'لنقل الأوقات المحفوظة يجب أن تكون متصلاً بالإنترنت.',
    successfulSync: 'يتم تحميل الأوقات',
    scanNowQrCode: 'رمز الاستجابة السريعة',
    scanNowQrCodeDescription: 'مسح رمز الاستجابة السريعة في الكائن.',
    scanNowNfc: 'نفك',
    scanNowNfcDescription: 'أمسك هاتفك الذكي مقابل ملصق NFC الموجود على الجسم.',
    nfcDescription: 'الاكتشاف عبر NFC: أمسك هاتفك الخلوي مقابل رمز الكائن',
    noTimeTrackingMethodAvailableDescription: 'لم يتم تكوين طريقة تتبع الوقت لهذا المستخدم. يرجى الاتصال بقائد فريقك.',
    noLocationCode: 'لا يوجد رمز متاح',
    noLocationCodeDescription: 'حدد هذا الخيار إذا لم يكن هناك رمز QR أو ملصق NFC في الكائن. تقديم التعليق إلزامي.',
    noLocationCodeConfirm: 'هل أنت متأكد أنك تريد البدء بدون رمز الكائن؟ يجب عليك تقديم تعليق للتوقف.',
    wrongCode: 'رمز الاستجابة السريعة الممسوح ضوئيًا ليس رمزًا للكائن!',
    readNotice: 'يرجى قراءة التعليمات!',
    warning: 'تحذير',
    lessThanAMinuteTitle: 'تسجيل الوقت الكامل',
    lessThanAMinute: 'لقد بدأت منذ أقل من دقيقة واحدة. هل أنت متأكد أنك تريد التوقف؟',
    continue: 'مواصلة التسجيل',
    timeAlert: {
      differenceInMinutesHint: 'يختلف الوقت الموجود على هاتفك الذكي عن الوقت الموجود على الخادم. يتم تسجيل الفرق',
      differentTimeZoneHint: 'تختلف المنطقة الزمنية لهاتفك الذكي عن المنطقة الزمنية للخادم. يتم تسجيل الفرق.'
    },
    leaveComment: {
      withCode: 'المعلومات المتعلقة بالكائن/الخدمة لم تكن واضحة. من فضلك أخبرنا في أي عقار/خدمة عملت فيها.',
      withoutCode: 'لم يتم التقاط رمز الكائن عند البداية أو التوقف. يرجى توضيح سبب البدء/التوقف بدون أي شيء.'
    },
    comment: 'تعليق',
    enterComment: 'التعليق على تتبع الوقت',
    differentQrCode: {
      title: 'تغيير الكائن؟',
      continueInLocation: 'متابعة العمل في {{locationName}}.',
      automaticStopInLocation: 'سيتوقف تسجيل الوقت في {{locationName}} تلقائيًا.',
      stopInLocation: 'إيقاف تسجيل الوقت في {{locationName}}.',
      stop: 'للتوقف',
      stopNoLocation: 'إيقاف تسجيل الوقت.',
      continueNoOldLocation: 'يتم إيقاف تسجيل الوقت للكائن السابق تلقائيًا.',
      continueWorking: 'الاستمرار في العمل',
      continueWorkingInNewLocation: 'مواصلة العمل في الممتلكات الجديدة.',
      stopWithLocationActivity: 'إيقاف تسجيل الوقت على {{locationActivity}}.',
      continueWithNewLocationActivity: 'متابعة العمل مع {{locationActivity}}',
      stopOldLocationActivity: 'يتم إيقاف تسجيل الوقت على {{locationActivity}} تلقائيًا.',
      stopOldNoLocationActivity: 'يتم إيقاف تسجيل الوقت بدون أداء تلقائيًا.',
      continueNoLocationActivity: 'مواصلة العمل دون أداء.',
      sameLocationTitle: 'تغيير الأداء؟',
      differentLocationTitle: 'تغيير الكائن؟'
    },
    saveAndStop: 'حفظ وإيقاف',
    noLocationScanned: '- لا مانع -',
    noLocationFound: '-الكائن غير واضح-',
    timerDifference: '{{h}} ساعة {{mm}} دقيقة',
    stopSuccess: 'تم تسجيل الوقت بنجاح',
    stopSuccessToast: 'توقف تسجيل الوقت بنجاح.',
    stopTimeTracking: 'للتوقف',
    manual: {
      time: 'وقت',
      locationSelect: 'حدد الكائن',
      loadMore: 'تحميل المزيد',
      trackTime: 'سجل الوقت',
      location: 'هدف',
      locationActivity: 'أداء',
      date: 'تاريخ',
      startTime: 'من',
      endTime: 'حتى',
      create: 'يتأكد',
      createAgain: 'تسجيل وقت إضافي لهذا الكائن',
      errorUntil: 'من وقت يجب أن يكون أقل من إلى وقت',
      worktimeDifference: 'ساعات العمل:',
      worktimeDifferenceValue: '{{hours}} ساعة {{minutes}} دقيقة',
      worktimeDifferenceValueMinutes: '{{minutes}} دقيقة',
      success: 'تم تسجيل الوقت بنجاح',
      break: 'استراحة:',
      breakMinutes: '{{minutes}} دقيقة',
      breakHoursAndMinutes: '{{hours}} ساعة {{minutes}} دقيقة',
      noActivity: '- لا يوجد أداء -',
      locationPlaceHolder: 'البحث حسب الاسم أو الرقم أو رموز الكائن',
      conflictError: 'يوجد بالفعل وقت للتسجيل في الفترات المحددة. التداخلات غير مسموح بها. الرجاء مراجعة التفاصيل الخاصة بك.',
      currentDayDuration: 'تم تسجيلها بالفعل',
      expectedDuration: 'ساعات العمل المتوقعة',
      expectedDurationIncludedCurrent: 'بما في ذلك المدخلات الحالية'
    },
    differenceInMinutesHint: 'يختلف الوقت الموجود على هاتفك الذكي عن الوقت الموجود على الخادم. يتم تسجيل الفرق',
    differentTimeZoneHint: 'تختلف المنطقة الزمنية لهاتفك الذكي عن المنطقة الزمنية للخادم. يتم تسجيل الفرق.',
    proposed: {
      capture: 'يأسر',
      time: 'وقت',
      captureTime: 'سجل الوقت',
      button: 'يتأكد',
      title: 'تأكيد ساعات العمل',
      includingBreak: '(بما في ذلك الاستراحة)',
      from: 'من',
      to: 'حتى',
      break: 'استراحة',
      hour: 'ساعة',
      confirm: {
        description: 'يرجى تأكيد ساعات العمل الخاصة بك',
        confirm: 'نعم هذا صحيح',
        editButton: 'لا، هذا ليس صحيحا'
      },
      edit: {
        description: 'يرجى تحديد المدة',
        breakIncluded: 'بما في ذلك استراحة {{ duration }}.',
        comment: 'تعليق',
        yourComment: 'تعليقك',
        minutes: 'دقائق',
        hours: 'ساعات'
      },
      reasons: {
        overtime: 'العمل الإضافي بسبب العميل',
        faster: 'تم بشكل أسرع',
        other: 'متنوع',
        representative: 'لقد كنت بديلا'
      },
      description: 'قم بتأكيد ساعات عملك بمجرد الانتهاء من العمل في مكان الإقامة.'
    },
    codeNotAssignable: 'لا يمكن تعيين رمز الاستجابة السريعة الممسوح ضوئيًا!',
    running: 'تسجيل الوقت قيد التشغيل'
  },
  timeFramePicker: {
    title: 'الرجاء تحديد الفترة',
    today: 'اليوم',
    currentWeek: 'الأسبوع الحالي',
    currentMonth: 'الشهر الحالي',
    custom: 'مخصص',
    startDate: 'يبدأ',
    endDate: 'نهاية'
  },
  timeSheet: {
    title: 'الأوقات والتخطيط',
    noPlannings: 'لا تخطيط',
    planned: 'المخطط لها',
    workingTime: 'ساعات العمل',
    workloadDuration: 'وقت العمل الاسبوعي',
    workloadDurationShort: 'واز',
    plannedDuration: 'يجب',
    workedDuration: 'هو',
    hasWorkload: 'يعمل في ذلك اليوم',
    pleaseConnectToLoad: 'يرجى إنشاء اتصال بالإنترنت لتحميل الأوقات الخاصة بك.',
    footer: {
      planned: 'المخطط لها',
      worklogsTotal: 'مجموع الساعات',
      workloadTotal: 'ساعات حسب العقد',
      difference: 'اختلاف'
    },
    homepageTitle: 'يوم عملك'
  },
  planning: {
    planRoute: 'طريق الخطة',
    title: 'تخطيط',
    modifyMinutes: 'سعر موحد:',
    includingPause: 'استراحة:',
    noNavAppFound: 'لم يتم العثور على تطبيق الملاحة'
  },
  worklog: {
    title: 'الوقت المسجل',
    absenceTitle: 'غياب',
    workingTime: 'ساعات العمل',
    breakDuration: 'استراحة',
    reduce: 'سعر موحد',
    attachments: 'المرفقات',
    comments: 'تعليقات',
    comment: 'تعليق',
    noLocation: 'لم يتم تحديد أي كائن',
    changedBy: 'تم تغيير الوقت من {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'هل أنت متأكد أنك تريد حذف هذا المرفق؟',
    attachmentDeleted: 'تم حذف المرفق',
    confirmCommentDelete: 'هل أنت متأكد أنك تريد حذف هذا التعليق؟',
    commentDeleted: 'تم حذف التعليق'
  },
  tabs: {
    home: 'بيت',
    times: 'مرات',
    chat: 'محادثة',
    tickets: 'التذاكر',
    more: 'أكثر'
  },
  profile: {
    title: 'حساب تعريفي',
    testing: 'اختبارات',
    testingDescription: 'تكون هذه المنطقة مرئية فقط عند الاتصال بنظام اختبار.',
    testingAllowQrCodeOnWeb: 'السماح بالإدخال اليدوي لرمز الاستجابة السريعة',
    pushNotifications: 'دفع الإخطارات',
    reloadUserContext: 'إعادة تحميل UserContext',
    pushNotificationSuccessful: 'تم استلام إشعار الدفع بنجاح',
    editProfilePicture: 'تغيير الصورة الشخصية',
    editProfilePictureTitle: 'اختر القسم',
    pushNotificationNotice: 'مطلوب إذن "تحرير إعدادات العميل" للاختبار.',
    send: 'يرسل',
    profilePicture: 'الصوره الشخصيه'
  },
  help: { title: 'يساعد' },
  myVacations: {
    vacation: 'أجازة',
    amountAvailableVacationDays: 'لا تزال هناك {{amount}} يوم إجازة متاحة',
    totalVacationDays: 'استحقاق الإجازة {{ total }} يوم',
    remainingVacationDays: '({{ total }} يوم {{ sign }} {{ remaining }} يوم متبقي من الإجازة {{ previousYear }})',
    xDaysVacations: '{{ total }} يوم إجازة',
    title: 'عطلتك'
  },
  externalLinks: {
    title: 'روابط خارجية',
    notice: 'تم توفير هذا الرابط من قبل شركتك. أنت تغادر نظام Blink.'
  },
  stickers: {
    title: 'الملصقات الخاصة بك',
    sticker: 'ملصقات',
    sender: 'متلقي',
    text: 'متعلق',
    date: 'تاريخ',
    emptyState: 'لسوء الحظ ليس لديك أي ملصقات حتى الآن',
    senderDescription: 'تم إرسال هذا الملصق إليك بواسطة {{sender}} بتاريخ {{date}}',
    readed: 'يقرأ',
    notReaded: 'لم أقرأه بعد',
    newStickerToaster: 'ملصق وميض جديد!',
    open: 'يفتح',
    next: 'متابعة',
    titleCases: {
      case1: 'تهانينا على الملصق الأول!',
      case2: 'رائع، لديك الآن ملصقات <b>{{count}}</b> بالفعل!',
      case3: 'تهانينا على الملصق العاشر!',
      case4: '30 ملصقًا - رائع!',
      case5: 'الآن أصبح المكان مزدحمًا هنا: <b>{{count}}</b> الملصقات!'
    },
    confirmDelete: 'هل تريد حقًا حذف هذه الملصقات؟'
  },
  autoTranslate: {
    isTranslated: 'مترجمة تلقائيا',
    translationError: 'الترجمة الآلية غير ممكنة حاليا'
  },
  confirmLogout: 'هل تريد حقا تسجيل الخروج؟',
  message: 'أخبار',
  migration: {
    wrongVersion: 'في الإصدار الحالي من Blink me، لا يتم دعم تسجيل الدخول عبر تطبيق Blink Time. يرجى تحديث تطبيق Blink me إلى الإصدار الأحدث'
  },
  eLearning: {
    pendingTab: 'للقيام ({{value}})',
    completedTab: 'تم ({{value}})',
    completed: 'مكتمل',
    pending: 'يفتح',
    dueFalling: 'تأخرت',
    title: 'التعلم الإلكتروني',
    course: 'دورة',
    dueDate: 'صالح حتى',
    topics: 'المواضيع',
    completeDate: 'انتهى يوم',
    status: { Pending: 'يفتح', Done: 'مكتمل' },
    infos: {
      pendingExists: 'يمكنك العثور على كافة الدورات التدريبية المفتوحة الخاصة بك هنا.',
      finishOverdue: 'يرجى إجراء التدريب المستحق بالفعل في أسرع وقت ممكن.',
      allCompleted: 'ممتاز! لقد أكملت بنجاح جميع الدورات التدريبية الخاصة بك.',
      emptyState: 'ليس لديك أي تدريب معين.'
    }
  },
  checklists: {
    title: 'نماذج',
    description: 'ملء نموذج لكائن',
    button: 'حدد الكائن'
  },
  pendingTab: 'التنفيذ ({{value}})',
  completedTab: 'مكتمل ({{value}})',
  workOrders: {
    title: 'طلبات',
    homepageTitle: 'طلباتك',
    responsible: 'مسؤول',
    customerContact: 'الاتصال بالعملاء',
    trackedTime: 'تسجيل الوقت للطلب رقم {{value}}',
    show: 'عرض الطلب',
    trackInfo: {
      confirmAndAcknowledge: 'يرجى التأكد من المواقف بعد التنفيذ وإجراء اختبار القبول في الموقع.',
      acknowledgeOnly: 'يرجى إجراء التفتيش في الموقع.',
      confirmOnly: 'برجاء التأكد من المواقف بعد التنفيذ.'
    },
    pleaseConnectToLoad: 'يرجى إنشاء اتصال بالإنترنت لتحميل طلباتك.',
    status: { new: 'جديد', inProgress: 'تنفيذ', completed: 'مكتمل' },
    emptyState: 'لا توجد أوامر مفتوحة متاحة.',
    emptyStateCompleted: 'لا توجد طلبات مكتملة متاحة.',
    details: {
      title: 'تفاصيل الطلب',
      positionsTab: 'المناصب ({{value}})',
      acknowledgementsTab: 'النقصان ({{value}})'
    },
    acknowledgement: {
      title: 'قبول',
      start: 'ابدأ بالقبول',
      acknowledgedAtDate: 'تمت الإزالة على',
      itemSelectDescription: 'حدد كافة المواقف المراد إزالتها.',
      customerInformationDescription: 'يرجى تقديم تفاصيل الاتصال بالعميل (المشتري). بعد القبول، سيتم إرسال تأكيد إلى هذا البريد الإلكتروني.',
      created: 'تم حفظ القبول بنجاح',
      selectAll: 'حدد الكل',
      emptyState: 'لا توجد قبولات.'
    },
    items: {
      items: 'المواقف',
      acknowledge: 'تأكيد الموقف',
      confirmAcknowledge: 'أنت تؤكد بموجب هذا أن العمل قد تم تنفيذه. يتم تحديث حالة الوظيفة إلى "مكتملة".',
      status: {
        open: 'يفتح',
        planned: 'المخطط لها',
        performed: 'منتهي',
        acknowledged: 'تمت الإزالة',
        billed: 'فاتورة'
      },
      emptyState: 'لا توجد وظائف متاحة.'
    },
    trackTime: {
      description: 'حدد الوظيفة التي تريد تتبع الوقت لها.',
      chooseWorkOrder: 'حدد النظام',
      choosePosition: 'حدد الموقف'
    }
  }
};
