import { InfoType } from '../enums/info-type';
import { UiInputSelectList } from '../inputs';
import { EMAIL_REGEXP } from '@blink/util';

export const uiInputName = (required = true, autofocus = true) => ({
  key: "Name",
  type: "input",
  props: {
    label: "typedGlobal.name",
    required,
    autofocus
  }
});

export const uiInput = (key: string, label: string, required = false, autofocus = false, pattern = null, patternValidationMessage = null) => {
  const input = {
    key: key,
    type: "input",
    props: {
      label: label,
      required: required,
      autofocus: autofocus,
      pattern: pattern
    }
  };

  if (pattern && patternValidationMessage) {
    input['validation'] = {
      messages: {
        pattern: () => patternValidationMessage
      }
    };
  }

  return input;
};

export const uiInputTextarea = (key: string, label: string, required = false, autofocus = false, pattern = null, patternValidationMessage = null) => {
  const input = {
    key: key,
    type: "textarea",
    props: {
      label: label,
      required: required,
      autofocus: autofocus,
      pattern: pattern
    }
  };

  if (pattern && patternValidationMessage) {
    input['validation'] = {
      messages: {
        pattern: () => patternValidationMessage
      }
    };
  }

  return input;
};

export const uiInputActive = () => ({
  key: "Active",
  type: "toggle",
  props: {
    label: "typedGlobal.active"
  }
});

export const uiInputToggle = (key: string, label: string) => ({
  key: key,
  type: "toggle",
  props: {
    label: label
  }
});

export const uiInputRadio = (key, label) => ({
  key: key,
  type: "radio",
  props: {
    label: label
  }
});

export const uiInputRadioGroup = (key, value, items) => ({
  key: key,
  defaultValue: value,
  type: "radio-group",
  items: items
});

export const uiInputNumber = ((key: string, label: string, required = true, min?: number, max?: number) => ({
  key: key,
  type: "input",
  props: {
    label: label,
    type: 'number',
    required: required,
    min: min,
    max: max
  }
}));

export const uiInputEmail = ((key: string, label: string, required = true, validationMessage: string) => ({
  key: key,
  type: "input",
  props: {
    label: label,
    type: 'email',
    required: required,
    pattern: EMAIL_REGEXP
  },
  validation: {
    messages: {
      pattern: () => validationMessage,
    },
  },
}));

//Named it with Formly so we don't mix it up with the other uiInfo component
export const uiFormlyInfo = ((key: string, text: string, infoType: InfoType) => ({
  key: key,
  type: 'ui-info',
  props: {
    label: text,
    infoType: infoType,
  }
}));

export const uiInputDescription = (required = false, autofocus = true) => ({
  key: "Description",
  type: "textarea",
  props: {
    label: "typedGlobal.description",
    required,
    autofocus
  }
});

//Named it with Formly so we don't mix it up with the other uiInfo component
export const uiFormlyDisplayTitle = ((key: string, text: string) => ({
  key: key,
  type: 'ui-display-title',
  props: {
    label: text
  }
}));

export const uiFormlyDisplayDescription = ((key: string, text: string) => ({
  key: key,
  type: 'ui-display-description',
  props: {
    label: text
  }
}));

//Named it with Formly so we don't mix it up with the other uiInfo component
export const uiFormlyInputSelect = ((key: string, text: string, items: UiInputSelectList, multiple = false) => ({
  key: key,
  type: 'ui-input-select',
  props: {
    label: text,
    items,
    multiple
  }
}));

export const uiFormlyDisplayImage = ((key: string, src: string) => ({
  key: key,
  type: 'ui-display-image',
  props: {
    src: src
  }
}));
