<ion-header class="ion-no-border">
  <img class="header-logo"
       src="assets/imgs/blink-logo.png"
       alt="Blink Logo"
       (click)="navigateHome()">
  <ion-button *ngIf="newVersionAvailable"
              size="small"
              class="ion-margin-top update-button"
              (click)="refresh()">
    Neue Version
  </ion-button>
</ion-header>

<ion-content>
  <div *ngIf="tabs"
       class="menu-tabs mt-10">
    <ion-menu-toggle *ngFor="let tab of getVisibleItems(tabs)"
                     [autoHide]="false">
      <ion-button [routerLink]="tab.routerLink!"
                  fill="clear"
                  class="ion-no-padding"
                  routerLinkActive="active"
                  [attr.data-testid]="tab.id">
        <div class="p-5">
          <div>
            <ui-icon *ngIf="tab.icon"
                     [icon]="tab.icon"
                     [fixedWidth]="true"
                     size="xl">
            </ui-icon>
          </div>
          <div class="mt-5">{{ tab.label | translate }}</div>
        </div>
      </ion-button>
    </ion-menu-toggle>
  </div>

  <div class="menu-wrapper">
    <div *ngIf="showCompanySelect" class="menu-company">
      <ion-row class="ion-align-items-center"
               (click)="selectCompany($event)">
        <ion-col size="auto">
          <ui-icon [icon]="BlinkIcon.Company"
                   [fixedWidth]="true"
                   class="menu-icon">
          </ui-icon>
        </ion-col>
        <ion-col>
          <div class="menu-company-name">
            {{ selectedCompanyName | translate }}
            <ion-spinner *ngIf="!selectedCompanyName" style="width: 15px; height: 15px;"></ion-spinner>
          </div>
        </ion-col>
        <ion-col size="auto">
          <ui-icon [icon]="BlinkIcon.ChevronDown"></ui-icon>
        </ion-col>
      </ion-row>
    </div>

    <div *ngIf="loginProfiles && loginProfiles.length > 1 && activeProfile" class="menu-company">
      <ion-row class="ion-align-items-center"
               (click)="selectProfile($event)">
        <ion-col size="auto">
          <i class="menu-icon fal {{BlinkIcon.Profile}} fa-fw"
             [style.padding-top]="activeProfile.System ? '5px' : '0px'"></i>
        </ion-col>
        <ion-col>
          <div class="menu-company-name">
            {{activeProfile.CompanyName}}
            <small style="display:block;margin-left:0px" class="input-info">{{activeProfile.System}}</small>
          </div>
        </ion-col>
        <ion-col size="auto">
          <ui-icon [icon]="BlinkIcon.ChevronDown"></ui-icon>
        </ion-col>
      </ion-row>
    </div>


    <div class="menu-items">
      <ng-container *ngTemplateOutlet="recursiveListTemplate; context:{ list:getVisibleItems(items) }"></ng-container>

      <ion-row *ngFor="let externalLink of externalLinks"
               class="menu-item mt-20"
               (click)="openExternalLink(externalLink)">
        <ion-col size="auto">
          <i class="menu-icon fal fa-{{externalLink.icon}} fa-fw"></i>
        </ion-col>
        <ion-col>
          <div class="menu-item-text">
            {{ externalLink.name | translate }}
          </div>
        </ion-col>
      </ion-row>
    </div>

    <ng-template #recursiveListTemplate let-list="list" let-noIcon="noIcon">
      <ion-menu-toggle [autoHide]="false">
        <ion-row *ngFor="let item of list"
                 class="menu-item mt-20"
                 [routerLink]="item.routerLink"
                 (click)="navigateToMenuPoint($event, item)">
          <ion-col *ngIf="!noIcon" size="auto">
            <ui-icon [icon]="item.icon"
                     [fixedWidth]="true"
                     class="menu-icon">
            </ui-icon>
          </ion-col>
          <ion-col [class.child-item]="noIcon" class="ion-align-self-center">
            <div class="menu-item-text"
                 routerLinkActive="active">
              {{ item.label | translate }}
            </div>
            <div *ngIf="item.children && item.open" class="mt-20">
              <ng-container
                *ngTemplateOutlet="recursiveListTemplate; context: {list: getVisibleItems(item.children), noIcon: true}"></ng-container>
            </div>
          </ion-col>
          <ion-col *ngIf="item.children"
                   size="auto"
                   (click)="$event.stopPropagation(); item.open = !item.open">
            <ui-icon [icon]="item.open ? BlinkIcon.ChevronUp : BlinkIcon.ChevronDown"></ui-icon>
          </ion-col>
        </ion-row>
      </ion-menu-toggle>
    </ng-template>
  </div>
</ion-content>
