export default {
  home: {
    pageTitle: "Page d'accueil",
    welcome: "Bienvenue sur l'application Blink me. Amusez-vous à discuter,\n" +
      '\n' +
      'Votre équipe Blink',
    startChat: 'commencer',
    ticket: 'billet',
    timeLicenseMissing: 'Aucune licence Blink Time ne vous a été attribuée !'
  },
  conversations: {
    pageTitle: 'Nouvelles',
    yourChats: 'vos discussions',
    chat: 'Chat'
  },
  settings: {
    settings: 'Paramètres',
    pushEnabled: 'Recevoir des notifications push',
    system: 'système',
    languageSelection: 'sélection de la langue',
    currentSettings: 'Paramètres actuels',
    user: 'utilisateur',
    appVersion: 'Version (application)',
    apiVersion: 'Version (API)',
    deviceInfo: "Version de l'appareil",
    buildVersion: 'version (construire)',
    helpAndDataPolicy: 'Aide et confidentialité',
    text1: "Notre équipe d'assistance se fera un plaisir de vous aider si vous avez des questions :",
    phone: 'téléphone',
    mail: 'e-mail',
    information: 'Info',
    documentation: 'Documentation en ligne',
    documentationText: 'Dans notre documentation en ligne, vous trouverez des instructions et des vidéos pour tous les domaines importants de Blink.',
    dataPolicy: 'protection des données',
    support: 'Soutien',
    moreActions: 'Actions supplémentaires',
    protocol: 'protocole',
    showChatUserSecret: 'Partager le chat avec un autre appareil',
    scanCodeAnotherDevice: 'Scannez le code QR suivant sur votre nouvel appareil ou saisissez votre code de chat personnel sur le nouvel appareil !',
    yourPersonalCode: 'Votre code de chat personnel',
    permissions: {
      title: 'Autorisations',
      grantPermission: 'donner la permission',
      camera: 'caméra',
      cameraHint: "Nécessaire pour l'enregistrement du temps via un code objet.",
      nfc: 'NFC',
      nfcHint: "Nécessaire pour l'enregistrement du temps via tag NFC.",
      media: 'Prendre une image et une image de la bibliothèque',
      mediaHint: 'Nécessaire pour capturer des images et des documents liés à vos horaires et billets.',
      location: 'Emplacement',
      locationHint: "Nécessaire pour enregistrer votre position au moment de l'enregistrement du temps.",
      notifications: 'Notifications push',
      notificationsHint: 'Nécessaire pour recevoir des notifications push pour les messages de chat, les notifications clignotantes et les autocollants.',
      locationDisabledOnSystem: "Les services de localisation sont désactivés. Veuillez l'activer dans les paramètres de votre appareil."
    }
  },
  connectionState: {
    connecting: 'Connecter...',
    connected: 'Attachés ensemble.',
    disconnecting: 'La connexion est déconnectée...',
    disconnected: 'connexion perdue',
    denied: "La connexion n'a pas pu être établie."
  },
  noOneToFind: 'Aucun à trouver.',
  nothingToFind: 'Rien à trouver.',
  noLocationFound: 'Aucun objet trouvé.',
  searchPlaceholder: 'Chercher...',
  employeeSearchPlaceholder: 'Nom Prénom',
  typeToSearch: 'Appuyez pour rechercher...',
  typeToSearchEmployee: 'Entrez le nom de la personne pour démarrer une conversation',
  createChatWithEmployee: 'rechercher des employés',
  createChatWithLocationTeam: 'Objet de recherche',
  createTeamleaderChat: 'Tous mes employés',
  yourLanguageSelection: 'Votre sélection de langue :',
  loginInfo: 'Pour vous inscrire, veuillez scanner votre code QR personnel :',
  updating: 'Blink me est en cours de mise à jour.',
  notice: 'Avis',
  importantNotice: 'Note importante',
  continue: 'Plus loin',
  intro: {
    slide1: {
      header: 'Accueillir',
      text: 'Amusez-vous avec votre application Blink me.'
    },
    slide2: {
      header: 'Chat',
      text: 'Discutez directement avec vos collègues ou découvrez les dernières nouvelles de groupes intéressants.'
    },
    slide3: {
      header: 'Des billets',
      text: 'Ici, vous pouvez voir vos tâches et créer des applications.'
    }
  },
  pleaseWait: "S'il vous plaît, attendez...",
  skip: 'Sauter',
  startAgain: 'Recommencer à nouveau',
  lock: {
    createPinHeader: 'Votre code PIN personnel',
    createPinInfo: "Pour protéger vos données, vous pouvez définir ici un code PIN que vous devez saisir chaque fois que vous utilisez l'application Blink me.",
    validatePin: 'Veuillez saisir à nouveau le code PIN.',
    tryAgain: 'Le code PIN ne correspond pas. Veuillez réessayer.',
    triedToOften: 'Malheureusement, votre code PIN ne correspond pas. Voulez-vous redémarrer le processus ou l’ignorer et le faire plus tard ?',
    enterPinHeader: 'Entrée du code PIN',
    enterPinInfo: "Veuillez saisir votre code PIN pour déverrouiller l'application.",
    remainingTrys: "{{amount}} tentatives supplémentaires. Vous devez ensuite vous reconnecter à l'application.",
    tooManyEnters: "Malheureusement, il n'est plus possible de s'inscrire avec un code PIN. Veuillez vous reconnecter avec votre carte d'identité Blink.",
    reSignIn: 'Nouvelle inscription',
    setpin: "Saisie du code PIN lors de l'utilisation de l'application",
    skip: "Vous ne souhaitez pas attribuer un code PIN maintenant ? Vous pouvez activer cette fonction à tout moment via les paramètres de l'application, y compris ultérieurement.",
    dontUsePin: "n'utilisez pas de code PIN",
    deactivatePinHeader: 'Désactiver le code PIN',
    deactivatePinInfo: 'Veuillez saisir votre code PIN pour désactiver la protection.'
  },
  active: {
    yourTickets: 'Vos billets',
    createNewTask: 'Créer un nouveau billet',
    assignee: 'éditeur',
    location: 'objet',
    dueDate: 'Due le',
    createTask: 'Créer un ticket',
    taskCreated: 'Le ticket a été créé.',
    backToTasks: 'Retour aux billets',
    createdAt: 'créé sur',
    attachments: 'Pièces jointes',
    addFileOrImage: 'Ajouter un fichier ou une photo',
    addImage: 'ajouter une photo',
    comments: 'commentaires',
    addComment: 'ajouter un commentaire',
    addCommentInfo: 'Le commentaire est visible par toute personne pouvant consulter ce ticket.',
    commentCreated: 'Le commentaire a été enregistré.',
    showClosed: 'Afficher les tickets terminés',
    resetFilter: 'réinitialiser tous les filtres',
    changeTicketStatusTo: 'Changez le statut du ticket en :',
    editTask: 'Modifier le billet',
    saveTask: 'Enregistrer le billet',
    taskSaved: 'Le billet a été enregistré.',
    deleteImage: 'Image claire',
    deleteImageConfirmation: 'Voulez-vous vraiment supprimer la photo ?',
    deleteAttachment: 'Supprimer la pièce jointe',
    deleteAttachmentConfirmation: 'Êtes-vous sûr de vouloir supprimer cette pièce jointe ?',
    deleteComment: 'Supprimer le commentaire',
    deleteCommentConfirmation: 'Voulez-vous vraiment supprimer le commentaire ?',
    changeAssignee: "Changer d'éditeur",
    duplicateTask: 'Billet en double',
    duplicateTaskConfirmation: 'Voulez-vous dupliquer ce ticket ?',
    taskDuplicated: 'Le ticket a été dupliqué.',
    deleteTask: 'Supprimer le billet',
    deleteTaskConfirmation: 'Êtes-vous sûr de vouloir supprimer ce ticket ?',
    taskDeleted: 'Le ticket a été supprimé.',
    assigneeChanged: "L'éditeur a été modifié.",
    statusChanged: 'Le statut du ticket a été modifié.',
    startDate: 'commencer',
    endDate: 'Fin',
    doctorVisitDate: 'Date de la visite chez le médecin',
    doctorVisitDescription: 'Date de la visite chez le médecin : {{date}}',
    chooseDate: 'Choisissez une date',
    comment: 'Commentaire (facultatif)',
    dateError: 'La date de fin ne peut pas être antérieure à la date de début',
    employee: 'Employés',
    pleaseConnectToLoad: 'Veuillez établir une connexion Internet pour charger vos billets.'
  },
  emptyState: {
    chats: 'Créez une discussion !',
    chat: 'Écrivez le premier message :)',
    tasks: 'Aucun billet à trouver.',
    attachments: "Il n'y a pas de pièces jointes.",
    comments: 'Aucun commentaire disponible.',
    vacation: 'Aucune vacances saisie pour cette année'
  },
  formError: {
    min: 'La valeur doit être supérieure à {{value}}.',
    min2: 'La valeur doit être supérieure ou égale à {{value}}.',
    max: 'La valeur doit être inférieure ou égale à {{value}}.',
    requiredBecause: 'Valeur obligatoire car {{name}} est renseigné.',
    isRequired: '{{name}} est obligatoire.',
    numberInUse: 'Le numéro de personnel est déjà utilisé.',
    maxlength: '{{name}} ne peut pas contenir plus de {{value}} caractères.',
    faultyBlinkId: 'ID de clignotement incorrect'
  },
  ngSelect: {
    typeToSeach: 'Veuillez saisir 3 lettres ou plus.',
    loading: 'chargement des données...',
    notFound: 'Aucun {{title}} trouvé.',
    clearAll: 'Réinitialiser aux valeurs par défaut'
  },
  placeholder: {
    employee: 'Recherche par nom et prénom, matricule, ID Blink',
    location: "Recherche par nom, numéro ou codes d'objet",
    date: 'Choisissez une date',
    searchTasks: 'Rechercher des billets...'
  },
  hasChat: 'Commencez à discuter maintenant',
  hasNoChatYet: "Malheureusement, je n'ai pas encore installé l'application Blink me.",
  noBlinkActiveLicence: 'Pas de permis',
  noBlinkActiveLicenceInfo: "Vous n'avez actuellement pas de licence pour cette application. Veuillez contacter votre superviseur.",
  noBlinkMeLicence: 'Pas de permis',
  noBlinkMeLicenceInfo: "Cette application ne peut pas être utilisée avec votre système. Veuillez utiliser Blink Time depuis l'application ou le Playstore.",
  noSuitableAppFound: 'Aucune application trouvée pour ouvrir le fichier.',
  myChats: 'Vos discussions',
  hasNoChatsHome: "Commencez à discuter maintenant ! Vous pouvez parler à tous les collègues et participer à des groupes d'objets et d'équipe.",
  hasNoTicketsHome: 'Aucun billet ouvert disponible.',
  myTickets: 'Vos billets',
  myEmployees: 'Mes employés',
  group: 'groupe',
  confirmLogoutWithChat: 'Si vous vous déconnectez, vos messages de chat seront supprimés. Voulez-vous continuer?',
  messageNotDecryptable: "Le message n'a pas pu être déchiffré.",
  chat: {
    userAlreadyExists: "Vous avez déjà configuré le chat sur un autre appareil. Afin de pouvoir utiliser le chat sur cet appareil, vous devez lier les appareils. Vous pouvez également redémarrer la configuration, mais le chat ne pourra alors plus être utilisé sur l'autre appareil.",
    linkDevices: 'Lier des appareils',
    withoutChat: 'Continuer sans discuter',
    setupAgain: 'Réinitialiser le chat',
    setupAgainText: 'Si vous configurez à nouveau le chat, tous les messages des chats individuels existants seront supprimés et le chat ne pourra plus être utilisé sur les autres appareils ! Voulez-vous continuer?',
    LinkDevicesText: 'Pour lier les appareils pour discuter, ouvrez les paramètres sur votre autre appareil et sélectionnez « Partager le chat avec un autre appareil ».',
    personalToken: 'Votre code de chat personnel',
    wrongCode: 'Le code de chat fourni est incorrect.',
    beingPrepared: 'Le chat est en préparation.',
    twilioAccessDenied: "La connexion au service de chat n'a pas pu être établie. Veuillez contacter l'assistance Blink.",
    notificationsDenied: 'Les notifications sont désactivées sur votre appareil/navigateur. Accédez à Paramètres et activez les notifications pour que Blink me reçoive des notifications. Vous pourrez ensuite activer les notifications dans vos paramètres personnels.',
    deleted: 'Le chat a été supprimé',
    unavailable: "Le chat n'est pas disponible",
    participant: 'Participant',
    teamChatDescription: 'Tous les employés pour lesquels vous êtes inscrit en tant que manager sont ajoutés à ce groupe de discussion.',
    locationChatDescription: "Les employés sont automatiquement ajoutés s'ils ont un planning pour cet objet au cours des quatre dernières semaines ou des quatre semaines suivantes.",
    createEmployeeChatDescription: "Un chat privé sera créé avec l'employé sélectionné.",
    teamChatCreationTitle: 'Créer un groupe de discussion',
    createTeamChatDescription: 'Un groupe de discussion est créé dans lequel tous les employés pour lesquels vous êtes inscrit en tant que manager sont automatiquement ajoutés.',
    createLocationChatDescription: "Un groupe de discussion est créé pour l'objet sélectionné, dans lequel les employés sont automatiquement ajoutés s'ils ont un plan pour cela au cours des quatre dernières semaines ou des quatre semaines suivantes."
  },
  protocol: {
    SEND_EMAIL: 'Envoyé par email',
    DELETE: 'Effacer le journal',
    deleteMessage: 'Voulez-vous vraiment supprimer le journal ? Cette action ne peut pas être annulée.',
    Send: 'Envoyer',
    SuccessMessage: 'Le journal a été envoyé avec succès au support Blink.',
    ConfirmMessage: 'Contactez d’abord l’équipe d’assistance et envoyez le journal uniquement sur demande.',
    Description: 'Description du problème'
  },
  timeTracking: {
    title: 'Suivi du temps',
    start: 'Commencer',
    startDescription: 'Cliquez sur Démarrer pour démarrer le suivi du temps',
    started: 'Commencé',
    stop: 'Arrêter',
    duration: 'Une longueur de temps',
    syncButtonLabel: 'Temps de téléchargement enregistrés hors ligne',
    offlineSyncMessage: 'Pour transférer les temps enregistrés, vous devez être en ligne.',
    successfulSync: 'Les horaires sont téléchargés',
    scanNowQrCode: 'QR Code',
    scanNowQrCodeDescription: "Scannez le code QR dans l'objet.",
    scanNowNfc: 'NFC',
    scanNowNfcDescription: "Tenez votre smartphone contre l'autocollant NFC dans l'objet.",
    nfcDescription: 'Détection via NFC : placez votre téléphone portable devant le code objet',
    noTimeTrackingMethodAvailableDescription: "Aucune méthode de suivi du temps n’est configurée pour cet utilisateur. Veuillez contacter votre chef d'équipe.",
    noLocationCode: 'Aucun code disponible',
    noLocationCodeDescription: "Sélectionnez cette option s'il n'y a pas de code QR ou d'autocollant NFC dans l'objet. Un commentaire est obligatoire.",
    noLocationCodeConfirm: 'Êtes-vous sûr de vouloir démarrer sans code objet ? Vous devez fournir un commentaire pour arrêter.',
    wrongCode: "Le code QR scanné n'est pas un code objet !",
    readNotice: 'Veuillez lire les instructions !',
    warning: 'avertissement',
    lessThanAMinuteTitle: 'Enregistrement du temps complet',
    lessThanAMinute: "Vous avez commencé il y a moins d'une minute. Êtes-vous sûr de vouloir arrêter ?",
    continue: "Continuer l'enregistrement",
    timeAlert: {
      differenceInMinutesHint: "L'heure sur votre smartphone diffère de l'heure sur le serveur. La différence est enregistrée",
      differentTimeZoneHint: 'Le fuseau horaire de votre smartphone diffère du fuseau horaire du serveur. La différence est enregistrée.'
    },
    leaveComment: {
      withCode: "Les informations sur l'objet/service n'étaient pas claires. Veuillez nous dire dans quelle propriété/service vous avez travaillé.",
      withoutCode: "Aucun code objet n'a été capturé au démarrage ou à l'arrêt. Veuillez expliquer pourquoi vous avez démarré/arrêté sans objet."
    },
    comment: 'commentaire',
    enterComment: 'Commentaire sur le suivi du temps',
    differentQrCode: {
      title: "Changer d'objet ?",
      continueInLocation: 'Continuez à travailler dans {{locationName}}.',
      automaticStopInLocation: "L'enregistrement du temps dans {{locationName}} s'arrêtera automatiquement.",
      stopInLocation: 'Arrêtez le suivi du temps dans {{locationName}}.',
      stop: 'Arrêter',
      stopNoLocation: 'Arrêtez l’enregistrement du temps.',
      continueNoOldLocation: "L'enregistrement du temps pour l'objet précédent est automatiquement arrêté.",
      continueWorking: 'Continue de travailler',
      continueWorkingInNewLocation: 'Continuez à travailler dans une nouvelle propriété.',
      stopWithLocationActivity: "Arrêtez l'enregistrement du temps sur {{locationActivity}}.",
      continueWithNewLocationActivity: 'Continuez à travailler avec {{locationActivity}}',
      stopOldLocationActivity: "L'enregistrement du temps sur {{locationActivity}} est arrêté automatiquement.",
      stopOldNoLocationActivity: 'L’enregistrement du temps sans exécution est automatiquement arrêté.',
      continueNoLocationActivity: 'Continuez à travailler sans performance.',
      sameLocationTitle: 'Changer les performances ?',
      differentLocationTitle: "Changer d'objet ?"
    },
    saveAndStop: 'Enregistrer et arrêter',
    noLocationScanned: '- Aucun objet -',
    noLocationFound: '- Objet pas clair -',
    timerDifference: '{{h}} heures {{mm}} minutes',
    stopSuccess: 'Temps enregistré avec succès',
    stopSuccessToast: "L'enregistrement du temps s'est arrêté avec succès.",
    stopTimeTracking: 'Arrêter',
    manual: {
      time: 'Temps',
      locationSelect: 'Sélectionner un objet',
      loadMore: 'charger plus',
      trackTime: 'Temps record',
      location: 'objet',
      locationActivity: 'Performance',
      date: 'Date',
      startTime: 'Depuis',
      endTime: "Jusqu'à",
      create: 'Confirmer',
      createAgain: 'Enregistrer du temps supplémentaire pour cet objet',
      errorUntil: 'Du temps doit être inférieur au temps To',
      worktimeDifference: "Heures d'ouverture:",
      worktimeDifferenceValue: '{{hours}} heures {{minutes}} minutes',
      worktimeDifferenceValueMinutes: '{{minutes}} minutes',
      success: 'Temps enregistré avec succès',
      break: 'Casser:',
      breakMinutes: '{{minutes}} minutes',
      breakHoursAndMinutes: '{{hours}} heures {{minutes}} minutes',
      noActivity: '- aucune prestation -',
      locationPlaceHolder: "Recherche par nom, numéro ou codes d'objet",
      conflictError: "Il existe déjà une saisie du temps dans les périodes spécifiées. Les chevauchements ne sont pas autorisés. S'il vous plaît vérifier vos informations.",
      currentDayDuration: 'Déjà enregistré',
      expectedDuration: 'Heures de travail prévues',
      expectedDurationIncludedCurrent: "y compris l'entrée de courant"
    },
    differenceInMinutesHint: "L'heure sur votre smartphone diffère de l'heure sur le serveur. La différence est enregistrée",
    differentTimeZoneHint: 'Le fuseau horaire de votre smartphone diffère du fuseau horaire du serveur. La différence est enregistrée.',
    proposed: {
      capture: 'Capturer',
      time: 'Temps',
      captureTime: 'Temps record',
      button: 'Confirmer',
      title: 'Confirmer les heures de travail',
      includingBreak: '(y compris pause)',
      from: 'Depuis',
      to: "Jusqu'à",
      break: 'Casser',
      hour: 'Horloge',
      confirm: {
        description: 'Veuillez confirmer vos heures de travail',
        confirm: "Oui c'est vrai",
        editButton: "Non, ce n'est pas vrai"
      },
      edit: {
        description: 'Merci de préciser la durée',
        breakIncluded: 'Y compris {{ duration }} pause',
        comment: 'commentaire',
        yourComment: 'votre commentaire',
        minutes: 'minutes',
        hours: 'Heures'
      },
      reasons: {
        overtime: 'Heures supplémentaires dues au client',
        faster: 'Fait plus vite',
        other: 'Divers',
        representative: "j'étais un remplaçant"
      },
      description: 'Confirmez vos heures de travail dès que vous avez fini de travailler dans la propriété.'
    },
    codeNotAssignable: 'Le code QR scanné ne peut pas être attribué !',
    running: "L'enregistrement du temps est en cours"
  },
  timeFramePicker: {
    title: 'Veuillez sélectionner une période',
    today: "Aujourd'hui",
    currentWeek: 'Cette semaine',
    currentMonth: 'Mois en cours',
    custom: 'Coutume',
    startDate: 'commencer',
    endDate: 'Fin'
  },
  timeSheet: {
    title: 'Horaires et planning',
    noPlannings: 'Aucune planification',
    planned: 'Prévu',
    workingTime: "heures d'ouverture",
    workloadDuration: 'Temps de travail hebdomadaire',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Devrait',
    workedDuration: 'Est',
    hasWorkload: 'Travailler ce jour-là',
    pleaseConnectToLoad: 'Veuillez établir une connexion Internet pour charger vos temps.',
    footer: {
      planned: 'Prévu',
      worklogsTotal: 'Heures totales',
      workloadTotal: 'Horaires selon contrat',
      difference: 'différence'
    },
    homepageTitle: 'Votre journée de travail'
  },
  planning: {
    planRoute: 'Planifier un itinéraire',
    title: 'planification',
    modifyMinutes: 'Forfait:',
    includingPause: 'Casser:',
    noNavAppFound: 'Aucune application de navigation trouvée'
  },
  worklog: {
    title: 'Temps enregistré',
    absenceTitle: 'absence',
    workingTime: "heures d'ouverture",
    breakDuration: 'Casser',
    reduce: 'forfait',
    attachments: 'Pièces jointes',
    comments: 'commentaires',
    comment: 'commentaire',
    noLocation: 'Aucun objet spécifié',
    changedBy: "L'heure est passée de {{firstName}} {{lastName}}.",
    confirmAttachmentDelete: 'Êtes-vous sûr de vouloir supprimer cette pièce jointe ?',
    attachmentDeleted: 'La pièce jointe a été supprimée',
    confirmCommentDelete: 'êtes-vous sûr de vouloir supprimer ce commentaire?',
    commentDeleted: 'Le commentaire a été supprimé'
  },
  tabs: {
    home: 'Maison',
    times: 'fois',
    chat: 'Chat',
    tickets: 'Des billets',
    more: 'plus'
  },
  profile: {
    title: 'profil',
    testing: 'Essai',
    testingDescription: "Cette zone n'est visible que lorsqu'elle est connectée à un système de test.",
    testingAllowQrCodeOnWeb: 'Autoriser la saisie manuelle du code QR',
    pushNotifications: 'Notifications push',
    reloadUserContext: 'Recharger le contexte utilisateur',
    pushNotificationSuccessful: 'Notification push reçue avec succès',
    editProfilePicture: 'modifier la photo de profil',
    editProfilePictureTitle: 'Sélectionner une rubrique',
    pushNotificationNotice: 'L’autorisation « Modifier les paramètres client » est requise pour le test.',
    send: 'Envoyer',
    profilePicture: 'Image de profil'
  },
  help: { title: 'Aide' },
  myVacations: {
    vacation: 'Vacances',
    amountAvailableVacationDays: '{{amount}} jours de vacances encore disponibles',
    totalVacationDays: 'Droit aux vacances {{ total }} jours',
    remainingVacationDays: '({{ total }} jours {{ sign }} {{ remaining }} jours de vacances restants {{ previousYear }})',
    xDaysVacations: '{{ total }} jours de vacances',
    title: 'Tes vacances'
  },
  externalLinks: {
    title: 'Liens externes',
    notice: 'Ce lien a été fourni par votre entreprise. Vous quittez le système Blink.'
  },
  stickers: {
    title: 'Vos autocollants',
    sticker: 'Autocollants',
    sender: 'Destinataire',
    text: 'Concernant',
    date: 'Date',
    emptyState: "Malheureusement, vous n'avez pas encore d'autocollants",
    senderDescription: 'Cet autocollant vous a été envoyé par {{sender}} le {{date}}',
    readed: 'lire',
    notReaded: "je ne l'ai pas encore lu",
    newStickerToaster: 'Nouvel autocollant clignotant !',
    open: 'Ouvrir',
    next: 'Plus loin',
    titleCases: {
      case1: 'Félicitations pour votre 1er autocollant!',
      case2: 'Super, vous avez désormais déjà <b>{{count}}</b> autocollants !',
      case3: 'Félicitations pour votre 10ème autocollant !',
      case4: '30 autocollants - wow !',
      case5: 'Maintenant, il y a du monde ici : <b>{{0}></b> Autocollants !'
    },
    confirmDelete: 'Voulez-vous vraiment supprimer ces autocollants ?'
  },
  autoTranslate: {
    isTranslated: 'Traduit automatiquement',
    translationError: "La traduction automatique n'est actuellement pas possible"
  },
  confirmLogout: 'Voulez-vous vraiment vous déconnecter ?',
  message: 'Nouvelles',
  migration: {
    wrongVersion: "Dans la version actuelle de Blink me, la connexion via l'application Blink Time n'est pas prise en charge. Veuillez mettre à jour l'application Blink me vers la dernière version"
  },
  eLearning: {
    pendingTab: 'À faire ({{value}})',
    completedTab: 'Terminé ({{value}})',
    completed: 'Complété',
    pending: 'Ouvrir',
    dueFalling: 'En retard',
    title: 'Apprentissage en ligne',
    course: 'cours',
    dueDate: "Valable jusqu'au",
    topics: 'sujets',
    completeDate: 'Terminé le',
    status: { Pending: 'ouvrir', Done: 'complété' },
    infos: {
      pendingExists: 'Vous pouvez retrouver toutes vos formations ouvertes ici.',
      finishOverdue: 'Veuillez suivre la formation déjà prévue le plus rapidement possible.',
      allCompleted: 'Excellent! Vous avez réussi toutes vos formations.',
      emptyState: "Vous n'avez aucune formation assignée."
    }
  },
  checklists: {
    title: 'Formes',
    description: 'Remplir un formulaire pour un objet',
    button: 'Sélectionner un objet'
  },
  pendingTab: 'Exécution ({{value}})',
  completedTab: 'Terminé ({{value}})',
  workOrders: {
    title: 'Ordres',
    homepageTitle: 'Vos commandes',
    responsible: 'Responsable',
    customerContact: 'Contact client',
    trackedTime: 'Enregistrement du temps pour la commande n° {{value}}',
    show: 'Voir la commande',
    trackInfo: {
      confirmAndAcknowledge: 'Veuillez confirmer les positions après la mise en œuvre et effectuer un test de réception sur place.',
      acknowledgeOnly: 'Veuillez effectuer une inspection sur place.',
      confirmOnly: 'Veuillez confirmer les positions après la mise en œuvre.'
    },
    pleaseConnectToLoad: 'Veuillez établir une connexion Internet pour charger vos commandes.',
    status: { new: 'Nouveau', inProgress: 'Exécution', completed: 'Complété' },
    emptyState: 'Aucune commande ouverte disponible.',
    emptyStateCompleted: 'Aucune commande terminée disponible.',
    details: {
      title: 'Détails de la commande',
      positionsTab: 'Postes ({{value}})',
      acknowledgementsTab: 'diminue ({{value}})'
    },
    acknowledgement: {
      title: 'acceptation',
      start: "Commencer l'acceptation",
      acknowledgedAtDate: 'Supprimé le',
      itemSelectDescription: 'Sélectionnez tous les postes à supprimer.',
      customerInformationDescription: 'Veuillez fournir les coordonnées du client (acheteur). Après acceptation, une confirmation sera envoyée à cet email.',
      created: 'Acceptation enregistrée avec succès',
      selectAll: 'Tout sélectionner',
      emptyState: 'Il n’y a aucune acceptation.'
    },
    items: {
      items: 'postes',
      acknowledge: 'Confirmer le poste',
      confirmAcknowledge: 'Vous confirmez par la présente que les travaux ont été effectués. Le statut du poste est mis à jour à « Terminé ».',
      status: {
        open: 'Ouvrir',
        planned: 'Prévu',
        performed: 'Fait',
        acknowledged: 'Supprimé',
        billed: 'Facturé'
      },
      emptyState: "Il n'y a aucun poste disponible."
    },
    trackTime: {
      description: 'Sélectionnez une tâche pour laquelle vous souhaitez suivre le temps.',
      chooseWorkOrder: 'Sélectionnez la commande',
      choosePosition: 'Sélectionnez un poste'
    }
  }
};
