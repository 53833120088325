export default {
  cancel: 'İptal Et',
  blinkSlogan: 'Blink - Bina Temizleyicileri için Uygulama',
  all: 'Tümü',
  hello: 'Merhaba',
  description: 'Açıklama',
  ok: 'Tamam.',
  reset: 'Sıfırla',
  edit: 'Düzenle',
  finish: 'Kilit',
  notice: 'Not',
  content: 'İçerik',
  back: 'Geri',
  close: 'Kapat',
  backToHome: 'Ana sayfaya geri dön',
  proceed: 'Daha ileri',
  done: 'Hazır',
  delete: 'Silme',
  successful: 'Başarılı',
  error: 'Hata',
  ERROR_OCCURRED: 'Bir hata oluştu veya veriler doğru değil. Lütfen tekrar deneyin.',
  internetRequired: 'İnternet gerekli',
  configuration: 'Konfigürasyon',
  logo: 'Logo',
  uploadLogo: 'Logo yükle',
  deleteLogo: 'Logoyu sil',
  duplicate: 'Yinelenen',
  importExport: 'İthalat / İhracat',
  import: 'İthalat',
  doImport: 'İthalat',
  export: 'İhracat',
  doExport: 'İhracat',
  excelExport: 'Excel dışa aktarma',
  excelExportDownloaded: 'Excel dışa aktarma indirildi',
  excelExportError: 'Excel dışa aktarma başarısız oldu',
  pdfDownload: 'PDF İndir',
  pdfDownloaded: 'PDF indirildi',
  configSaved: 'Konfigürasyon kaydedildi.',
  send: 'Gönder',
  connecting: 'Bağlan...',
  or: 'veya',
  select: 'Seçiniz',
  today: 'Bugün',
  camera: 'Kamera',
  gallery: 'Galeri',
  files: 'Dosyalar',
  upload: 'Dosya yükleme',
  chooseFiles: 'Dosyaları seçin',
  filesTransferring: 'Dosyaları iletin...',
  filesTransferringWait: 'Dosyalar iletilirken lütfen bekleyiniz.',
  filesTransferred: 'Dosyalar başarıyla iletildi.',
  download: 'İndir',
  showInactive: 'Aktif olmadığını göster',
  copyLink: 'Bağlantıyı kopyala',
  linkCopied: 'Bağlantı panoya kopyalandı.',
  permissions: 'Yetkilendirmeler',
  email: 'E-posta',
  emailConfig: 'E-posta yapılandırması',
  title: 'Başlık',
  user: 'Kullanıcı',
  logout: 'Oturumu kapat',
  system: 'Sistem',
  appVersion: 'Sürüm (Uygulama)',
  apiVersionCore: 'Versiyon\n(Çekirdek API)',
  apiVersionCheck: "Versiyon\n(API'yi kontrol edin)",
  deviceInfo: 'Cihaz bilgileri',
  installBlinkTime: 'Göz Kırpma Süresini Yükle',
  installBlinkMe: "Blink Me'yi yükleyin",
  installing: 'Sürüm {{version}} yüklendi ...',
  companyRequired: 'Müşteri seçin',
  companyRequiredInfo: 'Sistem yöneticisi olarak devam etmek için bir istemci seçmelisiniz.',
  pleaseChoose: 'Lütfen seçiniz',
  pleaseFill: 'Lütfen doldurun',
  noEntitiesFound: 'Hiçbir unsur bulunamadı.',
  addCustom: 'Öğe oluşturun:',
  typeToSearchText: 'Lütfen iki veya daha fazla karakter girin...',
  loading: 'Veriler yüklendi...',
  offline: 'Çevrimdışı',
  youAreOffline: 'Çevrimdışısınız!',
  by: 'gelen',
  date: 'Tarih',
  time: 'Zaman',
  searchTitle: 'Arama',
  searchTerm: 'Anahtar Kelime Ara',
  searchLoginUser: 'Çalışan arama',
  searchLoginUserPlaceholder: 'İsme veya ilk isme göre arama',
  location: 'Nesne',
  searchLocation: 'Nesne arama',
  searchLocationPlaceholder: 'Nesne adı, numarası veya etiketlerine göre arama',
  deactivate: 'Devre dışı bırak',
  active: 'Aktif',
  inactive: 'Aktif değil',
  tag: 'Gün',
  tags: 'Etiketler',
  color: 'Renk',
  icon: 'Simge',
  language: 'Dil',
  general: 'Genel',
  yes: 'Evet',
  no: 'Hayır',
  searchThrough: 'Gözat',
  name: 'İsim',
  create: 'Oluştur',
  save: 'Kaydet',
  home: 'Ev',
  profile: 'Profil',
  templates: 'Şablonlar',
  noAssignment: 'Atanmamış',
  sort: {
    change: 'Değişiklik emri',
    save: 'Diziyi kaydet',
    saved: 'Sekans kaydedildi.'
  },
  signature: {
    signature: 'İmza',
    pleaseSign: 'Lütfen imzalayın',
    clickHereToSign: 'İmzalamak için buraya tıklayın',
    addSignature: 'İmza ekleyin',
    save: 'Devralmak',
    delete: 'Silme'
  },
  formError: {
    min: "Değer {{value}}'den büyük olmalıdır.",
    min2: 'Değer {{value}} değerinden büyük veya ona eşit olmalıdır.',
    max: 'Değer {{value}} değerinden küçük veya eşit olmalıdır.',
    requiredBecause: 'Değer gerekli çünkü {{name}} doldurulmuş.',
    isRequired: '{{name}} zorunludur.',
    numberInUse: 'Personel numarası zaten kullanımda.',
    maxlength: "{{name}}, {{value}}'den fazlasını içermemelidir karakterler.",
    email: 'Lütfen geçerli bir e-posta adresi girin.',
    faultyBlinkId: 'Blink ID arızalı'
  },
  scan: {
    choose: 'Yeni Yanıp Sönme Kimliği Atama',
    text: 'Kayıt kartındaki QR kodunu tarayın.',
    scan_now: 'Şimdi tara',
    enter_manually: 'Manuel olarak girin'
  },
  removeImage: 'Görüntüyü sil',
  tickets: { assignee: 'Editör', dueTo: 'Şu tarihe kadar' },
  ngSelect: {
    typeToSearch: 'Lütfen 3 veya daha fazla harf girin.',
    loading: 'veri yükle...',
    notFound: '{{title}} bulunamadı.',
    clearAll: 'Sıfırla'
  },
  help: {
    pageTitle: 'Yardım',
    text1: 'Destek ekibimiz her türlü sorunuzu yanıtlamaktan mutluluk duyacaktır:',
    phone: 'Telefon',
    mail: 'E-posta',
    information: 'Bilgi',
    documentation: 'Çevrimiçi dokümantasyon',
    documentationText: "Çevrimiçi dokümantasyonumuzda Blink'in tüm önemli alanları için talimatlar ve videolar bulacaksınız.",
    support: 'Destek'
  },
  REGISTRATION: {
    WELCOME: 'Hoş geldiniz!',
    chooseMethod: 'Kayıt olmak için hangi yöntemi kullanmak istersiniz?',
    withBlinkId: 'BLINK Kimlik Kartı',
    withCredentials: 'Kullanıcı Adı / Şifre',
    REGISTER: 'Kayıt Olun',
    ALREADY_REGISTERED: 'Blink hesabım var.',
    INVALID_CARD: 'Kart geçerli değil.',
    noBlinkId: 'Taranan QR kodu geçerli bir Blink ID değil',
    scanBlinkId: 'Yanıp sönen kimlik taraması',
    ERROR: 'Kayıt işlemi tamamlanamadı. Lütfen farklı bir e-posta adresi veya cep telefonu numarası girin.',
    NO_INTERNET_EXPLORER: "Ne yazık ki, Blink Internet Explorer'ı desteklemiyor. Lütfen Microsoft Edge, Firefox veya Chrome kullanın. Anlayışınız için teşekkür ederiz.",
    loginNow: 'Şimdi kayıt olun',
    help: 'Kayıt konusunda yardım',
    helpText: 'Blink Manager uygulaması zamanları, personeli ve sistem yapılandırmasını yönetmenizi sağlar. Blink Manager sadece süpervizörler, mülk yöneticileri, yönetim ve idare içindir! Salt zaman kaydı için "Blink Time App "i kullanın!<br/><br/>Kayıt olmak için kullanabileceğiniz iki yöntem vardır:<br/><br/><b>Blink ID kartı ile kaydolun</b><br/><br/>Blink ID kartınız varsa, bu yöntemi seçin. İlk kayıt olduğunuzda sizden bir e-posta adresi veya cep telefonu numarası istenecektir. Kayıt için size tek seferlik bir şifre göndermek için buna ihtiyacımız var. Tek seferlik şifreyi hatırlamanıza gerek yoktur. Tekrar kayıt olduğunuzda, size yeni bir tek seferlik şifre göndereceğiz.<br/><br/><b>Kullanıcı adı ve şifre ile giriş</b><br/><br/>Kullanıcı adı ve şifreyi yalnızca talep üzerine sistem yöneticinizden alacaksınız. Bu yöntem yalnızca yönetici haklarına sahip kullanıcılar tarafından kullanılabilir.<br/><br/><b>Destek</b><br/><br/>Giriş yaparken sorun yaşarsanız, support@blink-time.de adresine bir e-posta yazın veya 0911 240 330 0 numaralı telefonu arayın.',
    userOffline: "Blink me'yi kullanmak için lütfen internete bağlanın.",
    login: 'Şimdi kayıt olun',
    codeError: 'Lütfen girişinizi kontrol edin ve tekrar deneyin.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Tebrikler!',
    YOU_DID_IT: 'Başardın.',
    SIGNED_IN: 'Kayıtlı',
    SIGNED_IN_TEXT: 'Başarıyla kaydoldunuz ve artık uygulamayı kullanabilirsiniz.',
    START_USAGE: 'Şimdi uygulamayı kullanabilirsiniz.',
    START: 'Başlangıç'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Erişim verilerinizi değiştirebileceğiniz bir e-posta tarafınıza gönderilmiştir.',
    FORGOT_PASSWORD: 'Şifre unutuldu mu?',
    errors: {
      notAllowed: 'Bu kullanıcı için şifre sıfırlanamaz.',
      invalidToken: 'Şifrenizi sıfırlamak için kullandığınız kodun süresi doldu. Lütfen yeni bir kod talep edin.'
    },
    reset: {
      backToRoot: 'Giriş sayfasına geri dönün',
      renewCode: 'Yeni kod talep edin',
      resetPassword: 'Şifreyi sıfırla',
      success: 'Parolanız başarıyla sıfırlandı.',
      password1: 'Yeni Parolanız',
      password2: 'Şifreyi tekrarla',
      validationError: 'Şifre, şifre yönergelerimize uygun değil!',
      passwordsNotEqual: 'Şifreler aynı değil! Lütfen girişinizi kontrol edin.',
      passwordValidation: 'Parola en az 6 karakter uzunluğunda olmalı ve bir harf, bir sayı ve bir özel karakter içermelidir.'
    },
    FORGOT_PASSWORD_TEXT: 'Şifrenizi sıfırlamak için lütfen e-posta adresinizi ve hedef sistemi girin.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-posta'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Verileri kontrol edin',
    TEXT: 'Şifrenizi sıfırlamamız gerekirse, e-posta adresinize ve isteğe bağlı olarak cep telefonu numaranıza ihtiyacımız olacaktır.',
    EMAIL: 'E-posta adresi',
    MOBILE: 'Cep telefonu numarası'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Kodu girin',
    EMAIL_TEXT: 'Aşağıdaki e-posta adresine gönderdiğimiz kodu girin:',
    SMS_TEXT: 'Aşağıdaki cep telefonu numarasına gönderdiğimiz kodu girin:',
    NO_CODE: 'Bir kod almadım.',
    CODE_LENGTH_ERROR: 'Girilen kod altı karakterden daha uzun. Lütfen kodunuzu tekrar girin.',
    CODE_SENT: 'Kod gönderildi.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Kod yok',
    TEXT1: 'Kod almadınız mı?',
    RESEND_CODE: 'Kodu tekrar gönder',
    TEXT2: 'Sistemde kayıtlı e-posta adresiniz veya cep telefonu numaranız değiştiyse, lütfen yöneticinizle iletişime geçin.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: "Lütfen Blink ID'nizi ve kodunuzu girin. Tüm bilgileri kayıt kartınızda bulabilirsiniz.",
    textCredentials: 'Sistem yöneticiniz size kullanıcı adı ve parola sağlamalıdır.',
    BLINK_ID_INVALID: 'Blink ID arızalı',
    loginCardNotFound: 'Blink ID ya zaten atanmıştır ya da mevcut değildir.',
    WRONG_CREDENTIALS: 'Girilen veriler doğru değil. Lütfen girişinizi kontrol edin.',
    loginViaBlinkId: 'BlinkID ile Kayıt',
    loginViaCredentials: 'Erişim verileriyle giriş yapın',
    CODE: 'Kod',
    codePlaceholder: 'Kişisel erişim kodunuz',
    company: 'Sistem',
    password: 'Şifre',
    FORGOT_PASSWORD: 'Şifremi unuttum',
    username: 'e-posta'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'Tanımlama',
    TEXT_1: 'Sizi benzersiz bir şekilde tanımlayabilmemiz için size SMS veya e-posta ile tek seferlik bir kod göndereceğiz.',
    TEXT_2: 'Bunun için lütfen cep telefonu numaranızı veya e-posta adresinizi girin.'
  },
  update: {
    update: 'Güncelleme',
    latestVersion: 'Yeni bir sürüm mevcut. Bundan tam olarak yararlanmak için lütfen uygulamanızı güncelleyin.',
    warnVersion: 'Bu uygulamanın eski bir sürümünü kullanıyorsunuz. Lütfen mümkün olan en kısa sürede güncelleyin.',
    blockVersion: 'Bu uygulamanın artık desteklenmeyen eski bir sürümünü kullanıyorsunuz. Lütfen şimdi güncelleyin.'
  },
  inProgress: 'Devam etmekte',
  video_offline: 'Videolar çevrimdışı modda görüntülenemez; lütfen internete bağlanın.',
  location_offline: "Nesne çevrimdışına kaydedilmedi; QR kodunu almak için lütfen İnternet'e bağlanın.",
  invalidObjectCode: 'Geçerli nesne kodu yok.',
  invalidValue: 'geçersiz değer',
  ERROR: {
    ERROR_400: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz. (Hata kodu: 400)',
    ERROR_403: 'Bu eylemi gerçekleştirme izniniz yok',
    REFRESH_TOKEN_403: 'Erişim verileriniz artık geçerli değil. Lütfen tekrar oturum açın!',
    ERROR_500: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz. (Hata kodu: 500)'
  },
  list: {
    entityPluralDefault: 'Sonuçlar',
    emptyState: '{{entityPlural}} yok.',
    noResults: '{{entityPlural}} bulunamadı.',
    selectAll: "Tüm {{entityPlural}}'u seç",
    massAction: '{{count}} {{entityPlural}} için promosyon',
    massActionAllGlobal: "Tüm {{count}}'u seç",
    massActionDeSelectAllGlobal: 'Tüm {{count}} seçimini kaldır'
  },
  languages: {
    ar: 'Arapça',
    de: 'Almanca',
    es: 'İspanyol',
    fr: 'Fransızca',
    hr: 'Hırvat',
    it: 'İtalyan',
    pt: 'Portekizce',
    ru: 'Rusça',
    bg: 'Bulgarca',
    en: 'İngilizce',
    fa: 'Farsça',
    el: 'Yunan',
    hu: 'Macarca',
    pl: 'Lehçe',
    ro: 'Romen',
    tr: 'Türkçe',
    uk: 'Ukrayna',
    cs: 'Çek',
    sk: 'Slovak'
  },
  now: 'Şimdi',
  allCompanies: 'Tüm müşteriler',
  createDate: 'Oluşturulma tarihi',
  company: 'müşteri',
  new: 'Yeni',
  of: 'itibaren',
  request: {
    deleteTitle: "{{item}}'ı sil",
    confirmDelete: "'{{itemName}}' girişini gerçekten silmek istiyor musunuz?",
    itemAdded: '{{item}} oluşturuldu.',
    itemAddedError: '{{item}} oluşturulamadı.',
    itemSaved: '{{item}} kaydedildi.',
    itemSavedError: '{{item}} kaydedilemedi.',
    itemRemoved: '{{item}} silindi.',
    itemRemovedError: '{{item}} silinemedi.'
  },
  news: { pageTitle: 'Haberler', edit: 'Haber raporlarını düzenle' },
  profiles: {
    loginProfiles: 'Giriş profilleri',
    add: 'Giriş profili',
    logout: 'Oturumu kapatırsanız tüm yerel veriler ve profiller silinecektir! Abonelikten çıkmak istiyor musunuz?',
    login: 'Kayıt olmak',
    deleteConfirmation: 'Profili gerçekten silmek istiyor musun?',
    changed: 'Profil başarıyla değiştirildi',
    deleted: 'Profil başarıyla silindi'
  },
  deleteAttachment: {
    confirmTitle: 'Eki sil',
    confirmText: 'Bu eki silmek istediğinizden emin misiniz?'
  },
  comments: {
    addComment: 'yorum ekle',
    addCommentInfo: 'Yorum, bu bileti görüntüleyebilen herkes tarafından görülebilir.',
    comment: 'Yorum',
    deleteComment: 'Yorumu sil',
    deleteCommentConfirmation: 'Yorumu gerçekten silmek istiyor musun?'
  },
  confirm: 'Onaylamak',
  firstName: 'İlk adı',
  lastName: 'Soy isim',
  status: 'durum',
  attachment: 'EK'
};
