export default {
  home: {
    pageTitle: 'Pagina inicial',
    welcome: 'Bem-vindo ao aplicativo Blink me. Divirta-se conversando,\n' +
      '\n' +
      'Sua equipe Blink',
    startChat: 'começar',
    ticket: 'bilhete',
    timeLicenseMissing: 'Você não recebeu uma licença Blink Time!'
  },
  conversations: {
    pageTitle: 'Notícias',
    yourChats: 'Seus bate-papos',
    chat: 'Bater papo'
  },
  settings: {
    settings: 'Configurações',
    pushEnabled: 'Receba notificações push',
    system: 'sistema',
    languageSelection: 'seleção de idioma',
    currentSettings: 'Configurações atuais',
    user: 'do utilizador',
    appVersion: 'Versão (aplicativo)',
    apiVersion: 'Versão (API)',
    deviceInfo: 'Versão do dispositivo',
    buildVersion: 'versão (compilação)',
    helpAndDataPolicy: 'Ajuda e proteção de dados',
    text1: 'Nossa equipe de suporte terá prazer em ajudá-lo se você tiver alguma dúvida:',
    phone: 'telefone',
    mail: 'e-mail',
    information: 'informações',
    documentation: 'Documentação on-line',
    documentationText: 'Em nossa documentação online você encontrará instruções e vídeos para todas as áreas importantes do Blink.',
    dataPolicy: 'Proteção de dados',
    support: 'Apoiar',
    moreActions: 'Ações futuras',
    protocol: 'protocolo',
    showChatUserSecret: 'Compartilhe bate-papo com outro dispositivo',
    scanCodeAnotherDevice: 'Digitalize o seguinte código QR no seu novo dispositivo ou insira o seu código de chat pessoal no novo dispositivo!',
    yourPersonalCode: 'Seu código de bate-papo pessoal',
    permissions: {
      title: 'Permissões',
      grantPermission: 'conceder permissão',
      camera: 'Câmera',
      cameraHint: 'Necessário para registro de horas via código objeto.',
      nfc: 'NFC',
      nfcHint: 'Necessário para registro de tempo via etiqueta NFC.',
      media: 'Pegue imagem e imagem da biblioteca',
      mediaHint: 'Necessário para capturar imagens e documentos relacionados aos seus horários e ingressos.',
      location: 'Localização',
      locationHint: 'Necessário registrar sua localização no momento da gravação do tempo.',
      notifications: 'Notificações via push',
      notificationsHint: 'Necessário para receber notificações push para mensagens de bate-papo, notificações piscantes e adesivos.',
      locationDisabledOnSystem: 'Os serviços de localização estão desativados. Por favor, ative isso nas configurações do seu dispositivo.'
    }
  },
  connectionState: {
    connecting: 'Conectar...',
    connected: 'Amarrado junto.',
    disconnecting: 'A conexão está desconectada...',
    disconnected: 'conexão perdida',
    denied: 'Não foi possível estabelecer a ligação.'
  },
  noOneToFind: 'Nenhum a ser encontrado.',
  nothingToFind: 'Nada a ser encontrado.',
  noLocationFound: 'Nenhum objeto encontrado.',
  searchPlaceholder: 'Procurar...',
  employeeSearchPlaceholder: 'Primeiro nome',
  typeToSearch: 'Toque para pesquisar...',
  typeToSearchEmployee: 'Digite o nome da pessoa para iniciar um bate-papo',
  createChatWithEmployee: 'Procurando funcionários',
  createChatWithLocationTeam: 'Objeto de pesquisa',
  createTeamleaderChat: 'Todos os meus funcionários',
  yourLanguageSelection: 'Sua seleção de idioma:',
  loginInfo: 'Para se registrar, escaneie seu código QR pessoal:',
  updating: 'Blink me está sendo atualizado.',
  notice: 'Perceber',
  importantNotice: 'Nota importante',
  continue: 'Avançar',
  intro: {
    slide1: {
      header: 'Bem-vindo',
      text: 'Divirta-se com seu aplicativo Blink me.'
    },
    slide2: {
      header: 'Bater papo',
      text: 'Converse diretamente com seus colegas ou descubra as últimas notícias de grupos interessantes.'
    },
    slide3: {
      header: 'Ingressos',
      text: 'Aqui você pode ver suas tarefas e criar aplicativos.'
    }
  },
  pleaseWait: 'Por favor, aguarde...',
  skip: 'Pular',
  startAgain: 'Começar de novo',
  lock: {
    createPinHeader: 'Seu PIN pessoal',
    createPinInfo: 'Para proteger seus dados, você pode definir aqui um PIN que deverá inserir sempre que usar o aplicativo Blink me.',
    validatePin: 'Por favor, insira o PIN novamente.',
    tryAgain: 'O PIN não corresponde. Por favor, tente novamente.',
    triedToOften: 'Infelizmente o seu PIN não corresponde. Deseja reiniciar o processo ou ignorá-lo e fazê-lo mais tarde?',
    enterPinHeader: 'Entrada de alfinete',
    enterPinInfo: 'Por favor, insira seu PIN para desbloquear o aplicativo.',
    remainingTrys: 'Mais {{amount}} tentativas. Você então terá que fazer login no aplicativo novamente.',
    tooManyEnters: 'Infelizmente, o registro com um PIN não é mais possível. Faça login novamente com seu cartão Blink ID.',
    reSignIn: 'Novo registro',
    setpin: 'Entrada do PIN ao usar o aplicativo',
    skip: 'Você não deseja atribuir um PIN agora? Você pode ativar esta função a qualquer momento através das configurações do aplicativo, inclusive mais tarde.',
    dontUsePin: 'não use um PIN',
    deactivatePinHeader: 'Desativar PIN',
    deactivatePinInfo: 'Por favor, insira seu PIN para desativar a proteção.'
  },
  active: {
    yourTickets: 'Seus ingressos',
    createNewTask: 'Crie um novo tíquete',
    assignee: 'editor',
    location: 'objeto',
    dueDate: 'Devido a',
    createTask: 'Criar ingresso',
    taskCreated: 'O ticket foi criado.',
    backToTasks: 'Voltar aos ingressos',
    createdAt: 'criado em',
    attachments: 'Anexos',
    addFileOrImage: 'Adicionar arquivo ou foto',
    addImage: 'adicionar uma foto',
    comments: 'Comentários',
    addComment: 'adicionar comentário',
    addCommentInfo: 'O comentário fica visível para qualquer pessoa que possa visualizar este ticket.',
    commentCreated: 'O comentário foi salvo.',
    showClosed: 'Ver tickets concluídos',
    resetFilter: 'redefinir todos os filtros',
    changeTicketStatusTo: 'Alterar o status do ticket para:',
    editTask: 'Editar ingresso',
    saveTask: 'Salvar ingresso',
    taskSaved: 'O ticket foi salvo.',
    deleteImage: 'Imagem clara',
    deleteImageConfirmation: 'Você realmente deseja excluir a imagem?',
    deleteAttachment: 'Excluir anexo',
    deleteAttachmentConfirmation: 'Tem certeza de que deseja excluir este anexo?',
    deleteComment: 'Excluir comentário',
    deleteCommentConfirmation: 'Você realmente deseja excluir o comentário?',
    changeAssignee: 'Alterar editor',
    duplicateTask: 'Bilhete duplicado',
    duplicateTaskConfirmation: 'Deseja duplicar este ticket?',
    taskDuplicated: 'O ingresso foi duplicado.',
    deleteTask: 'Excluir tíquete',
    deleteTaskConfirmation: 'Tem certeza de que deseja excluir este ticket?',
    taskDeleted: 'O ticket foi excluído.',
    assigneeChanged: 'O processador foi alterado.',
    statusChanged: 'O status do ticket foi alterado.',
    startDate: 'começar',
    endDate: 'Fim',
    doctorVisitDate: 'Data da consulta médica',
    doctorVisitDescription: 'Data da consulta médica: {{date}}',
    chooseDate: 'Escolha uma data',
    comment: 'Comentário (opcional)',
    dateError: 'A data de término não pode ser anterior à data de início',
    employee: 'Funcionários',
    pleaseConnectToLoad: 'Por favor, estabeleça uma conexão com a Internet para carregar seus ingressos.'
  },
  emptyState: {
    chats: 'Crie um bate-papo!',
    chat: 'Escreva a primeira mensagem :)',
    tasks: 'Não foram encontrados ingressos.',
    attachments: 'Nenhum anexo disponível.',
    comments: 'Nenhum comentário disponível.',
    vacation: 'Não há férias inseridas para este ano'
  },
  formError: {
    min: 'O valor deve ser maior que {{value}}.',
    min2: 'O valor deve ser maior ou igual a {{value}}.',
    max: 'O valor deve ser menor ou igual a {{value}}.',
    requiredBecause: 'Valor obrigatório porque {{name}} está preenchido.',
    isRequired: '{{name}} é obrigatório.',
    numberInUse: 'O número pessoal já está em uso.',
    maxlength: '{{name}} não pode conter mais de {{value}} caracteres.',
    faultyBlinkId: 'ID de piscada incorreto'
  },
  ngSelect: {
    typeToSeach: 'Por favor insira 3 letras ou mais.',
    loading: 'carregando dados...',
    notFound: 'Nenhum {{title}} encontrado.',
    clearAll: 'Restaurar ao padrão'
  },
  placeholder: {
    employee: 'Pesquise por nome e sobrenome, número pessoal, Blink ID',
    location: 'Pesquise por nome, número ou códigos de objeto',
    date: 'Escolha uma data',
    searchTasks: 'Pesquisar ingressos...'
  },
  hasChat: 'Iniciar bate-papo agora',
  hasNoChatYet: 'Infelizmente, ainda não instalei o aplicativo Blink me.',
  noBlinkActiveLicence: 'Sem licença',
  noBlinkActiveLicenceInfo: 'Atualmente você não tem uma licença para este aplicativo. Entre em contato com seu supervisor.',
  noBlinkMeLicence: 'Sem licença',
  noBlinkMeLicenceInfo: 'Este aplicativo não pode ser usado com o seu sistema. Use o Blink Time do aplicativo ou Playstore.',
  noSuitableAppFound: 'Nenhum aplicativo encontrado para abrir o arquivo.',
  myChats: 'Seus bate-papos',
  hasNoChatsHome: 'Comece a conversar agora! Você pode conversar com todos os colegas e participar de grupos de objetos e equipes.',
  hasNoTicketsHome: 'Não há ingressos abertos disponíveis.',
  myTickets: 'Seus ingressos',
  myEmployees: 'Meus funcionários',
  group: 'grupo',
  confirmLogoutWithChat: 'Se você sair, suas mensagens de bate-papo serão excluídas. Você gostaria de continuar?',
  messageNotDecryptable: 'A mensagem não pôde ser descriptografada.',
  chat: {
    userAlreadyExists: 'Você já configurou o bate-papo em outro dispositivo. Para poder usar o chat neste dispositivo, você deve vincular os dispositivos. Alternativamente, você pode reiniciar a configuração, mas o chat não poderá mais ser usado no outro dispositivo.',
    linkDevices: 'Vincular dispositivos',
    withoutChat: 'Continuar sem bate-papo',
    setupAgain: 'Redefinir bate-papo',
    setupAgainText: 'Se você configurar o chat novamente, todas as mensagens dos chats individuais existentes serão excluídas e o chat não poderá mais ser usado em outros dispositivos! Você gostaria de continuar?',
    LinkDevicesText: 'Para vincular os dispositivos para bate-papo, abra Configurações em seu outro dispositivo e selecione “Compartilhar bate-papo com outro dispositivo”.',
    personalToken: 'Seu código de bate-papo pessoal',
    wrongCode: 'O código de chat fornecido está incorreto.',
    beingPrepared: 'O bate-papo está sendo preparado.',
    twilioAccessDenied: 'Não foi possível estabelecer a ligação ao serviço de chat. Entre em contato com o suporte da Blink.',
    notificationsDenied: 'As notificações estão desativadas no seu dispositivo/navegador. Vá para Configurações e ative notificações para Blink me receber notificações. Você pode então ativar notificações em suas configurações pessoais.',
    deleted: 'O bate-papo foi excluído',
    unavailable: 'O bate-papo não está disponível',
    participant: 'Participante',
    teamChatDescription: 'Todos os funcionários para os quais você está registrado como gerente são adicionados a este grupo de bate-papo.',
    locationChatDescription: 'Os funcionários são adicionados automaticamente se tiverem um agendamento para este objeto nas últimas quatro semanas ou nas próximas quatro semanas.',
    createEmployeeChatDescription: 'Um chat privado será criado com o funcionário selecionado.',
    teamChatCreationTitle: 'Criar grupo de bate-papo',
    createTeamChatDescription: 'É criado um grupo de chat no qual são adicionados automaticamente todos os funcionários para os quais você está cadastrado como gerente.',
    createLocationChatDescription: 'Um grupo de bate-papo é criado para o objeto selecionado, onde os funcionários são adicionados automaticamente se tiverem um plano para isso nas últimas quatro semanas ou nas próximas quatro semanas.'
  },
  protocol: {
    SEND_EMAIL: 'Enviar por email',
    DELETE: 'Log clara',
    deleteMessage: 'Você realmente deseja excluir o log? Essa ação não pode ser desfeita.',
    Send: 'Enviar',
    SuccessMessage: 'O log foi enviado com sucesso para o Suporte Blink.',
    ConfirmMessage: 'Primeiro entre em contato com a equipe de suporte e só envie o log se solicitado.',
    Description: 'Descrição do Problema'
  },
  timeTracking: {
    title: 'Controle de tempo',
    start: 'Começar',
    startDescription: 'Clique em Iniciar para iniciar o monitoramento do tempo',
    started: 'Iniciado',
    stop: 'Parar',
    duration: 'Duração de tempo',
    syncButtonLabel: 'Tempos de upload registrados off-line',
    offlineSyncMessage: 'Para transferir os tempos salvos você deve estar online.',
    successfulSync: 'Os horários são carregados',
    scanNowQrCode: 'Código QR',
    scanNowQrCodeDescription: 'Digitalize o código QR no objeto.',
    scanNowNfc: 'NFC',
    scanNowNfcDescription: 'Segure seu smartphone contra o adesivo NFC do objeto.',
    nfcDescription: 'Detecção via NFC: segure seu celular contra o código objeto',
    noTimeTrackingMethodAvailableDescription: 'Não há método de controle de tempo configurado para este usuário. Entre em contato com o líder da sua equipe.',
    noLocationCode: 'Nenhum código disponível',
    noLocationCodeDescription: 'Selecione esta opção se não houver código QR ou adesivo NFC no objeto. Fornecer um comentário é obrigatório.',
    noLocationCodeConfirm: 'Tem certeza de que deseja começar sem código-objeto? Você deve fornecer um comentário para parar.',
    wrongCode: 'O código QR digitalizado não é um código objeto!',
    readNotice: 'Por favor, leia as instruções!',
    warning: 'aviso',
    lessThanAMinuteTitle: 'Registro de tempo completo',
    lessThanAMinute: 'Você começou há menos de 1 minuto. Tem certeza de que deseja parar?',
    continue: 'Continuar gravando',
    timeAlert: {
      differenceInMinutesHint: 'A hora no seu smartphone é diferente da hora no servidor. A diferença é registrada',
      differentTimeZoneHint: 'O fuso horário do seu smartphone é diferente do fuso horário do servidor. A diferença é registrada.'
    },
    leaveComment: {
      withCode: 'As informações sobre o objeto/serviço não estavam claras. Por favor, informe-nos em qual imóvel/serviço você trabalhou.',
      withoutCode: 'Nenhum código objeto foi capturado no início ou na parada. Explique por que você iniciou/parou sem um objeto.'
    },
    comment: 'Comente',
    enterComment: 'Comente sobre o controle de tempo',
    differentQrCode: {
      title: 'Alterar objeto?',
      continueInLocation: 'Continue trabalhando em {{locationName}}.',
      automaticStopInLocation: 'A gravação do tempo em {{locationName}} irá parar automaticamente.',
      stopInLocation: 'Pare a gravação do tempo em {{locationName}}.',
      stop: 'Parar',
      stopNoLocation: 'Pare a gravação do tempo.',
      continueNoOldLocation: 'A gravação do tempo do objeto anterior é interrompida automaticamente.',
      continueWorking: 'Continue trabalhando',
      continueWorkingInNewLocation: 'Continue trabalhando em novas propriedades.',
      stopWithLocationActivity: 'Pare a gravação do tempo em {{locationActivity}}.',
      continueWithNewLocationActivity: 'Continue trabalhando com {{locationActivity}}',
      stopOldLocationActivity: 'A gravação do tempo em {{locationActivity}} é interrompida automaticamente.',
      stopOldNoLocationActivity: 'A gravação de tempo sem performance é interrompida automaticamente.',
      continueNoLocationActivity: 'Continue trabalhando sem desempenho.',
      sameLocationTitle: 'Alterar o desempenho?',
      differentLocationTitle: 'Alterar objeto?'
    },
    saveAndStop: 'Salvar e parar',
    noLocationScanned: '- Nenhum objeto -',
    noLocationFound: '- Objeto não claro -',
    timerDifference: '{{h}} horas {{mm}} minutos',
    stopSuccess: 'Hora registrada com sucesso',
    stopSuccessToast: 'A gravação de tempo foi interrompida com sucesso.',
    stopTimeTracking: 'Parar',
    manual: {
      time: 'Tempo',
      locationSelect: 'Selecione o objeto',
      loadMore: 'Carregue mais',
      trackTime: 'Tempo recorde',
      location: 'objeto',
      locationActivity: 'Desempenho',
      date: 'Data',
      startTime: 'De',
      endTime: 'Até',
      create: 'confirme',
      createAgain: 'Registre o tempo adicional para este objeto',
      errorUntil: 'O tempo De deve ser menor que o Tempo Até',
      worktimeDifference: 'Jornada de trabalho:',
      worktimeDifferenceValue: '{{hours}} horas {{minutes}} minutos',
      worktimeDifferenceValueMinutes: '{{minutes}} minutos',
      success: 'Hora registrada com sucesso',
      break: 'Quebrar:',
      breakMinutes: '{{minutes}} minutos',
      breakHoursAndMinutes: '{{hours}} horas {{minutes}} minutos',
      noActivity: '- sem desempenho -',
      locationPlaceHolder: 'Pesquise por nome, número ou códigos de objeto',
      conflictError: 'Já existe registro de horas nos períodos especificados. Sobreposições não são permitidas. Por favor, verifique seus detalhes.',
      currentDayDuration: 'Já gravado',
      expectedDuration: 'Horas de trabalho previstas',
      expectedDurationIncludedCurrent: 'incluindo entrada atual'
    },
    differenceInMinutesHint: 'A hora no seu smartphone é diferente da hora no servidor. A diferença é registrada',
    differentTimeZoneHint: 'O fuso horário do seu smartphone é diferente do fuso horário do servidor. A diferença é registrada.',
    proposed: {
      capture: 'Capturar',
      time: 'Tempo',
      captureTime: 'Tempo recorde',
      button: 'confirme',
      title: 'Confirme o horário de trabalho',
      includingBreak: '(incluindo pausa)',
      from: 'De',
      to: 'Até',
      break: 'Quebrar',
      hour: 'Relógio',
      confirm: {
        description: 'Por favor confirme seu horário de trabalho',
        confirm: 'Sim está certo',
        editButton: 'Não, isso não está certo'
      },
      edit: {
        description: 'Especifique a duração',
        breakIncluded: 'Incluindo {{ duration }} intervalo',
        comment: 'Comente',
        yourComment: 'seu comentário',
        minutes: 'minutos',
        hours: 'Horas'
      },
      reasons: {
        overtime: 'Horas extras por conta do cliente',
        faster: 'Feito mais rápido',
        other: 'Diversos',
        representative: 'Eu era um substituto'
      },
      description: 'Confirme seu horário de trabalho assim que terminar de trabalhar na propriedade.'
    },
    codeNotAssignable: 'O código QR digitalizado não pode ser atribuído!',
    running: 'A gravação do tempo está em execução'
  },
  timeFramePicker: {
    title: 'Selecione um período',
    today: 'Hoje',
    currentWeek: 'Semana atual',
    currentMonth: 'Mês atual',
    custom: 'Personalizado',
    startDate: 'começar',
    endDate: 'Fim'
  },
  timeSheet: {
    title: 'Tempos e planejamento',
    noPlannings: 'Sem planejamento',
    planned: 'Planejado',
    workingTime: 'jornada de trabalho',
    workloadDuration: 'Tempo de trabalho semanal',
    workloadDurationShort: 'WAZ',
    plannedDuration: 'Deve',
    workedDuration: 'É',
    hasWorkload: 'Trabalhe naquele dia',
    pleaseConnectToLoad: 'Por favor, estabeleça uma conexão com a Internet para carregar seus horários.',
    footer: {
      planned: 'Planejado',
      worklogsTotal: 'Total de horas',
      workloadTotal: 'Horário conforme contrato',
      difference: 'diferença'
    },
    homepageTitle: 'Seu dia de trabalho'
  },
  planning: {
    planRoute: 'Planejar rota',
    title: 'planejamento',
    modifyMinutes: 'Taxa fixa:',
    includingPause: 'Quebrar:',
    noNavAppFound: 'Nenhum aplicativo de navegação encontrado'
  },
  worklog: {
    title: 'Tempo registrado',
    absenceTitle: 'ausência',
    workingTime: 'jornada de trabalho',
    breakDuration: 'Quebrar',
    reduce: 'taxa fixa',
    attachments: 'Anexos',
    comments: 'Comentários',
    comment: 'Comente',
    noLocation: 'Nenhum objeto especificado',
    changedBy: 'Hora alterada de {{firstName}} {{lastName}}.',
    confirmAttachmentDelete: 'Tem certeza de que deseja excluir este anexo?',
    attachmentDeleted: 'O anexo foi excluído',
    confirmCommentDelete: 'Tem certeza de que deseja excluir este comentário?',
    commentDeleted: 'O comentário foi excluído'
  },
  tabs: {
    home: 'Lar',
    times: 'vezes',
    chat: 'Bater papo',
    tickets: 'Ingressos',
    more: 'mais'
  },
  profile: {
    title: 'perfil',
    testing: 'Teste',
    testingDescription: 'Esta área só é visível quando conectado a um sistema de teste.',
    testingAllowQrCodeOnWeb: 'Permitir entrada manual do código QR',
    pushNotifications: 'Notificações via push',
    reloadUserContext: 'Recarregar UserContext',
    pushNotificationSuccessful: 'Notificação push recebida com sucesso',
    editProfilePicture: 'alterar a foto do perfil',
    editProfilePictureTitle: 'Selecione a seção',
    pushNotificationNotice: 'A autorização “Editar configurações do cliente” é necessária para o teste.',
    send: 'Enviar',
    profilePicture: 'Foto do perfil'
  },
  help: { title: 'Ajuda' },
  myVacations: {
    vacation: 'Férias',
    amountAvailableVacationDays: '{{amount}} dias de férias ainda disponíveis',
    totalVacationDays: 'Direito a férias {{ total }} dias',
    remainingVacationDays: '({{ total }} dias {{ sign }} {{ remaining }} dias de férias restantes {{ previousYear }})',
    xDaysVacations: '{{ total }} dias de férias',
    title: 'Suas férias'
  },
  externalLinks: {
    title: 'links externos',
    notice: 'Este link foi fornecido pela sua empresa. Você está saindo do sistema Blink.'
  },
  stickers: {
    title: 'Seus adesivos',
    sticker: 'Adesivos',
    sender: 'Destinatário',
    text: 'A respeito de',
    date: 'Data',
    emptyState: 'Infelizmente você ainda não tem adesivos',
    senderDescription: 'Este adesivo foi enviado a você por {{sender}} em {{date}}',
    readed: 'ler',
    notReaded: 'ainda não li',
    newStickerToaster: 'Novo adesivo Blink!',
    open: 'Abrir',
    next: 'Avançar',
    titleCases: {
      case1: 'Parabéns pelo seu primeiro adesivo!',
      case2: 'Ótimo, agora você já tem <b>{0}}</b> adesivos!',
      case3: 'Parabéns pelo seu 10º adesivo!',
      case4: '30 adesivos - uau!',
      case5: 'Agora está ficando lotado aqui: <b>{{count}}</b> Adesivos!'
    },
    confirmDelete: 'Você realmente deseja excluir esses adesivos?'
  },
  autoTranslate: {
    isTranslated: 'Traduzido automaticamente',
    translationError: 'A tradução automática não é possível no momento'
  },
  confirmLogout: 'Você realmente deseja sair?',
  message: 'Notícias',
  migration: {
    wrongVersion: 'Na versão atual do Blink me, o login por meio do aplicativo Blink Time não é compatível. Atualize o aplicativo Blink me para a versão mais recente'
  },
  eLearning: {
    pendingTab: 'Para fazer ({{value}})',
    completedTab: 'Concluído ({{value}})',
    completed: 'Concluído',
    pending: 'Abrir',
    dueFalling: 'Atrasado',
    title: 'E-learning',
    course: 'curso',
    dueDate: 'Válida até',
    topics: 'assuntos',
    completeDate: 'Concluído em',
    status: { Pending: 'abrir', Done: 'concluído' },
    infos: {
      pendingExists: 'Você pode encontrar todos os seus cursos de treinamento abertos aqui.',
      finishOverdue: 'Por favor, faça o treinamento que já está vencido o mais rápido possível.',
      allCompleted: 'Excelente! Você concluiu com êxito todos os seus cursos de treinamento.',
      emptyState: 'Você não tem treinamento atribuído.'
    }
  },
  checklists: {
    title: 'Formulários',
    description: 'Preencha um formulário para um objeto',
    button: 'Selecione o objeto'
  },
  pendingTab: 'Execução ({{value}})',
  completedTab: 'Concluído ({{value}})',
  workOrders: {
    title: 'Pedidos',
    homepageTitle: 'Seus pedidos',
    responsible: 'Responsável',
    customerContact: 'Contato do cliente',
    trackedTime: 'Registro de tempo para o pedido nº{{value}}',
    show: 'Ver pedido',
    trackInfo: {
      confirmAndAcknowledge: 'Por favor, confirme as posições após a implementação e realize um teste de aceitação no local.',
      acknowledgeOnly: 'Por favor, faça uma inspeção no local.',
      confirmOnly: 'Por favor, confirme as posições após a implementação.'
    },
    pleaseConnectToLoad: 'Por favor, estabeleça uma conexão com a Internet para carregar seus pedidos.',
    status: { new: 'Novo', inProgress: 'Execução', completed: 'Concluído' },
    emptyState: 'Não há pedidos em aberto disponíveis.',
    emptyStateCompleted: 'Nenhum pedido concluído disponível.',
    details: {
      title: 'Detalhes do pedido',
      positionsTab: 'Posições ({{value}})',
      acknowledgementsTab: 'diminui ({{value}})'
    },
    acknowledgement: {
      title: 'aceitação',
      start: 'Iniciar aceitação',
      acknowledgedAtDate: 'Removido em',
      itemSelectDescription: 'Selecione todas as posições a serem removidas.',
      customerInformationDescription: 'Forneça os dados de contato do cliente (comprador). Após a aceitação, uma confirmação será enviada para este e-mail.',
      created: 'Aceitação salva com sucesso',
      selectAll: 'Selecionar tudo',
      emptyState: 'Não há aceitações.'
    },
    items: {
      items: 'posições',
      acknowledge: 'Confirmar posição',
      confirmAcknowledge: 'Você confirma que o trabalho foi realizado. O status da posição é atualizado para “Concluído”.',
      status: {
        open: 'Abrir',
        planned: 'Planejado',
        performed: 'Feito',
        acknowledged: 'Removido',
        billed: 'Faturado'
      },
      emptyState: 'Não há vagas disponíveis.'
    },
    trackTime: {
      description: 'Selecione um trabalho para o qual deseja monitorar o tempo.',
      chooseWorkOrder: 'Selecione o pedido',
      choosePosition: 'Selecione a posição'
    }
  }
};
