export default {
  cancel: 'Anuluj',
  blinkSlogan: 'Blink - aplikacja dla firm sprzątających budynki',
  all: 'Wszystkie',
  hello: 'Witam',
  description: 'Opis',
  ok: 'Ok',
  reset: 'Reset',
  edit: 'Edytuj',
  finish: 'Blokada',
  notice: 'Uwaga',
  content: 'Treść',
  back: 'Powrót',
  close: 'Zamknij',
  backToHome: 'Powrót do strony głównej',
  proceed: 'Dalej',
  done: 'Gotowy',
  delete: 'Usuń',
  successful: 'Sukces',
  error: 'Błąd',
  ERROR_OCCURRED: 'Wystąpił błąd lub dane są nieprawidłowe. Spróbuj ponownie.',
  internetRequired: 'Wymagany Internet',
  configuration: 'Konfiguracja',
  logo: 'Logo',
  uploadLogo: 'Prześlij logo',
  deleteLogo: 'Usuń logo',
  duplicate: 'Duplikat',
  importExport: 'Import / Eksport',
  import: 'Import',
  doImport: 'Import',
  export: 'Eksport',
  doExport: 'Eksport',
  excelExport: 'Eksport programu Excel',
  excelExportDownloaded: 'Pobrany eksport programu Excel',
  excelExportError: 'Eksport do programu Excel nie powiódł się',
  pdfDownload: 'PDF Download',
  pdfDownloaded: 'PDF do pobrania',
  configSaved: 'Konfiguracja została zapisana.',
  send: 'Wyślij',
  connecting: 'Połącz...',
  or: 'lub',
  select: 'Wybierz',
  today: 'Dzisiaj',
  camera: 'Kamera',
  gallery: 'Galeria',
  files: 'Pliki',
  upload: 'Przesyłanie plików',
  chooseFiles: 'Wybierz pliki',
  filesTransferring: 'Przesyłanie plików...',
  filesTransferringWait: 'Poczekaj, aż pliki zostaną przesłane.',
  filesTransferred: 'Pliki zostały pomyślnie przesłane.',
  download: 'Pobierz',
  showInactive: 'Pokaż nieaktywne',
  copyLink: 'Kopiuj link',
  linkCopied: 'Link został skopiowany do schowka.',
  permissions: 'Zezwolenia',
  email: 'E-mail',
  emailConfig: 'Konfiguracja poczty e-mail',
  title: 'Tytuł',
  user: 'Użytkownik',
  logout: 'Wylogowanie',
  system: 'System',
  appVersion: 'Wersja (aplikacja)',
  apiVersionCore: 'Wersja\n(Core API)',
  apiVersionCheck: 'Wersja\n(Sprawdź API)',
  deviceInfo: 'Informacje o urządzeniu',
  installBlinkTime: 'Zainstaluj czas migania',
  installBlinkMe: 'Zainstaluj Blink Me',
  installing: 'Wersja {{version}} jest zainstalowana ...',
  companyRequired: 'Wybierz klienta',
  companyRequiredInfo: 'Musisz wybrać klienta, aby kontynuować pracę jako administrator systemu.',
  pleaseChoose: 'Wybierz',
  pleaseFill: 'Wypełnij',
  noEntitiesFound: 'Nie znaleziono żadnych elementów.',
  addCustom: 'Utwórz element:',
  typeToSearchText: 'Wprowadź dwa lub więcej znaków...',
  loading: 'Dane są ładowane...',
  offline: 'Offline',
  youAreOffline: 'Jesteś offline!',
  by: 'z',
  date: 'Data',
  time: 'Czas',
  searchTitle: 'Wyszukiwanie',
  searchTerm: 'Wyszukiwane słowo kluczowe',
  searchLoginUser: 'Wyszukiwanie pracowników',
  searchLoginUserPlaceholder: 'Wyszukiwanie według nazwiska lub imienia',
  location: 'Obiekt',
  searchLocation: 'Obiekt wyszukiwania',
  searchLocationPlaceholder: 'Wyszukiwanie według nazwy obiektu, numeru lub tagów',
  deactivate: 'Dezaktywuj',
  active: 'Aktywny',
  inactive: 'Nieaktywny',
  tag: 'Dzień',
  tags: 'Tagi',
  color: 'Kolor',
  icon: 'Ikona',
  language: 'Język',
  general: 'Ogólne',
  yes: 'Tak',
  no: 'Nie',
  searchThrough: 'Przeglądaj',
  name: 'Nazwa',
  create: 'Utwórz',
  save: 'Zapisz',
  home: 'Strona główna',
  profile: 'Profil',
  templates: 'Szablony',
  noAssignment: 'Nieprzypisany',
  sort: {
    change: 'Polecenie zmiany',
    save: 'Zapisz sekwencję',
    saved: 'Sekwencja została zapisana.'
  },
  signature: {
    signature: 'Podpis',
    pleaseSign: 'Prosimy o podpisanie',
    clickHereToSign: 'Kliknij tutaj, aby podpisać',
    addSignature: 'Dodaj podpis',
    save: 'Przejęcie',
    delete: 'Usuń'
  },
  formError: {
    min: 'Wartość musi być większa niż {{value}}.',
    min2: 'Wartość musi być większa lub równa {{value}}.',
    max: 'Wartość musi być mniejsza lub równa {{value}}.',
    requiredBecause: 'Wartość wymagana, ponieważ {{name}} jest wypełnione.',
    isRequired: '{{name}} jest obowiązkowe.',
    numberInUse: 'Numer personelu jest już używany.',
    maxlength: '{{name}} nie może zawierać więcej niż {{value}} znaków. znaków.',
    email: 'Wprowadź prawidłowy adres e-mail.',
    faultyBlinkId: 'Błąd identyfikatora migania'
  },
  scan: {
    choose: 'Przypisz nowy identyfikator migania',
    text: 'Zeskanuj kod QR na karcie rejestracyjnej.',
    scan_now: 'Skanuj teraz',
    enter_manually: 'Wprowadź ręcznie'
  },
  removeImage: 'Usuń obraz',
  tickets: { assignee: 'Edytor', dueTo: 'Należne do' },
  ngSelect: {
    typeToSearch: 'Wprowadź co najmniej 3 litery.',
    loading: 'load data...',
    notFound: 'Nie znaleziono {{title}}.',
    clearAll: 'Reset'
  },
  help: {
    pageTitle: 'Pomoc',
    text1: 'Nasz zespół wsparcia chętnie odpowie na wszelkie pytania:',
    phone: 'Telefon',
    mail: 'E-mail',
    information: 'Info',
    documentation: 'Dokumentacja online',
    documentationText: 'W naszej dokumentacji online znajdziesz instrukcje i filmy dotyczące wszystkich ważnych obszarów Blink.',
    support: 'Wsparcie'
  },
  REGISTRATION: {
    WELCOME: 'Witamy!',
    chooseMethod: 'Jakiej metody rejestracji chcesz użyć?',
    withBlinkId: 'Karta identyfikacyjna BLINK',
    withCredentials: 'Nazwa użytkownika / Hasło',
    REGISTER: 'Rejestr',
    ALREADY_REGISTERED: 'Mam konto Blink.',
    INVALID_CARD: 'Karta nieważna.',
    noBlinkId: 'Zeskanowany kod QR nie jest prawidłowym identyfikatorem Blink ID',
    scanBlinkId: 'Skanowanie Blink ID',
    ERROR: 'Rejestracja nie mogła zostać zakończona. Wprowadź inny adres e-mail lub numer telefonu komórkowego.',
    NO_INTERNET_EXPLORER: 'Niestety, Blink nie obsługuje przeglądarki Internet Explorer. Prosimy o korzystanie z Microsoft Edge, Firefox lub Chrome. Dziękujemy za zrozumienie.',
    loginNow: 'Zarejestruj się teraz',
    help: 'Pomoc przy rejestracji',
    helpText: 'Aplikacja Blink Manager umożliwia zarządzanie czasem, personelem i konfiguracją systemu. Blink Manager jest przeznaczony tylko dla nadzorców, zarządców nieruchomości, kierownictwa i administracji! Do czystej rejestracji czasu użyj aplikacji "Blink Time App"!<br/><br/>Istnieją dwie metody rejestracji:<br/><br/><b>Zarejestruj się za pomocą karty Blink ID</b><br/><br/>Jeśli posiadasz kartę Blink ID, wybierz tę metodę. Podczas pierwszej rejestracji zostaniesz poproszony o podanie adresu e-mail lub numeru telefonu komórkowego. Jest on nam potrzebny do wysłania jednorazowego hasła do rejestracji. Nie musisz pamiętać jednorazowego hasła. Przy ponownej rejestracji wyślemy nowe jednorazowe hasło.<br/><br/><b>Logowanie za pomocą nazwy użytkownika i hasła</b><br/><br/>Nazwę użytkownika i hasło otrzymasz tylko na żądanie od administratora systemu. Ta metoda jest dostępna tylko dla użytkowników z uprawnieniami administracyjnymi.<br/><br/><b>Pomoc</b><br/><br/>Jeśli masz problemy z logowaniem, napisz e-mail na adres support@blink-time.de lub zadzwoń pod numer 0911 240 330 0.',
    userOffline: 'Aby korzystać z Blink me, należy połączyć się z Internetem.',
    login: 'Zarejestruj się teraz',
    codeError: 'Sprawdź swoje zgłoszenie i spróbuj ponownie.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Gratulacje!',
    YOU_DID_IT: 'Udało ci się.',
    SIGNED_IN: 'Zarejestrowany',
    SIGNED_IN_TEXT: 'Rejestracja przebiegła pomyślnie i możesz teraz korzystać z aplikacji.',
    START_USAGE: 'Teraz możesz korzystać z aplikacji.',
    START: 'Start'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Wysłano do Ciebie wiadomość e-mail, za pomocą której możesz zmienić swoje dane dostępu.',
    FORGOT_PASSWORD: 'Zapomniane hasło?',
    errors: {
      notAllowed: 'Nie można zresetować hasła dla tego użytkownika.',
      invalidToken: 'Kod do resetowania hasła wygasł. Poproś o nowy kod.'
    },
    reset: {
      backToRoot: 'Powrót do strony logowania',
      renewCode: 'Żądanie nowego kodu',
      resetPassword: 'Resetowanie hasła',
      success: 'Hasło zostało pomyślnie zresetowane.',
      password1: 'Nowe hasło',
      password2: 'Powtórz hasło',
      validationError: 'Hasło nie jest zgodne z naszymi wytycznymi dotyczącymi haseł!',
      passwordsNotEqual: 'Hasła nie są identyczne! Sprawdź swoje zgłoszenie.',
      passwordValidation: 'Hasło musi mieć co najmniej 6 znaków i zawierać literę, cyfrę i znak specjalny.'
    },
    FORGOT_PASSWORD_TEXT: 'Aby zresetować hasło, wpisz swój adres e-mail i system docelowy.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-mail'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Sprawdź dane',
    TEXT: 'Jeśli będziemy musieli zresetować hasło, będziemy potrzebować adresu e-mail i opcjonalnie numeru telefonu komórkowego.',
    EMAIL: 'Adres e-mail',
    MOBILE: 'Numer telefonu komórkowego'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Wprowadź kod',
    EMAIL_TEXT: 'Wprowadź kod, który wysłaliśmy na poniższy adres e-mail:',
    SMS_TEXT: 'Wprowadź kod, który wysłaliśmy na następujący numer telefonu komórkowego:',
    NO_CODE: 'Nie otrzymałem kodu.',
    CODE_LENGTH_ERROR: 'Wprowadzony kod jest dłuższy niż sześć znaków. Wprowadź kod ponownie.',
    CODE_SENT: 'Kod został wysłany.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Brak kodu',
    TEXT1: 'Nie otrzymałeś kodu?',
    RESEND_CODE: 'Wyślij kod ponownie',
    TEXT2: 'Jeśli Twój adres e-mail lub numer telefonu komórkowego zapisany w systemie uległ zmianie, skontaktuj się z administratorem.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Wprowadź swój Blink ID i kod. Wszystkie informacje znajdują się na karcie rejestracyjnej.',
    textCredentials: 'Administrator systemu musi podać nazwę użytkownika i hasło.',
    BLINK_ID_INVALID: 'Błąd identyfikatora migania',
    loginCardNotFound: 'Identyfikator Blink ID jest już przypisany lub nie istnieje.',
    WRONG_CREDENTIALS: 'Wprowadzone dane są nieprawidłowe. Sprawdź wprowadzone dane.',
    loginViaBlinkId: 'Rejestracja za pomocą BlinkID',
    loginViaCredentials: 'Logowanie przy użyciu danych dostępu',
    CODE: 'Kod',
    codePlaceholder: 'Twój osobisty kod dostępu',
    company: 'System',
    password: 'Hasło',
    FORGOT_PASSWORD: 'Zapomniałem hasła',
    username: 'e-mail'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'Identyfikacja',
    TEXT_1: 'Abyśmy mogli jednoznacznie zidentyfikować użytkownika, wyślemy mu jednorazowy kod SMS-em lub e-mailem.',
    TEXT_2: 'W tym celu należy podać swój numer telefonu komórkowego lub adres e-mail.'
  },
  update: {
    update: 'Aktualizacja',
    latestVersion: 'Dostępna jest nowa wersja. Zaktualizuj swoją aplikację, aby w pełni z niej korzystać.',
    warnVersion: 'Używasz nieaktualnej wersji tej aplikacji. Prosimy o jak najszybszą aktualizację.',
    blockVersion: 'Używasz przestarzałej wersji tej aplikacji, która nie jest już obsługiwana. Prosimy o aktualizację.'
  },
  inProgress: 'W trakcie',
  video_offline: 'Nie można oglądać filmów w trybie offline. Połącz się z Internetem.',
  location_offline: 'Obiekt nie został zapisany w trybie offline. Aby pobrać kod QR, połącz się z Internetem.',
  invalidObjectCode: 'Brak prawidłowego kodu obiektowego.',
  invalidValue: 'niewłaściwa wartość',
  ERROR: {
    ERROR_400: 'Wystąpił błąd. Spróbuj ponownie później. (Kod błędu: 400)',
    ERROR_403: 'Nie masz uprawnień do wykonania tej czynności',
    REFRESH_TOKEN_403: 'Twoje dane dostępowe nie są już ważne. Proszę zalogować się ponownie!',
    ERROR_500: 'Wystąpił błąd. Spróbuj ponownie później. (Kod błędu: 500)'
  },
  list: {
    entityPluralDefault: 'Wyniki',
    emptyState: 'Nie istnieje żaden {{entityPlural}}.',
    noResults: 'Nie znaleziono żadnego {{entityPlural}}.',
    selectAll: 'Wybierz wszystko {{entityPlural}}',
    massAction: 'Promocja na {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Wybierz wszystko {{count}}',
    massActionDeSelectAllGlobal: 'Odznacz wszystko {{count}}'
  },
  languages: {
    ar: 'arabski',
    de: 'Niemiecki',
    es: 'hiszpański',
    fr: 'Francuski',
    hr: 'chorwacki',
    it: 'Włoski',
    pt: 'portugalski',
    ru: 'Rosyjski',
    bg: 'bułgarski',
    en: 'język angielski',
    fa: 'perski',
    el: 'grecki',
    hu: 'język węgierski',
    pl: 'Polski',
    ro: 'rumuński',
    tr: 'turecki',
    uk: 'ukraiński',
    cs: 'Czech',
    sk: 'słowacki'
  },
  now: 'Teraz',
  allCompanies: 'Wszyscy klienci',
  createDate: 'Data utworzenia',
  company: 'klient',
  new: 'Nowy',
  of: 'z',
  request: {
    deleteTitle: 'Usuń {{item}}',
    confirmDelete: 'Czy na pewno chcesz usunąć wpis „{{itemName}}”?',
    itemAdded: 'Utworzono {{item}}.',
    itemAddedError: 'Nie można utworzyć {{item}}.',
    itemSaved: '{{item}} zostało zapisane.',
    itemSavedError: 'Nie można zapisać {{item}}.',
    itemRemoved: '{{item}} został usunięty.',
    itemRemovedError: 'Nie można usunąć {{item}}.'
  },
  news: { pageTitle: 'Aktualności', edit: 'Edytuj doniesienia prasowe' },
  profiles: {
    loginProfiles: 'Profile logowania',
    add: 'Profil logowania',
    logout: 'Jeśli się wylogujesz, wszystkie dane lokalne i profile zostaną usunięte! Czy chcesz zrezygnować z subskrypcji?',
    login: 'Rejestr',
    deleteConfirmation: 'Czy na pewno chcesz usunąć profil?',
    changed: 'Profil został pomyślnie zmieniony',
    deleted: 'Profil został pomyślnie usunięty'
  },
  deleteAttachment: {
    confirmTitle: 'Usuń załącznik',
    confirmText: 'Czy na pewno chcesz usunąć ten załącznik?'
  },
  comments: {
    addComment: 'Dodaj komentarz',
    addCommentInfo: 'Komentarz jest widoczny dla każdego, kto może wyświetlić to zgłoszenie.',
    comment: 'komentarz',
    deleteComment: 'Usuń komentarz',
    deleteCommentConfirmation: 'Czy na pewno chcesz usunąć komentarz?'
  },
  confirm: 'Potwierdzać',
  firstName: 'Imię',
  lastName: 'Nazwisko',
  status: 'status',
  attachment: 'Załącznik'
};
