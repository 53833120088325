export default {
  blinkSlogan: 'Blink - Aplikacija za čišćenje zgrada',
  all: 'svi',
  hello: 'zdravo',
  description: 'Opis',
  ok: 'u redu',
  cancel: 'Otkazati',
  reset: 'Vrati na zadano',
  edit: 'Uredi',
  finish: 'Kompletan',
  notice: 'Obavijest',
  content: 'Sadržaj',
  back: 'leđa',
  close: 'Zatvoriti',
  backToHome: 'Povratak na početnu stranicu',
  proceed: 'Unaprijediti',
  done: 'Spreman',
  delete: 'Izbrisati',
  successful: 'Uspješno',
  error: 'Pogreška',
  ERROR_OCCURRED: 'Došlo je do pogreške ili su podaci netočni. Molim te pokušaj ponovno.',
  internetRequired: 'Internet potreban',
  configuration: 'konfiguracija',
  logo: 'logo',
  uploadLogo: 'Učitaj logotip',
  deleteLogo: 'Brisanje logotipa',
  duplicate: 'duplikat',
  importExport: 'uvoz izvoz',
  import: 'uvoz',
  doImport: 'Uvoz',
  export: 'izvoz',
  doExport: 'Izvoz',
  excelExport: 'Excel izvoz',
  excelExportDownloaded: 'Excel izvoz preuzet',
  excelExportError: 'Excel izvoz nije uspio',
  pdfDownload: 'Preuzmite PDF',
  pdfDownloaded: 'PDF preuzet',
  configSaved: 'Konfiguracija je spremljena.',
  send: 'Poslati',
  connecting: 'Spojiti...',
  or: 'ili',
  select: 'Odaberite',
  today: 'Danas',
  camera: 'fotoaparat',
  gallery: 'galerija',
  files: 'datoteke',
  upload: 'učitati datoteke',
  chooseFiles: 'odabrati datoteke',
  filesTransferring: 'Pošalji datoteke...',
  filesTransferringWait: 'Molimo pričekajte dok se datoteke pošalju.',
  filesTransferred: 'Datoteke su uspješno poslane.',
  download: 'preuzimanje datoteka',
  showInactive: 'Prikaži neaktivno',
  copyLink: 'Kopiraj link',
  linkCopied: 'Veza je kopirana u međuspremnik.',
  permissions: 'Dozvole',
  email: 'e-pošta',
  emailConfig: 'Konfiguracija e-pošte',
  title: 'titula',
  user: 'korisnik',
  logout: 'Odjavite se',
  system: 'sustav',
  appVersion: 'verzija',
  apiVersionCore: 'verzija\n(Core API)',
  apiVersionCheck: 'verzija\n(Provjeri API)',
  deviceInfo: 'informacije o uređaju',
  installBlinkTime: 'Instalirajte Blink Time',
  installBlinkMe: 'Instalirajte Blink Me',
  installing: 'Instaliranje verzije {{version}}...',
  companyRequired: 'Odaberite klijenta',
  companyRequiredInfo: 'Morate odabrati klijenta da biste nastavili kao sysadmin.',
  pleaseChoose: 'Molim odaberite',
  pleaseFill: 'Molimo ispunite',
  noEntitiesFound: 'Nijedna stavka nije pronađena.',
  addCustom: 'Izradi stavku:',
  typeToSearchText: 'Unesite dva ili više znakova...',
  loading: 'Podaci se učitavaju...',
  offline: 'Izvan mreže',
  youAreOffline: 'Vi ste izvan mreže!',
  by: 'iz',
  date: 'Datum',
  time: 'vrijeme',
  searchTitle: 'Tražiti',
  searchTerm: 'pojam za pretraživanje',
  searchLoginUser: 'traženje zaposlenika',
  searchLoginUserPlaceholder: 'Tražite po imenu ili imenu',
  location: 'objekt',
  searchLocation: 'predmet pretraživanja',
  searchLocationPlaceholder: 'Pretražujte po nazivu objekta, broju ili oznakama',
  deactivate: 'Deaktiviraj',
  active: 'Aktivan',
  inactive: 'Neaktivan',
  tag: 'Dan',
  tags: 'oznake',
  color: 'Boja',
  icon: 'ikona',
  language: 'Jezik',
  general: 'općenito',
  yes: 'Da',
  no: 'Ne',
  searchThrough: 'traži',
  name: 'Prezime',
  create: 'Stvoriti',
  save: 'Uštedjeti',
  home: 'Dom',
  profile: 'profil',
  templates: 'šablone',
  noAssignment: 'neraspoređeno',
  sort: {
    change: 'za promjenu redoslijeda',
    save: 'spremi red',
    saved: 'Narudžba je spremljena.'
  },
  signature: {
    signature: 'Potpis',
    pleaseSign: 'Molimo potpišite',
    clickHereToSign: 'Kliknite ovdje za potpis',
    addSignature: 'Dodajte potpis',
    save: 'Preuzeti',
    delete: 'Izbrisati'
  },
  formError: {
    min: 'Vrijednost mora biti veća od {{value}}.',
    min2: 'Vrijednost mora biti veća ili jednaka {{value}}.',
    max: 'Vrijednost mora biti manja ili jednaka {{value}}.',
    requiredBecause: 'Vrijednost je potrebna jer je {{name}} popunjeno.',
    isRequired: '{{name}} je potrebno.',
    numberInUse: 'Personalni broj je već u upotrebi.',
    maxlength: '{{name}} ne može sadržavati više od {{value}} znakova.',
    email: 'Unesite važeću adresu e-pošte.',
    faultyBlinkId: 'Pogreška ID treptaja'
  },
  scan: {
    choose: 'Dodijelite novi Blink ID',
    text: 'Skenirajte QR kod na registracijskoj kartici.',
    scan_now: 'Skeniraj sada',
    enter_manually: 'Unesite ručno'
  },
  removeImage: 'Jasna slika',
  tickets: { assignee: 'urednik', dueTo: 'Vrijedi do' },
  ngSelect: {
    typeToSearch: 'Unesite 3 ili više slova.',
    loading: 'učitavanje podataka...',
    notFound: '{{title}} nije pronađen.',
    clearAll: 'Vrati na zadano'
  },
  help: {
    pageTitle: 'Pomozite',
    text1: 'Naš tim za podršku rado će odgovoriti na sva vaša pitanja:',
    phone: 'telefon',
    mail: 'e-pošta',
    information: 'info',
    documentation: 'online dokumentacija',
    documentationText: 'U našoj mrežnoj dokumentaciji pronaći ćete upute i videozapise za sva važna područja Blinka.',
    support: 'podrška'
  },
  REGISTRATION: {
    WELCOME: 'Dobrodošli!',
    chooseMethod: 'Koju metodu želite koristiti za prijavu?',
    withBlinkId: 'BLINK iskaznica',
    withCredentials: 'Korisničko ime Zaporka',
    REGISTER: 'registrirati se',
    ALREADY_REGISTERED: 'Imam Blink račun.',
    INVALID_CARD: 'Kartica nije važeća.',
    noBlinkId: 'Skenirani QR kod nije važeći Blink ID',
    scanBlinkId: 'Skeniraj Blink ID',
    ERROR: 'Registracija nije mogla biti dovršena. Unesite drugu adresu e-pošte ili broj mobitela.',
    NO_INTERNET_EXPLORER: 'Nažalost, Blink ne podržava Internet Explorer. Koristite Microsoft Edge, Firefox ili Chrome. Hvala na razumijevanju.',
    loginNow: 'Registriraj se',
    help: 'Pomoć pri registraciji',
    helpText: 'Aplikacija Blink Manager omogućuje vam upravljanje vremenom, osobljem i konfiguracijom sustava. Blink Manager je samo za nadzornike, upravitelje objekata, menadžment i administraciju! Za čisto praćenje vremena koristite "Blink Time App"!<br/><br/>Postoje dvije metode koje možete koristiti za prijavu:<br/><br/><b>Prijava s Blink ID karticom< / b><br/><br/>Ako imate Blink ID karticu, odaberite ovu metodu. Prilikom prve registracije od vas će se tražiti adresa e-pošte ili broj mobitela. Ovo nam je potrebno kako bismo vam poslali jednokratnu lozinku za registraciju. Ne morate pamtiti jednokratnu lozinku. Ako se ponovno prijavite, poslat ćemo vam novu jednokratnu lozinku.<br/><br/><b>Prijavite se korisničkim imenom i lozinkom</b><br/><br/>Vaše korisničko ime i lozinka će dobit ćete samo ako zatražite od administratora sustava. Ova je metoda dostupna samo korisnicima s administratorskim pravima.<br/><br/><b>Podrška</b><br/><br/>Ako imate problema s prijavom, pošaljite podršku e-poštom na @blink-time.de ili nazovite 0911 240 330 0.',
    userOffline: 'Uspostavite internetsku vezu za korištenje Blink me.',
    login: 'Registriraj se',
    codeError: 'Provjerite svoj unos i pokušajte ponovno.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Čestitamo!',
    YOU_DID_IT: 'Uspio si.',
    SIGNED_IN: 'Registriran',
    SIGNED_IN_TEXT: 'Uspješno ste se registrirali i sada možete koristiti aplikaciju.',
    START_USAGE: 'Sada možete koristiti aplikaciju.',
    START: 'početi'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Poslan vam je email s kojim možete promijeniti pristupne podatke.',
    FORGOT_PASSWORD: 'Zaboravili ste zaporku?',
    errors: {
      notAllowed: 'Lozinka se ne može poništiti za ovog korisnika.',
      invalidToken: 'Kôd za ponovno postavljanje lozinke je istekao. Zatražite novi kod.'
    },
    reset: {
      backToRoot: 'Povratak na stranicu za prijavu',
      renewCode: 'Zatraži novi kod',
      resetPassword: 'resetiranje lozinke',
      success: 'Vaša lozinka je uspješno poništena.',
      password1: 'Vaša nova lozinka',
      password2: 'ponovi lozinku',
      validationError: 'Lozinka nije u skladu s našim pravilima o lozinkama!',
      passwordsNotEqual: 'Lozinke nisu identične! Provjerite svoj unos.',
      passwordValidation: 'Lozinka mora imati najmanje 6 znakova i sadržavati slovo, broj i poseban znak.'
    },
    FORGOT_PASSWORD_TEXT: 'Unesite svoju adresu e-pošte i ciljni sustav za poništavanje lozinke.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-pošta'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'provjerite podatke',
    TEXT: 'Ako trebamo poništiti vašu lozinku, potrebna nam je vaša adresa e-pošte i po izboru vaš broj mobitela.',
    EMAIL: 'Email adresa',
    MOBILE: 'Broj mobitela'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Unesite šifru',
    EMAIL_TEXT: 'Unesite kod koji smo poslali na sljedeću adresu e-pošte:',
    SMS_TEXT: 'Unesite kod koji smo poslali na sljedeći broj mobitela:',
    NO_CODE: 'Nisam dobio kod.',
    CODE_LENGTH_ERROR: 'Unesena šifra je duža od šest znakova. Molimo ponovno unesite svoj kod.',
    CODE_SENT: 'Šifra je poslana.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'bez šifre',
    TEXT1: 'Niste dobili kod?',
    RESEND_CODE: 'ponovno poslati kod',
    TEXT2: 'Ako se vaša e-mail adresa ili broj mobitela pohranjen u sustavu promijenio, obratite se svom administratoru.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Unesite svoj Blink ID i kod. Sve podatke pronaći ćete na svojoj registracijskoj kartici.',
    textCredentials: 'Vaš administrator sustava mora vam dati korisničko ime i lozinku.',
    BLINK_ID_INVALID: 'Pogreška ID treptaja',
    loginCardNotFound: 'Blink ID je ili već dodijeljen ili ne postoji',
    WRONG_CREDENTIALS: 'Uneseni podaci nisu točni. Provjerite svoj unos.',
    loginViaBlinkId: 'Prijavite se pomoću BlinkID-a',
    loginViaCredentials: 'Registracija s pristupnim podacima',
    CODE: 'kodirati',
    codePlaceholder: 'Vaš osobni pristupni kod',
    company: 'sustav',
    password: 'lozinka',
    FORGOT_PASSWORD: 'Zaboravio sam lozinku',
    username: 'e-pošta'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'iskaznica',
    TEXT_1: 'Kako bismo vas mogli jasno identificirati, poslat ćemo vam jednokratni kod putem SMS-a ili e-pošte.',
    TEXT_2: 'Molimo unesite svoj broj mobitela ili e-mail adresu.'
  },
  update: {
    update: 'Ažurirati',
    latestVersion: 'Dostupna je nova verzija. Ažurirajte svoju aplikaciju kako biste iskoristili sve pogodnosti.',
    warnVersion: 'Koristite zastarjelu verziju ove aplikacije. Ažurirajte što je prije moguće.',
    blockVersion: 'Koristite zastarjelu verziju ove aplikacije koja više nije podržana. Izvršite ažuriranje sada.'
  },
  inProgress: 'U nastajanju',
  video_offline: 'Videozapisi se ne mogu gledati u izvanmrežnom načinu rada, spojite se na internet.',
  location_offline: 'Objekt nije spremljen izvanmrežno, spojite se na internet kako biste preuzeli QR kod.',
  invalidObjectCode: 'Nema valjanog objektnog koda.',
  invalidValue: 'Nevažeća vrijednost',
  ERROR: {
    ERROR_400: 'Došlo je do pogreške. Molimo pokušajte ponovo kasnije. (Kôd pogreške: 400)',
    ERROR_403: 'Nemate dozvolu za izvođenje ove radnje',
    REFRESH_TOKEN_403: 'Vaši pristupni podaci više nisu valjani. Molimo prijavite se ponovno!',
    ERROR_500: 'Došlo je do pogreške. Molimo pokušajte ponovo kasnije. (Kôd pogreške: 500)'
  },
  list: {
    entityPluralDefault: 'Rezultati',
    emptyState: '{{entityPlural}} ne postoji.',
    noResults: 'Nije moguće pronaći {{entityPlural}}.',
    selectAll: 'Odaberite sve {{entityPlural}}',
    massAction: 'Promocija za {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Odaberite sve {{count}}',
    massActionDeSelectAllGlobal: 'Poništi odabir svih {{count}}'
  },
  languages: {
    ar: 'arapski',
    de: 'njemački',
    es: 'španjolski',
    fr: 'francuski',
    hr: 'Hrvatski',
    it: 'talijanski',
    pt: 'Portugalski',
    ru: 'ruski',
    bg: 'bugarski',
    en: 'Engleski',
    fa: 'perzijski',
    el: 'grčki',
    hu: 'mađarski',
    pl: 'Polirati',
    ro: 'rumunjski',
    tr: 'turski',
    uk: 'ukrajinski',
    cs: 'češki',
    sk: 'slovački'
  },
  now: 'Sada',
  allCompanies: 'Svi klijenti',
  createDate: 'Datum stvaranja',
  company: 'klijent',
  new: 'Novi',
  of: 'iz',
  request: {
    deleteTitle: 'Izbriši {{item}}',
    confirmDelete: "Želite li stvarno izbrisati unos '{{itemName}}'?",
    itemAdded: '{{item}} je stvoren.',
    itemAddedError: '{{item}} nije bilo moguće stvoriti.',
    itemSaved: '{{item}} je spremljeno.',
    itemSavedError: '{{item}} nije bilo moguće spremiti.',
    itemRemoved: '{{item}} je izbrisano.',
    itemRemovedError: '{{item}} nije moguće izbrisati.'
  },
  news: { pageTitle: 'Vijesti', edit: 'Uređivanje vijesti' },
  profiles: {
    loginProfiles: 'Profili za prijavu',
    add: 'Profil za prijavu',
    logout: 'Ako se odjavite, svi lokalni podaci i profili bit će izbrisani! Želite li odjaviti pretplatu?',
    login: 'Registar',
    deleteConfirmation: 'Želite li stvarno izbrisati profil?',
    changed: 'Profil je uspješno promijenjen',
    deleted: 'Profil je uspješno izbrisan'
  },
  deleteAttachment: {
    confirmTitle: 'Izbriši privitak',
    confirmText: 'Jeste li sigurni da želite izbrisati ovaj privitak?'
  },
  comments: {
    addComment: 'Dodaj komentar',
    addCommentInfo: 'Komentar je vidljiv svima koji mogu vidjeti ovu kartu.',
    comment: 'komentar',
    deleteComment: 'Obriši komentar',
    deleteCommentConfirmation: 'Želite li stvarno izbrisati komentar?'
  },
  confirm: 'Potvrdi',
  firstName: 'Ime',
  lastName: 'Prezime',
  status: 'status',
  attachment: 'Prilog'
};
