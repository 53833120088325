export default {
  blinkSlogan: 'چشمک زدن - برنامه برای پاک کننده های ساختمان',
  all: 'همه',
  hello: 'سلام',
  description: 'شرح',
  ok: 'خوب',
  cancel: 'لغو کنید',
  reset: 'تنظیم مجدد به حالت پیش فرض',
  edit: 'ویرایش کنید',
  finish: 'کامل',
  notice: 'اطلاع',
  content: 'محتوی',
  back: 'بازگشت',
  close: 'بستن',
  backToHome: 'بازگشت به صفحه اصلی',
  proceed: 'به علاوه',
  done: 'آماده',
  delete: 'حذف',
  successful: 'موفقیت آمیز',
  error: 'اشتباه',
  ERROR_OCCURRED: 'خطایی رخ داده است یا داده ها نادرست است. لطفا دوباره تلاش کنید.',
  internetRequired: 'اینترنت مورد نیاز است',
  configuration: 'پیکربندی',
  logo: 'لوگو',
  uploadLogo: 'لوگو بارگذاری کنید',
  deleteLogo: 'حذف لوگو',
  duplicate: 'تکراری',
  importExport: 'واردات / صادرات',
  import: 'وارد كردن',
  doImport: 'وارد كردن',
  export: 'صادرات',
  doExport: 'صادرات',
  excelExport: 'صادرات اکسل',
  excelExportDownloaded: 'صادرات اکسل دانلود شد',
  excelExportError: 'صادرات Excel انجام نشد',
  pdfDownload: 'PDF را دانلود کنید',
  pdfDownloaded: 'PDF دانلود شد',
  configSaved: 'پیکربندی ذخیره شده است.',
  send: 'ارسال',
  connecting: 'اتصال...',
  or: 'یا',
  select: 'انتخاب کنید',
  today: 'امروز',
  camera: 'دوربین',
  gallery: 'آلبوم عکس',
  files: 'فایل ها',
  upload: 'آپلود فایل ها',
  chooseFiles: 'فایل ها را انتخاب کنید',
  filesTransferring: 'ارسال فایل ...',
  filesTransferringWait: 'لطفا تا زمانی که فایل ها ارسال می شوند صبر کنید.',
  filesTransferred: 'فایل ها با موفقیت ارسال شدند.',
  download: 'دانلود',
  showInactive: 'نمایش غیر فعال',
  copyLink: 'لینک را کپی کنید',
  linkCopied: 'پیوند در کلیپ بورد کپی شده است.',
  permissions: 'مجوزها',
  email: 'پست الکترونیک',
  emailConfig: 'پیکربندی ایمیل',
  title: 'عنوان',
  user: 'کاربر',
  logout: 'خروج',
  system: 'سیستم',
  appVersion: 'نسخه',
  apiVersionCore: 'نسخه\n(Core API)',
  apiVersionCheck: 'نسخه\n(بررسی API)',
  deviceInfo: 'اطلاعات دستگاه',
  installBlinkTime: 'زمان چشمک زدن را نصب کنید',
  installBlinkMe: 'Blink Me را نصب کنید',
  installing: 'در حال نصب نسخه {{version}}...',
  companyRequired: 'مشتری را انتخاب کنید',
  companyRequiredInfo: 'برای ادامه به عنوان sysadmin باید یک کلاینت انتخاب کنید.',
  pleaseChoose: 'لطفا انتخاب کنید',
  pleaseFill: 'لطفا پر کنید',
  noEntitiesFound: 'هیچ موردی یافت نشد.',
  addCustom: 'ایجاد مورد:',
  typeToSearchText: 'لطفا دو یا چند کاراکتر وارد کنید...',
  loading: 'داده ها در حال بارگیری هستند...',
  offline: 'آفلاین',
  youAreOffline: 'شما آفلاین هستید!',
  by: 'از جانب',
  date: 'تاریخ',
  time: 'زمان',
  searchTitle: 'جستجو کنید',
  searchTerm: 'عبارت جستجو',
  searchLoginUser: 'جستجوی کارکنان',
  searchLoginUserPlaceholder: 'جستجو بر اساس نام یا نام',
  location: 'هدف - شی',
  searchLocation: 'شی جستجو',
  searchLocationPlaceholder: 'جستجو بر اساس نام شی، شماره یا برچسب',
  deactivate: 'از کار انداختن',
  active: 'فعال',
  inactive: 'غیر فعال',
  tag: 'روز',
  tags: 'برچسب ها',
  color: 'رنگ',
  icon: 'آیکون',
  language: 'زبان',
  general: 'بطور کلی',
  yes: 'آره',
  no: 'خیر',
  searchThrough: 'جستجو کردن',
  name: 'نام خانوادگی',
  create: 'ايجاد كردن',
  save: 'صرفه جویی',
  home: 'صفحه اصلی',
  profile: 'مشخصات',
  templates: 'قالب ها',
  noAssignment: 'تخصیص نیافته است',
  sort: {
    change: 'برای تغییر ترتیب',
    save: 'سفارش را ذخیره کنید',
    saved: 'سفارش ذخیره شده است.'
  },
  signature: {
    signature: 'امضا',
    pleaseSign: 'لطفا امضا کنید',
    clickHereToSign: 'برای امضا اینجا را کلیک کنید',
    addSignature: 'اضافه کردن امضا',
    save: 'به عهده گرفتن',
    delete: 'حذف'
  },
  formError: {
    min: 'مقدار باید بیشتر از {{value}} باشد.',
    min2: 'مقدار باید بزرگتر یا مساوی با {{value}} باشد.',
    max: 'مقدار باید کمتر یا مساوی با {{value}} باشد.',
    requiredBecause: 'مقدار لازم است زیرا {{name}} پر شده است.',
    isRequired: '{{name}} مورد نیاز است.',
    numberInUse: 'شماره پرسنل در حال حاضر در حال استفاده است.',
    maxlength: '{{name}} نمی‌تواند بیش از {{value}} نویسه داشته باشد.',
    email: 'لطفا یک آدرس ایمیل معتبر وارد کنید.',
    faultyBlinkId: 'خطای شناسه چشمک زدن'
  },
  scan: {
    choose: 'Blink ID جدید اختصاص دهید',
    text: 'کد QR روی کارت ثبت نام را اسکن کنید.',
    scan_now: 'اسکن کن',
    enter_manually: 'بصورت دستی وارد کنید'
  },
  removeImage: 'تصویر پاک کنید',
  tickets: { assignee: 'ویرایشگر', dueTo: 'معتبر تا' },
  ngSelect: {
    typeToSearch: 'لطفا 3 حرف یا بیشتر وارد کنید.',
    loading: 'در حال بارگیری داده ها...',
    notFound: 'هیچ {{title}} یافت نشد.',
    clearAll: 'تنظیم مجدد به حالت پیش فرض'
  },
  help: {
    pageTitle: 'کمک',
    text1: 'تیم پشتیبانی ما با کمال میل به هر سوالی که ممکن است داشته باشید پاسخ دهد:',
    phone: 'تلفن',
    mail: 'پست الکترونیک',
    information: 'اطلاعات',
    documentation: 'مستندات آنلاین',
    documentationText: 'در مستندات آنلاین ما، دستورالعمل ها و ویدئوهایی را برای همه بخش های مهم Blink پیدا خواهید کرد.',
    support: 'حمایت کردن'
  },
  REGISTRATION: {
    WELCOME: 'خوش آمدی!',
    chooseMethod: 'از کدام روش می خواهید برای ورود استفاده کنید؟',
    withBlinkId: 'کارت شناسایی BLINK',
    withCredentials: 'نام کاربری رمز عبور',
    REGISTER: 'برای ثبت نام',
    ALREADY_REGISTERED: 'من یک حساب Blink دارم.',
    INVALID_CARD: 'کارت معتبر نیست',
    noBlinkId: 'کد QR اسکن شده یک شناسه Blink معتبر نیست',
    scanBlinkId: 'اسکن Blink ID',
    ERROR: 'ثبت نام کامل نشد. لطفا آدرس ایمیل یا شماره موبایل دیگری را وارد کنید.',
    NO_INTERNET_EXPLORER: 'متأسفانه Blink از اینترنت اکسپلورر پشتیبانی نمی کند. لطفا از مایکروسافت اج، فایرفاکس یا کروم استفاده کنید. از درک شما متشکرم.',
    loginNow: 'همین الان ثبت نام کنید',
    help: 'برای ثبت نام کمک کنید',
    helpText: 'برنامه مدیریت چشمک به شما امکان می دهد زمان، کارکنان و پیکربندی سیستم را مدیریت کنید. مدیر چشمک فقط برای سرپرستان، مدیران شی، مدیریت و مدیریت است! برای ردیابی زمان خالص، از "برنامه زمان چشمک زدن" استفاده کنید!<br/><br/>دو روش برای ورود به سیستم وجود دارد:<br/><br/><b>ورود با کارت شناسایی Blink< / b><br/><br/>اگر کارت شناسایی Blink دارید، این روش را انتخاب کنید. هنگامی که برای اولین بار ثبت نام می کنید، آدرس ایمیل یا شماره تلفن همراه از شما خواسته می شود. ما به این نیاز داریم تا یک رمز عبور یکبار مصرف برای ثبت نام برای شما ارسال کنیم. نیازی نیست رمز یکبار مصرف را به خاطر بسپارید. اگر دوباره وارد سیستم شوید، یک رمز عبور جدید برای شما ارسال می کنیم.<br/><br/><b>با نام کاربری و رمز عبور وارد شوید</b><br/><br/>نام کاربری و رمز عبور شما فقط در صورتی به شما داده می شود که از مدیر سیستم خود بخواهید. این روش فقط برای کاربرانی که حقوق اداری دارند در دسترس است.<br/><br/><b>پشتیبانی</b><br/><br/>اگر مشکلی در ورود به سیستم دارید، ایمیل پشتیبانی @blink-time.de یا با شماره 0911 240 330 تماس بگیرید.',
    userOffline: 'لطفاً برای استفاده از Blink me یک اتصال اینترنتی برقرار کنید.',
    login: 'همین الان ثبت نام کنید',
    codeError: 'لطفا ورودی خود را بررسی کنید و دوباره امتحان کنید.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'تبریک می گویم!',
    YOU_DID_IT: 'توانجامش دادی.',
    SIGNED_IN: 'ثبت شده است',
    SIGNED_IN_TEXT: 'شما با موفقیت ثبت نام کرده اید و اکنون می توانید از برنامه استفاده کنید.',
    START_USAGE: 'اکنون می توانید از برنامه استفاده کنید.',
    START: 'شروع'
  },
  FORGOT_PASSWORD: {
    emailSent: 'ایمیلی برای شما ارسال شده است که با آن می توانید اطلاعات دسترسی خود را تغییر دهید.',
    FORGOT_PASSWORD: 'رمز عبور خود را فراموش کرده اید؟',
    errors: {
      notAllowed: 'هیچ رمز عبوری برای این کاربر قابل بازنشانی نیست.',
      invalidToken: 'کد بازنشانی رمز عبور شما منقضی شده است. لطفا یک کد جدید درخواست کنید.'
    },
    reset: {
      backToRoot: 'بازگشت به صفحه ورود',
      renewCode: 'درخواست کد جدید',
      resetPassword: 'بازنشانی رمز عبور',
      success: 'رمز عبور شما با موفقیت بازنشانی شد.',
      password1: 'رمز عبور جدید شما',
      password2: 'تکرار رمز عبور',
      validationError: 'رمز عبور با سیاست رمز عبور ما مطابقت ندارد!',
      passwordsNotEqual: 'پسوردها یکسان نیستند! لطفا ورودی خود را بررسی کنید.',
      passwordValidation: 'رمز عبور باید حداقل 6 کاراکتر باشد و دارای یک حرف، یک عدد و یک کاراکتر خاص باشد.'
    },
    FORGOT_PASSWORD_TEXT: 'لطفا آدرس ایمیل و سیستم مورد نظر خود را برای بازنشانی رمز عبور وارد کنید.',
    FORGOT_PASSWORD_PLACEHOLDER: 'پست الکترونیک'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'داده ها را بررسی کنید',
    TEXT: 'اگر نیاز به بازنشانی رمز عبور شما داریم، به آدرس ایمیل و در صورت تمایل شماره موبایل شما نیاز داریم.',
    EMAIL: 'آدرس ایمیل',
    MOBILE: 'شماره موبایل'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'کد را وارد کن',
    EMAIL_TEXT: 'کدی را که به آدرس ایمیل زیر فرستادیم وارد کنید:',
    SMS_TEXT: 'کدی را که به شماره موبایل زیر فرستادیم وارد کنید:',
    NO_CODE: 'من کدی دریافت نکرده ام',
    CODE_LENGTH_ERROR: 'کد وارد شده بیشتر از شش کاراکتر است. لطفا کد خود را دوباره وارد کنید.',
    CODE_SENT: 'کد ارسال شده است.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'بدون کد',
    TEXT1: 'کد دریافت نکردید؟',
    RESEND_CODE: 'کد را دوباره بفرستید',
    TEXT2: 'اگر آدرس ایمیل یا شماره موبایل ذخیره شده در سیستم تغییر کرده است، لطفاً با سرپرست خود تماس بگیرید.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'لطفا شناسه و کد Blink خود را وارد کنید. تمام اطلاعات کارت ثبت نام خود را خواهید یافت.',
    textCredentials: 'مدیر سیستم شما باید یک نام کاربری و رمز عبور به شما ارائه دهد.',
    BLINK_ID_INVALID: 'خطای شناسه چشمک زدن',
    loginCardNotFound: 'شناسه چشمک یا قبلاً اختصاص داده شده است یا وجود ندارد',
    WRONG_CREDENTIALS: 'داده های وارد شده صحیح نیست. لطفا ورودی خود را بررسی کنید.',
    loginViaBlinkId: 'با BlinkID وارد شوید',
    loginViaCredentials: 'ثبت نام با داده های دسترسی',
    CODE: 'کد',
    codePlaceholder: 'کد دسترسی شخصی شما',
    company: 'سیستم',
    password: 'کلمه عبور',
    FORGOT_PASSWORD: 'من گذرواژه ام را فراموش کرده ام',
    username: 'پست الکترونیک'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'شناسه',
    TEXT_1: 'برای اینکه بتوانیم شما را به وضوح شناسایی کنیم، یک کد یکبار مصرف از طریق پیامک یا ایمیل برای شما ارسال می کنیم.',
    TEXT_2: 'لطفا شماره موبایل یا آدرس ایمیل خود را وارد کنید.'
  },
  update: {
    update: 'برای به روز رسانی',
    latestVersion: 'نسخه جدید موجود است. لطفاً برنامه خود را به روز کنید تا از همه مزایا استفاده کنید.',
    warnVersion: 'شما از نسخه قدیمی این برنامه استفاده می کنید. لطفا در اسرع وقت به روز رسانی کنید.',
    blockVersion: 'شما از نسخه قدیمی این برنامه استفاده می کنید که دیگر پشتیبانی نمی شود. لطفا همین الان یک به روز رسانی انجام دهید.'
  },
  inProgress: 'در حال پیش رفت',
  video_offline: 'ویدیوها در حالت آفلاین قابل مشاهده نیستند، لطفاً به اینترنت وصل شوید.',
  location_offline: 'شی به صورت آفلاین ذخیره نمی شود، لطفاً برای بازیابی کد QR به اینترنت متصل شوید.',
  invalidObjectCode: 'هیچ کد شی معتبری وجود ندارد.',
  invalidValue: 'مقدار نامعتبر است',
  ERROR: {
    ERROR_400: 'خطایی رخ داده است. لطفاً بعداً دوباره امتحان کنید. (کد خطا: 400)',
    ERROR_403: 'شما اجازه انجام این عمل را ندارید',
    REFRESH_TOKEN_403: 'اطلاعات دسترسی شما دیگر معتبر نیست. لطفا دوباره وارد شوید!',
    ERROR_500: 'خطایی رخ داده است. لطفاً بعداً دوباره امتحان کنید. (کد خطا: 500)'
  },
  list: {
    entityPluralDefault: 'نتایج',
    emptyState: 'هیچ {{entityPlural}} وجود ندارد.',
    noResults: 'هیچ {{entityPlural}} یافت نشد.',
    selectAll: 'انتخاب همه {{entityPlural}}',
    massAction: 'تبلیغ برای {{count}} {{entityPlural}}',
    massActionAllGlobal: 'انتخاب همه {{count}}',
    massActionDeSelectAllGlobal: 'لغو انتخاب همه {{count}}'
  },
  languages: {
    ar: 'عربی',
    de: 'آلمانی',
    es: 'اسپانیایی',
    fr: 'فرانسوی',
    hr: 'کروات',
    it: 'ایتالیایی',
    pt: 'پرتغالی',
    ru: 'روسی',
    bg: 'بلغاری',
    en: 'انگلیسی',
    fa: 'فارسی',
    el: 'یونانی',
    hu: 'مجارستانی',
    pl: 'لهستانی',
    ro: 'رومانیایی',
    tr: 'ترکی',
    uk: 'اوکراینی',
    cs: 'کشور چک',
    sk: 'اسلواکی'
  },
  now: 'اکنون',
  allCompanies: 'همه مشتریان',
  createDate: 'تاریخ ایجاد',
  company: 'مشتری',
  new: 'جدید',
  of: 'از جانب',
  request: {
    deleteTitle: 'حذف {{item}}',
    confirmDelete: 'آیا واقعاً می خواهید ورودی "{{itemName}}" را حذف کنید؟',
    itemAdded: '{{item}} ایجاد شده است.',
    itemAddedError: '{{item}} ایجاد نشد.',
    itemSaved: '{{item}} ذخیره شده است.',
    itemSavedError: '{{item}} ذخیره نشد.',
    itemRemoved: '{{item}} حذف شده است.',
    itemRemovedError: '{{item}} حذف نشد.'
  },
  news: { pageTitle: 'اخبار', edit: 'ویرایش گزارش های خبری' },
  profiles: {
    loginProfiles: 'پروفایل های ورود',
    add: 'پروفایل ورود',
    logout: 'اگر از سیستم خارج شوید، تمام داده ها و پروفایل های محلی حذف خواهند شد! آیا می خواهید اشتراک را لغو کنید؟',
    login: 'ثبت نام',
    deleteConfirmation: 'آیا واقعاً می خواهید پروفایل را حذف کنید؟',
    changed: 'نمایه با موفقیت تغییر کرد',
    deleted: 'نمایه با موفقیت حذف شد'
  },
  deleteAttachment: {
    confirmTitle: 'حذف پیوست',
    confirmText: 'آیا مطمئن هستید که می خواهید این پیوست را حذف کنید؟'
  },
  comments: {
    addComment: 'اضافه کردن نظر',
    addCommentInfo: 'نظر برای هر کسی که می تواند این بلیط را مشاهده کند قابل مشاهده است.',
    comment: 'نظر',
    deleteComment: 'حذف نظر',
    deleteCommentConfirmation: 'آیا واقعاً می خواهید نظر را حذف کنید؟'
  },
  confirm: 'تایید کنید',
  firstName: 'نام کوچک',
  lastName: 'نام خانوادگی',
  status: 'وضعیت',
  attachment: 'پیوست'
};
