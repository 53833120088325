<ion-button (click)="buttonClicked.next(true)"
            [disabled]="disabled"
            [expand]="expand"
            [fill]="fill"
            [size]="size"
            [color]="color"
            [class.text-left]="textLeft"
            [class.text-right]="textRight">

  <ui-icon [solidIcon]="iconSolid"
           [size]="iconSize"
           [slot]="iconSlot"
           *ngIf="icon && label && !iconOnly"
           [icon]="icon" [class.mr-5]="iconSlot === 'start'"
           [class.ml-5]="iconSlot === 'end'">
  </ui-icon>
  <ui-icon slot="icon-only"
           [solidIcon]="iconSolid"
           [size]="iconSize"
           *ngIf="icon && iconOnly"
           [icon]="icon">
  </ui-icon>

  <span slot="start" *ngIf="label && !iconOnly">
    {{ label | translate | firstUppercase }}
  </span>
</ion-button>
