export default {
  blinkSlogan: 'Blink – aplikácia na čistenie budov',
  all: 'Všetky',
  hello: 'Ahoj',
  description: 'Popis',
  ok: 'OK',
  cancel: 'Zrušiť',
  reset: 'Obnoviť pôvodné nastavenia',
  edit: 'Upraviť',
  finish: 'Dokončiť',
  notice: 'Všimnite si',
  content: 'Obsah',
  back: 'späť',
  close: 'Zavrieť',
  backToHome: 'Späť na domovskú stránku',
  proceed: 'Ďalej',
  done: 'Pripravený',
  delete: 'Odstrániť',
  successful: 'Úspešný',
  error: 'Omyl',
  ERROR_OCCURRED: 'Vyskytla sa chyba alebo sú údaje nesprávne. Prosím skúste znova.',
  internetRequired: 'Vyžaduje sa internet',
  configuration: 'konfigurácia',
  logo: 'logo',
  uploadLogo: 'Nahrajte logo',
  deleteLogo: 'Odstrániť logo',
  duplicate: 'Duplicitné',
  importExport: 'Import/Export',
  import: 'importovať',
  doImport: 'Importovať',
  export: 'export',
  doExport: 'Export',
  excelExport: 'Excel export',
  excelExportDownloaded: 'Export do Excelu bol stiahnutý',
  excelExportError: 'Export do Excelu zlyhal',
  pdfDownload: 'Stiahnite si PDF',
  pdfDownloaded: 'PDF stiahnuté',
  configSaved: 'Konfigurácia bola uložená.',
  send: 'Odoslať',
  connecting: 'Pripojiť...',
  or: 'alebo',
  select: 'Vyberte si',
  today: 'Dnes',
  invalidValue: 'Nesprávna hodnota',
  camera: 'fotoaparát',
  gallery: 'galéria',
  files: 'súbory',
  upload: 'Nahrať súbory',
  chooseFiles: 'vyberte súbory',
  filesTransferring: 'Odoslať súbory...',
  filesTransferringWait: 'Počkajte, kým sa súbory prenesú.',
  filesTransferred: 'Súbory boli úspešne prenesené.',
  download: 'Stiahnuť ▼',
  showInactive: 'Zobraziť neaktívne',
  copyLink: 'Skopírovať odkaz',
  linkCopied: 'Odkaz bol skopírovaný do schránky.',
  permissions: 'Povolenia',
  email: 'e-mail',
  emailConfig: 'Konfigurácia e-mailu',
  title: 'titul',
  user: 'užívateľ',
  logout: 'Odhlásiť sa',
  system: 'systém',
  appVersion: 'Verzia (aplikácia)',
  apiVersionCore: 'verzia\n(Core API)',
  apiVersionCheck: 'verzia\n(Skontrolujte API)',
  deviceInfo: 'Informácie o zariadení',
  installBlinkTime: 'Nainštalujte čas blikania',
  installBlinkMe: 'Nainštalujte Blink Me',
  installing: 'Inštaluje sa verzia {{version}}...',
  companyRequired: 'Vyberte klienta',
  companyRequiredInfo: 'Ak chcete pokračovať ako správca systému, musíte vybrať klienta.',
  pleaseChoose: 'Prosím vyber si',
  pleaseFill: 'Vyplňte prosím',
  noEntitiesFound: 'Nenašli sa žiadne položky.',
  addCustom: 'Vytvorenie prvku:',
  typeToSearchText: 'Zadajte dva alebo viac znakov...',
  loading: 'Dáta sa načítavajú...',
  offline: 'Offline',
  youAreOffline: 'Ste offline!',
  by: 'od',
  date: 'Dátum',
  time: 'čas',
  searchTitle: 'Hľadaj',
  searchTerm: 'hľadaný výraz',
  searchLoginUser: 'Hľadáme zamestnancov',
  searchLoginUserPlaceholder: 'Hľadajte podľa priezviska alebo mena',
  location: 'objekt',
  searchLocation: 'Hľadaný objekt',
  searchLocationPlaceholder: 'Vyhľadávajte podľa názvu objektu, čísla alebo značiek',
  deactivate: 'Deaktivovať',
  active: 'Aktívne',
  inactive: 'Neaktívne',
  tag: 'deň',
  tags: 'značky',
  color: 'Farba',
  icon: 'Ikona',
  language: 'Jazyk',
  general: 'Vo všeobecnosti',
  yes: 'Áno',
  no: 'Nie',
  searchThrough: 'Vyhľadávanie',
  name: 'Priezvisko',
  create: 'Vytvorte',
  save: 'Uložiť',
  home: 'Domov',
  profile: 'profilu',
  templates: 'šablóny',
  noAssignment: 'nepridelené',
  sort: {
    change: 'na zmenu poradia',
    save: 'Uložiť objednávku',
    saved: 'Objednávka bola uložená.'
  },
  inProgress: 'Prebieha',
  video_offline: 'Videá nie je možné prezerať v režime offline, pripojte sa k internetu.',
  location_offline: 'Objekt nie je uložený offline, pripojte sa k internetu a získajte QR kód.',
  invalidObjectCode: 'Žiadny platný kód objektu.',
  signature: {
    signature: 'Podpis',
    pleaseSign: 'Prosím podpíšte',
    clickHereToSign: 'Kliknite sem a podpíšte sa',
    addSignature: 'Pridajte podpis',
    save: 'Prebrať',
    delete: 'Odstrániť'
  },
  formError: {
    min: 'Hodnota musí byť väčšia ako {{value}}.',
    min2: 'Hodnota musí byť väčšia alebo rovná {{value}}.',
    max: 'Hodnota musí byť menšia alebo rovná {{value}}.',
    requiredBecause: 'Hodnota je povinná, pretože {{name}} je vyplnené.',
    isRequired: '{{name}} je povinné.',
    numberInUse: 'Osobné číslo sa už používa.',
    maxlength: '{{name}} nemôže obsahovať viac ako {{value}} znakov.',
    email: 'Prosím zadajte platnú emailovú adresu.',
    faultyBlinkId: 'Nesprávne ID blikania'
  },
  scan: {
    choose: 'Priraďte nové Blink ID',
    text: 'Naskenujte QR kód na registračnej karte.',
    scan_now: 'Skenovanie teraz',
    enter_manually: 'Zadajte manuálne'
  },
  removeImage: 'Jasný obraz',
  tickets: { assignee: 'editor', dueTo: 'Platný do' },
  ngSelect: {
    typeToSearch: 'Zadajte 3 alebo viac písmen.',
    loading: 'načítavam dáta...',
    notFound: 'Nenašli sa žiadne {{title}}.',
    clearAll: 'Obnoviť pôvodné nastavenia'
  },
  help: {
    pageTitle: 'Pomoc',
    text1: 'Náš tím podpory vám rád pomôže, ak máte nejaké otázky:',
    phone: 'telefón',
    mail: 'e-mail',
    information: 'Info',
    documentation: 'Online dokumentácia',
    documentationText: 'V našej online dokumentácii nájdete pokyny a videá pre všetky dôležité oblasti Blink.',
    support: 'podpora'
  },
  REGISTRATION: {
    WELCOME: 'Vitajte!',
    chooseMethod: 'Ktorý spôsob by ste chceli použiť na prihlásenie?',
    withBlinkId: 'Blink ID karta',
    withCredentials: 'Používateľské meno Heslo',
    REGISTER: 'zaregistrovať sa',
    ALREADY_REGISTERED: 'Mám účet Blink.',
    INVALID_CARD: 'Karta je neplatná.',
    noBlinkId: 'Naskenovaný QR kód nie je platným Blink ID',
    scanBlinkId: 'Skenovať Blink ID',
    ERROR: 'Registráciu nebolo možné dokončiť. Zadajte inú e-mailovú adresu alebo mobilné číslo.',
    NO_INTERNET_EXPLORER: 'Žiaľ, program Blink nepodporuje Internet Explorer. Použite Microsoft Edge, Firefox alebo Chrome. Ďakujem za pochopenie.',
    loginNow: 'Zaregistrujte sa teraz',
    help: 'Pomoc s registráciou',
    helpText: 'Na prihlásenie môžete použiť dva spôsoby:<br/><br/><b>Prihlásenie pomocou karty Blink ID Card</b><br/><br/>Ak máte kartu Blink ID, vyberte túto možnosť metóda. Pri prvej registrácii budete požiadaní o zadanie e-mailovej adresy alebo čísla mobilného telefónu. Potrebujeme to, aby sme vám poslali jednorazové heslo na registráciu. Nemusíte si pamätať jednorazové heslo. Ak sa znova prihlásite, pošleme vám nové jednorazové heslo.<br/><br/><b>Prihláste sa pomocou používateľského mena a hesla</b><br/><br/>Dostanete iba svoje používateľské meno a heslo na vyžiadanie od správcu systému. Táto metóda je dostupná iba pre používateľov s oprávneniami správcu.<br/><br/><b>Podpora</b><br/><br/>Ak máte problémy s prihlásením, napíšte e-mail na podporu @blink- time.de alebo volajte 0911 240 330 0.',
    userOffline: 'Ak chcete používať Blink me, vytvorte si internetové pripojenie.',
    login: 'Zaregistrujte sa teraz',
    codeError: 'Skontrolujte svoj vstup a skúste to znova.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'Gratulujem!',
    YOU_DID_IT: 'Urobil si to.',
    SIGNED_IN: 'Registrovaný',
    SIGNED_IN_TEXT: 'Úspešne ste sa prihlásili a teraz môžete aplikáciu používať.',
    START_USAGE: 'Teraz môžete aplikáciu používať.',
    START: 'začať'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Bol vám zaslaný e-mail, pomocou ktorého si môžete zmeniť svoje prihlasovacie údaje.',
    FORGOT_PASSWORD: 'zabudol si heslo?',
    FORGOT_PASSWORD_TEXT: 'Zadajte svoju e-mailovú adresu a cieľový systém na obnovenie hesla.',
    FORGOT_PASSWORD_PLACEHOLDER: 'e-mail',
    errors: {
      notAllowed: 'Pre tohto používateľa nie je možné obnoviť heslo.',
      invalidToken: 'Platnosť kódu na obnovenie hesla vypršala. Požiadajte o nový kód.'
    },
    reset: {
      backToRoot: 'Späť na prihlasovaciu stránku',
      renewCode: 'Požiadajte o nový kód',
      resetPassword: 'obnoviť heslo',
      success: 'Vaše heslo bolo úspešne obnovené.',
      password1: 'vaše nové heslo',
      password2: 'zopakujte heslo',
      validationError: 'Heslo nie je v súlade s našimi pokynmi pre heslá!',
      passwordsNotEqual: 'Heslá nie sú totožné! Prosím skontrolujte svoj vstup.',
      passwordValidation: 'Heslo musí mať aspoň 6 znakov a musí obsahovať písmeno, číslo a špeciálny znak.'
    }
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'Skontrolujte údaje',
    TEXT: 'Ak potrebujeme obnoviť vaše heslo, budeme potrebovať vašu e-mailovú adresu a voliteľne aj vaše mobilné číslo.',
    EMAIL: 'Emailová adresa',
    MOBILE: 'Číslo mobilného telefónu'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Zadajte kód',
    EMAIL_TEXT: 'Zadajte kód, ktorý sme poslali na nasledujúcu e-mailovú adresu:',
    SMS_TEXT: 'Zadajte kód, ktorý sme poslali na nasledujúce mobilné číslo:',
    NO_CODE: 'Nedostal som kód.',
    CODE_LENGTH_ERROR: 'Zadaný kód je dlhší ako šesť znakov. Zadajte kód znova.',
    CODE_SENT: 'Kód bol odoslaný.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Žiadny kód',
    TEXT1: 'Nedostali ste kód?',
    RESEND_CODE: 'Pošlite kód znova',
    TEXT2: 'Ak sa zmenila vaša e-mailová adresa alebo mobilné číslo uložené v systéme, kontaktujte svojho správcu.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Zadajte svoje Blink ID a kód. Všetky informácie nájdete na registračnej karte.',
    textCredentials: 'Používateľské meno a heslo vám musí poskytnúť správca systému.',
    BLINK_ID_INVALID: 'Nesprávne ID blikania',
    loginCardNotFound: 'Blink ID je buď už obsadené, alebo neexistuje',
    WRONG_CREDENTIALS: 'Zadané údaje sú nesprávne. Prosím skontrolujte svoj vstup.',
    loginViaBlinkId: 'Prihláste sa pomocou BlinkID',
    loginViaCredentials: 'Registrácia s prístupovými údajmi',
    CODE: 'kód',
    codePlaceholder: 'Váš osobný prístupový kód',
    company: 'systém',
    password: 'heslo',
    FORGOT_PASSWORD: 'Zabudol som heslo',
    username: 'e-mail'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'ID',
    TEXT_1: 'Aby sme vás mohli jednoznačne identifikovať, pošleme vám jednorazový kód prostredníctvom SMS alebo emailu.',
    TEXT_2: 'Zadajte svoje mobilné číslo alebo e-mailovú adresu.'
  },
  update: {
    update: 'Ak chcete aktualizovať',
    latestVersion: 'K dispozícii je nová verzia. Aktualizujte si aplikáciu, aby ste ju naplno využili.',
    warnVersion: 'Používate zastaranú verziu tejto aplikácie. Aktualizujte ju čo najskôr.',
    blockVersion: 'Používate zastaranú verziu tejto aplikácie, ktorá už nie je podporovaná. Vykonajte aktualizáciu teraz.'
  },
  ERROR: {
    ERROR_400: 'Došlo k chybe. Skúste neskôr prosím. (Kód chyby: 400)',
    ERROR_403: 'Na vykonanie tejto akcie nemáte povolenie',
    REFRESH_TOKEN_403: 'Vaše prístupové údaje už nie sú platné. Prihláste sa znova!',
    ERROR_500: 'Došlo k chybe. Skúste neskôr prosím. (Kód chyby: 500)'
  },
  list: {
    entityPluralDefault: 'Výsledky',
    emptyState: 'Žiadne {{entityPlural}} neexistuje.',
    noResults: 'Nenašlo sa žiadne {{entityPlural}}.',
    selectAll: 'Vybrať všetky {{entityPlural}}',
    massAction: 'Propagácia pre {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Vybrať všetky {{count}}',
    massActionDeSelectAllGlobal: 'Zrušiť výber všetkých {{count}}'
  },
  languages: {
    ar: 'arabčina',
    de: 'nemecký',
    es: 'španielčina',
    fr: 'francúzsky',
    hr: 'chorvátsky',
    it: 'taliansky',
    pt: 'portugalčina',
    ru: 'ruský',
    bg: 'bulharčina',
    en: 'Angličtina',
    fa: 'perzský',
    el: 'grécky',
    hu: 'maďarský',
    pl: 'poľský',
    ro: 'rumunský',
    tr: 'turecký',
    uk: 'Ukrajinčina',
    cs: 'český',
    sk: 'slovenský'
  },
  now: 'Teraz',
  allCompanies: 'Všetci klienti',
  createDate: 'Dátum vytvorenia',
  company: 'zákazník',
  new: 'Nový',
  of: 'od',
  request: {
    deleteTitle: 'Odstrániť {{item}}',
    confirmDelete: "Naozaj chcete vymazať záznam '{{itemName}}'?",
    itemAdded: '{{item}} bol vytvorený.',
    itemAddedError: '{{item}} sa nepodarilo vytvoriť.',
    itemSaved: '{{item}} bol uložený.',
    itemSavedError: '{{item}} sa nepodarilo uložiť.',
    itemRemoved: '{{item}} bol odstránený.',
    itemRemovedError: '{{item}} sa nepodarilo odstrániť.'
  },
  news: { pageTitle: 'Správy', edit: 'Upravte správy' },
  profiles: {
    loginProfiles: 'Prihlasovacie profily',
    add: 'Prihlasovací profil',
    logout: 'Ak sa odhlásite, všetky lokálne údaje a profily budú vymazané! Chcete zrušiť odber?',
    login: 'Registrovať',
    deleteConfirmation: 'Naozaj chcete odstrániť profil?',
    changed: 'Profil sa úspešne zmenil',
    deleted: 'Profil bol úspešne odstránený'
  },
  deleteAttachment: {
    confirmTitle: 'Odstrániť prílohu',
    confirmText: 'Naozaj chcete odstrániť túto prílohu?'
  },
  comments: {
    addComment: 'pridať komentár',
    addCommentInfo: 'Komentár je viditeľný pre každého, kto môže zobraziť tento tiket.',
    comment: 'komentovať',
    deleteComment: 'Odstrániť komentár',
    deleteCommentConfirmation: 'Naozaj chcete odstrániť komentár?'
  },
  confirm: 'Potvrďte',
  firstName: 'Krstné meno',
  lastName: 'Priezvisko',
  status: 'stav',
  attachment: 'Príloha'
};
