import { Injectable } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import {
  getEntitiesIds,
  getEntity,
  selectActiveEntity,
  selectAllEntities,
  selectEntitiesCount,
  setActiveId, setEntities,
  upsertEntities,
  withActiveId,
  withEntities
} from '@ngneat/elf-entities';
import { BlinkApps, BlinkCompany, CompanyFeatureInternalId } from '@blink/shared-blink-types';
import { PersistStateService } from '../../shared';
import { combineLatestWith, Observable, ReplaySubject, shareReplay } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ActivationEnd, Router } from '@angular/router';
import { UiInputSelectList } from '@blink/ui';
import { GlobalTranslateService, logService } from '@blink/util';

interface CompanyRepositoryProps {
  migration: {
    activeId: number;
    migrated: boolean;
    ready: boolean;
  }
}

const store = createStore(
  { name: 'core_companies' },
  withEntities<BlinkCompany, 'Id'>({ idKey: 'Id' }),
  withActiveId(),
  withProps<CompanyRepositoryProps>({
    migration: {
      activeId: null,
      migrated: false,
      ready: false
    }
  })
);

@Injectable({ providedIn: 'root' })
export class CompanyRepository {
  companies$ = store.pipe(selectAllEntities());
  selectedCompanyId$: Observable<number> = store.pipe(select(state => state.activeId),
    map(activeId => activeId === undefined ? null : activeId));
  selectedCompany$ = store.pipe(selectActiveEntity());
  companyFromRoot$: Observable<BlinkCompany> = this.router.events
    .pipe(
      combineLatestWith(this.companies$),
      filter(([e]) => (e instanceof ActivationEnd) && (Object.keys(e.snapshot.params).length > 0)),
      map(([e, companies]) => {
        const params = e instanceof ActivationEnd ? e.snapshot.params : {}
        if (params['companyId']) {
          return companies.find(c => c.Id === Number(params['companyId']))
        }
        return null;
      }),
      shareReplay(1),
      distinctUntilChanged()
    );
  companyIdFromRoute$: Observable<number> = this.router.events
    .pipe(
      filter(e => (e instanceof ActivationEnd) && (Object.keys(e.snapshot.params).length > 0)),
      map((e) => {
        const params = e instanceof ActivationEnd ? e.snapshot.params : {}
        if (params['companyId']) {
          return Number(params['companyId'])
        }
        return null;
      }),
      shareReplay(1),
      distinctUntilChanged()
    );
  companyFromRootHasBlinkActiveLicense$: Observable<boolean> = this.companyIdFromRoute$.pipe(
    combineLatestWith(this.companies$),
    map(([companyId, companies]) => {
      if (companyId && companies.length > 0) {
        return companies.find(c => c.Id === companyId).BlinkApps?.some(companyBlinkApps => companyBlinkApps.InternalId === BlinkApps.BlinkActive)
      }
      return false;
    }), distinctUntilChanged()
  );
  companyNameFromRoot$: Observable<string> = this.companyIdFromRoute$.pipe(
    combineLatestWith(this.companies$),
    map(([companyId, companies]) => {
      if (companyId && companies.length > 0) {
        return companies.find(c => c.Id === companyId).Name
      }
      return '';
    }),
    distinctUntilChanged()
  );
  selectedCompanyHasStickersFeature$ = this.selectedCompany$.pipe(
    map(company => company && company.Features?.some(features => features.InternalId === CompanyFeatureInternalId.Stickers))
  )
  count$ = store.pipe(selectEntitiesCount(), distinctUntilChanged());
  companyIds$ = store.pipe(selectAllEntities())
    .pipe(
      map(t => t.map(e => e.Id)),
      distinctUntilChanged()
    )
  private repositoryReadySubject = new ReplaySubject<boolean>(1);
  repositoryReady$ = this.repositoryReadySubject.asObservable();

  constructor(private router: Router,
              persistStateService: PersistStateService,
              private t: GlobalTranslateService) {
    persistStateService
      .persistState(store, false)
      .subscribe(() => this.repositoryReadySubject.next(true));
    logService(this);
  }

  setCompanies(companies: BlinkCompany[]) {
    store.update(setEntities(companies));

    this.setActiveIdForMigration();
  }

  upsertCompanies(companies: BlinkCompany[]) {
    store.update(upsertEntities(companies))

    this.setActiveIdForMigration();
  }

  private setActiveIdForMigration() {
    const migration = store.getValue().migration;
    if (migration.activeId && migration.ready && !migration.migrated) {
      store.update(setActiveId(migration.activeId));
      store.update(setProps(() => ({ migration: { ...migration, migrated: true } })));
    }
  }

  companySelectList$(withAllItem: boolean): Observable<UiInputSelectList> {
    return this.companies$.pipe(
      map(companies => {
        const selectList = companies.map(x => ({ id: x.Id, name: x.Name }))
        if (withAllItem) {
          selectList.unshift({ id: 'All' as any, name: this.t.allCompanies })
        }
        return selectList;
      })
    )
  }

  companyHasLicense$(companyId: number, blinkApp: BlinkApps) {
    return this.companies$.pipe(
      map(companies => companies.find(x => x.Id === companyId)),
      map(company => company.BlinkApps?.some(companyBlinkApps => companyBlinkApps.InternalId === blinkApp))
    )
  }

  setActiveCompany(companyId: number) {
    store.update(setActiveId(companyId));
  }

  getActiveCompanyId(): number {
    return store.getValue().activeId;
  }

  getActiveCompany() {
    return store.getValue().entities[store.getValue().activeId];
  }

  migrate(migration: { activeId: number; migrated: boolean; ready: boolean }) {
    store.update(setProps(() => ({
      migration
    })));
    this.setActiveCompany(migration.activeId);
  }

  getCompany(id: number) {
    return store.query(getEntity(id))
  }

  getCompanyIds() {
    return store.query(getEntitiesIds());
  }
}
