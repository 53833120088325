export default {
  blinkSlogan: 'Blink: la aplicación para la limpieza de edificios',
  all: 'Todo',
  hello: 'Hola',
  description: 'Descripción',
  ok: 'DE ACUERDO',
  cancel: 'Cancelar',
  reset: 'Restablecen a los predeterminados',
  edit: 'Editar',
  finish: 'Completo',
  notice: 'Aviso',
  content: 'Contenido',
  back: 'Atrás',
  close: 'Cerca',
  backToHome: 'Volver a la página de inicio',
  proceed: 'Más',
  done: 'Listo',
  delete: 'Borrar',
  successful: 'Exitoso',
  error: 'Error',
  ERROR_OCCURRED: 'Ha ocurrido un error o los datos son incorrectos. Inténtalo de nuevo.',
  internetRequired: 'Se requiere Internet',
  configuration: 'configuración',
  logo: 'logo',
  uploadLogo: 'Subir logotipo',
  deleteLogo: 'Eliminar logotipo',
  duplicate: 'Duplicar',
  importExport: 'Importación y exportación',
  import: 'importar',
  doImport: 'Importar',
  export: 'exportar',
  doExport: 'Exportar',
  excelExport: 'Exportación de Excel',
  excelExportDownloaded: 'Exportación de Excel descargada',
  excelExportError: 'La exportación de Excel falló',
  pdfDownload: 'Descargar PDF',
  pdfDownloaded: 'PDF descargado',
  configSaved: 'La configuración ha sido guardada.',
  send: 'Enviar',
  connecting: 'Conectar...',
  or: 'o',
  select: 'Elegir',
  today: 'Hoy',
  camera: 'cámara',
  gallery: 'galería',
  files: 'archivos',
  upload: 'Subir archivos',
  chooseFiles: 'Seleccionar archivos',
  filesTransferring: 'Enviar archivos...',
  filesTransferringWait: 'Espere mientras se transmiten los archivos.',
  filesTransferred: 'Archivos transferidos exitosamente.',
  download: 'Descargar',
  showInactive: 'Mostrar los inactivos',
  copyLink: 'Copiar link',
  linkCopied: 'El enlace ha sido copiado al portapapeles.',
  permissions: 'Permisos',
  email: 'correo electrónico',
  emailConfig: 'Configuración de correo electrónico',
  title: 'título',
  user: 'usuario',
  logout: 'Cerrar sesión',
  system: 'sistema',
  appVersion: 'Versión (aplicación)',
  apiVersionCore: 'versión\n(API principal)',
  apiVersionCheck: 'versión\n(Consulte la API)',
  deviceInfo: 'Información del dispositivo',
  installBlinkTime: 'Instalar tiempo de parpadeo',
  installBlinkMe: 'Instalar Parpadeame',
  installing: 'Instalando la versión {{version}}....',
  companyRequired: 'Seleccionar cliente',
  companyRequiredInfo: 'Debe seleccionar un cliente para continuar como administrador del sistema.',
  pleaseChoose: 'Por favor elige',
  pleaseFill: 'Por favor llenalo',
  noEntitiesFound: 'No se pudieron encontrar artículos.',
  addCustom: 'Crear elemento:',
  typeToSearchText: 'Por favor introduzca dos o más caracteres...',
  loading: 'Los datos se están cargando...',
  offline: 'Desconectado',
  youAreOffline: '¡Estas desconectado!',
  by: 'de',
  date: 'Fecha',
  time: 'tiempo',
  searchTitle: 'Buscar',
  searchTerm: 'término de búsqueda',
  searchLoginUser: 'buscando empleados',
  searchLoginUserPlaceholder: 'Buscar por apellido o nombre',
  location: 'objeto',
  searchLocation: 'Objeto de búsqueda',
  searchLocationPlaceholder: 'Buscar por nombre de objeto, número o etiquetas',
  deactivate: 'Desactivar',
  active: 'Activo',
  inactive: 'Inactivo',
  tag: 'Día',
  tags: 'etiquetas',
  color: 'Color',
  icon: 'Icono',
  language: 'Idioma',
  general: 'Generalmente',
  yes: 'Sí',
  no: 'No',
  searchThrough: 'Buscar',
  name: 'Apellido',
  create: 'Crear',
  save: 'Ahorrar',
  home: 'Hogar',
  profile: 'perfil',
  templates: 'plantillas',
  noAssignment: 'no asignado',
  sort: {
    change: 'para cambiar el orden',
    save: 'guardar orden',
    saved: 'El pedido ha sido guardado.'
  },
  signature: {
    signature: 'Firma',
    pleaseSign: 'Por favor firma',
    clickHereToSign: 'Haga clic aquí para firmar',
    addSignature: 'Agregar firma',
    save: 'Tomar el control',
    delete: 'Borrar'
  },
  formError: {
    min: 'El valor debe ser mayor que {{value}}.',
    min2: 'El valor debe ser mayor o igual a {{value}}.',
    max: 'El valor debe ser menor o igual a {{value}}.',
    requiredBecause: 'Valor requerido porque {{name}} está completado.',
    isRequired: '{{name}} es obligatorio.',
    numberInUse: 'El número de personal ya está en uso.',
    maxlength: '{{name}} no puede contener más de {{value}} caracteres.',
    email: 'Por favor, introduce una dirección de correo electrónico válida.',
    faultyBlinkId: 'Error de identificación parpadeante'
  },
  scan: {
    choose: 'Asignar nueva ID de parpadeo',
    text: 'Escanee el código QR en la tarjeta de registro.',
    scan_now: 'Escanear ahora',
    enter_manually: 'Ingresar manualmente'
  },
  removeImage: 'Imagen clara',
  tickets: { assignee: 'editor', dueTo: 'Válida hasta' },
  ngSelect: {
    typeToSearch: 'Por favor ingrese 3 letras o más.',
    loading: 'cargando datos...',
    notFound: 'No se encontró {{title}}.',
    clearAll: 'Restablecen a los predeterminados'
  },
  help: {
    pageTitle: 'Ayuda',
    text1: 'Nuestro equipo de soporte estará encantado de ayudarle si tiene alguna pregunta:',
    phone: 'teléfono',
    mail: 'correo electrónico',
    information: 'información',
    documentation: 'Documentación en línea',
    documentationText: 'En nuestra documentación en línea encontrará instrucciones y vídeos para todas las áreas importantes de Blink.',
    support: 'Apoyo'
  },
  REGISTRATION: {
    WELCOME: '¡Bienvenido!',
    chooseMethod: '¿Qué método le gustaría utilizar para iniciar sesión?',
    withBlinkId: 'PARPADEO tarjeta de identificación',
    withCredentials: 'Usuario Contraseña',
    REGISTER: 'registrarse',
    ALREADY_REGISTERED: 'Tengo una cuenta de Blink.',
    INVALID_CARD: 'Tarjeta no válida.',
    noBlinkId: 'El código QR escaneado no es un Blink ID válido',
    scanBlinkId: 'Escanear ID parpadeante',
    ERROR: 'No se pudo completar el registro. Proporcione una dirección de correo electrónico o un número de teléfono móvil diferente.',
    NO_INTERNET_EXPLORER: 'Desafortunadamente, Blink no admite Internet Explorer. Utilice Microsoft Edge, Firefox o Chrome. Gracias por su comprensión.',
    loginNow: 'Regístrate ahora',
    help: 'Ayuda con el registro',
    helpText: 'La aplicación Blink Manager le permite gestionar tiempos, empleados y configuración del sistema. ¡El Blink Manager es solo para supervisores, administradores de propiedades, administración y administración! ¡Para grabación de tiempo puro, utilice la “aplicación Blink Time”!<br/><br/>Hay dos métodos que puede utilizar para iniciar sesión:<br/><br/><b>Registrarse con la tarjeta de identificación de Blink</ b><br/><br/>Si tiene una tarjeta de identificación Blink, elija este método. Cuando se registre por primera vez, se le pedirá una dirección de correo electrónico o un número de teléfono móvil. Necesitamos esto para enviarle una contraseña de un solo uso para registrarse. No es necesario recordar la contraseña de un solo uso. Si inicia sesión nuevamente, le enviaremos una nueva contraseña de un solo uso.<br/><br/><b>Inicie sesión con nombre de usuario y contraseña</b><br/><br/>Solo recibirá su nombre de usuario y contraseña previa solicitud de su administrador del sistema. Este método solo está disponible para usuarios con derechos administrativos.<br/><br/><b>Soporte</b><br/><br/>Si tienes problemas para iniciar sesión, escribe un correo electrónico a soporte @blink- time.de o llame al 0911 240 330 0.',
    userOffline: 'Establezca una conexión a Internet para utilizar Blink me.',
    login: 'Regístrate ahora',
    codeError: 'Por favor verifique su entrada e inténtelo nuevamente.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: '¡Felicidades!',
    YOU_DID_IT: 'Lo hiciste.',
    SIGNED_IN: 'Registrado',
    SIGNED_IN_TEXT: 'Se ha registrado correctamente y ahora puede utilizar la aplicación.',
    START_USAGE: 'Ahora puedes usar la aplicación.',
    START: 'comenzar'
  },
  FORGOT_PASSWORD: {
    emailSent: 'Se te ha enviado un correo electrónico con el que podrás cambiar tus datos de acceso.',
    FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
    errors: {
      notAllowed: 'No se puede restablecer una contraseña para este usuario.',
      invalidToken: 'El código para restablecer tu contraseña ha caducado. Solicite un nuevo código.'
    },
    reset: {
      backToRoot: 'Volver a la página de inicio de sesión',
      renewCode: 'Solicitar nuevo código',
      resetPassword: 'restablecer la contraseña',
      success: 'Su contraseña se ha restablecido correctamente.',
      password1: 'Tu nueva contraseña',
      password2: 'Repita la contraseña',
      validationError: '¡La contraseña no cumple con nuestra política de contraseñas!',
      passwordsNotEqual: '¡Las contraseñas no son idénticas! Por favor revisa tu entrada.',
      passwordValidation: 'La contraseña debe tener al menos 6 caracteres y contener una letra, un número y un carácter especial.'
    },
    FORGOT_PASSWORD_TEXT: 'Ingrese su dirección de correo electrónico y el sistema de destino para restablecer su contraseña.',
    FORGOT_PASSWORD_PLACEHOLDER: 'correo electrónico'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'comprobar datos',
    TEXT: 'Si necesitamos restablecer su contraseña, necesitaremos su dirección de correo electrónico y, opcionalmente, su número de teléfono móvil.',
    EMAIL: 'Dirección de correo electrónico',
    MOBILE: 'Número de teléfono móvil'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'Ingrese el código',
    EMAIL_TEXT: 'Ingrese el código que le enviamos a la siguiente dirección de correo electrónico:',
    SMS_TEXT: 'Introduce el código que te enviamos al siguiente número de móvil:',
    NO_CODE: 'No recibí un código.',
    CODE_LENGTH_ERROR: 'El código que ingresó tiene más de seis caracteres. Por favor ingrese su código nuevamente.',
    CODE_SENT: 'El código ha sido enviado.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'Sin código',
    TEXT1: '¿No recibiste un código?',
    RESEND_CODE: 'Enviar Código De nuevo',
    TEXT2: 'Si su dirección de correo electrónico o número de teléfono móvil almacenado en el sistema ha cambiado, comuníquese con su administrador.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'Ingrese su ID y código de Blink. Encontrará toda la información en su tarjeta de registro.',
    textCredentials: 'Su administrador del sistema debe proporcionarle el nombre de usuario y la contraseña.',
    BLINK_ID_INVALID: 'ID de parpadeo incorrecto',
    loginCardNotFound: 'El ID de Blink ya ha sido asignado o no existe',
    WRONG_CREDENTIALS: 'Los datos ingresados son incorrectos. Por favor revisa tu entrada.',
    loginViaBlinkId: 'Iniciar sesión con BlinkID',
    loginViaCredentials: 'Registro con datos de acceso',
    CODE: 'código',
    codePlaceholder: 'Tu código de acceso personal',
    company: 'sistema',
    password: 'contraseña',
    FORGOT_PASSWORD: 'Olvidé mi contraseña',
    username: 'correo electrónico'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'IDENTIFICACIÓN',
    TEXT_1: 'Para que podamos identificarte claramente, te enviaremos un código único por SMS o correo electrónico.',
    TEXT_2: 'Proporcione su número de teléfono móvil o dirección de correo electrónico.'
  },
  update: {
    update: 'Actualizar',
    latestVersion: 'Una nueva version esta disponible. Actualice su aplicación para aprovecharla al máximo.',
    warnVersion: 'Estás utilizando una versión desactualizada de esta aplicación. Actualice lo antes posible.',
    blockVersion: 'Estás utilizando una versión desactualizada de esta aplicación que ya no es compatible. Realice una actualización ahora.'
  },
  inProgress: 'En curso',
  video_offline: 'Los videos no se pueden ver en modo fuera de línea, conéctese a Internet.',
  location_offline: 'El objeto no se guarda sin conexión; conéctese a Internet para recuperar el código QR.',
  invalidObjectCode: 'No hay código objeto válido.',
  invalidValue: 'valor no válido',
  ERROR: {
    ERROR_400: 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde. (Código de error: 400)',
    ERROR_403: 'No tienes permiso para realizar esta acción',
    REFRESH_TOKEN_403: 'Tus datos de acceso ya no son válidos. ¡Por favor inicia sesión nuevamente!',
    ERROR_500: 'Se ha producido un error. Por favor, inténtelo de nuevo más tarde. (Código de error: 500)'
  },
  list: {
    entityPluralDefault: 'Resultados',
    emptyState: 'No existe {{entityPlural}}.',
    noResults: 'No se pudo encontrar {{entityPlural}}.',
    selectAll: 'Seleccionar todo {{entityPlural}}',
    massAction: 'Promoción para {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Seleccionar todo {{count}}',
    massActionDeSelectAllGlobal: 'Deseleccionar todo {{count}}'
  },
  languages: {
    ar: 'Arábica',
    de: 'Alemán',
    es: 'Español',
    fr: 'Francés',
    hr: 'croata',
    it: 'italiano',
    pt: 'portugués',
    ru: 'ruso',
    bg: 'búlgaro',
    en: 'Inglés',
    fa: 'persa',
    el: 'Griego',
    hu: 'húngaro',
    pl: 'Polaco',
    ro: 'rumano',
    tr: 'turco',
    uk: 'ucranio',
    cs: 'checo',
    sk: 'eslovaco'
  },
  now: 'Ahora',
  allCompanies: 'Todos los clientes',
  createDate: 'Fecha de creación',
  company: 'cliente',
  new: 'Nuevo',
  of: 'de',
  request: {
    deleteTitle: 'Eliminar {{item}}',
    confirmDelete: "¿Realmente desea eliminar la entrada '{{itemName}}'?",
    itemAdded: '{{item}} ha sido creado.',
    itemAddedError: 'No se pudo crear {{item}}.',
    itemSaved: '{{item}} se ha guardado.',
    itemSavedError: '{{item}} no se pudo guardar.',
    itemRemoved: '{{item}} ha sido eliminado.',
    itemRemovedError: '{{item}} no se pudo eliminar.'
  },
  news: { pageTitle: 'Noticias', edit: 'Editar informes de noticias' },
  profiles: {
    loginProfiles: 'Perfiles de inicio de sesión',
    add: 'Perfil de inicio de sesión',
    logout: 'Si cierra sesión, se eliminarán todos los datos y perfiles locales. ¿Quiere cancelar su suscripción?',
    login: 'Registro',
    deleteConfirmation: '¿Realmente quieres eliminar el perfil?',
    changed: 'Perfil cambiado exitosamente',
    deleted: 'Perfil eliminado exitosamente'
  },
  deleteAttachment: {
    confirmTitle: 'Eliminar archivo adjunto',
    confirmText: '¿Está seguro de que desea eliminar este archivo adjunto?'
  },
  comments: {
    addComment: 'agregar comentario',
    addCommentInfo: 'El comentario es visible para cualquiera que pueda ver este ticket.',
    comment: 'comentario',
    deleteComment: 'Eliminar comentario',
    deleteCommentConfirmation: '¿Realmente quieres eliminar el comentario?'
  },
  confirm: 'Confirmar',
  firstName: 'Nombre de pila',
  lastName: 'Apellido',
  status: 'estado',
  attachment: 'Adjunto'
};
