export default {
  blinkSlogan: 'Blink - تطبيق لعمال النظافة في المباني',
  all: 'الجميع',
  hello: 'مرحبًا',
  description: 'وصف',
  ok: 'نعم',
  cancel: 'يلغي',
  reset: 'إعادة تعيين إلى الافتراضي',
  edit: 'يحرر',
  finish: 'قفل',
  notice: 'يلاحظ',
  content: 'محتويات',
  back: 'خلف',
  close: 'يغلق',
  backToHome: 'العودة إلى الصفحة الرئيسية',
  proceed: 'متابعة',
  done: 'مستعد',
  delete: 'حذف',
  successful: 'ناجح',
  error: 'خطأ',
  ERROR_OCCURRED: 'حدث خطأ أو البيانات غير صحيحة. حاول مرة اخرى.',
  internetRequired: 'مطلوب الإنترنت',
  configuration: 'إعدادات',
  logo: 'شعار',
  uploadLogo: 'تحميل الشعار',
  deleteLogo: 'حذف الشعار',
  duplicate: 'ينسخ',
  importExport: 'استيراد و تصدير',
  import: 'يستورد',
  doImport: 'يستورد',
  export: 'يصدّر',
  doExport: 'يصدّر',
  excelExport: 'تصدير اكسل',
  excelExportDownloaded: 'تم تنزيل تصدير Excel',
  excelExportError: 'فشل تصدير Excel',
  pdfDownload: 'تحميل PDF',
  pdfDownloaded: 'تم تنزيل ملف PDF',
  configSaved: 'تم حفظ التكوين.',
  send: 'يرسل',
  connecting: 'يتصل...',
  or: 'أو',
  select: 'إختيار - يحدد',
  today: 'اليوم',
  camera: 'آلة تصوير',
  gallery: 'صالة عرض',
  files: 'ملفات',
  upload: 'تحميل الملفات',
  chooseFiles: 'اختر الملفات',
  filesTransferring: 'إرسال الملفات...',
  filesTransferringWait: 'الرجاء الانتظار بينما يتم إرسال الملفات.',
  filesTransferred: 'تم إرسال الملفات بنجاح.',
  download: 'تحميل',
  showInactive: 'إظهار غير نشط',
  copyLink: 'نسخ الوصلة',
  linkCopied: 'تم نسخ الرابط إلى الحافظة.',
  permissions: 'الأذونات',
  email: 'بريد إلكتروني',
  emailConfig: 'تكوين البريد الإلكتروني',
  title: 'عنوان',
  user: 'مستخدم',
  logout: 'تسجيل خروج',
  system: 'نظام',
  appVersion: 'إصدار',
  apiVersionCore: 'إصدار\n(واجهة برمجة التطبيقات الأساسية)',
  apiVersionCheck: 'إصدار\n(التحقق من واجهة برمجة التطبيقات)',
  deviceInfo: 'معلومات الجهاز',
  installBlinkTime: 'تثبيت وميض الوقت',
  installBlinkMe: 'تثبيت وميض لي',
  installing: 'جارٍ تثبيت الإصدار {{version}}...',
  companyRequired: 'حدد العميل',
  companyRequiredInfo: 'يجب عليك اختيار عميل للاستمرار كمسؤول النظام.',
  pleaseChoose: 'اختر من فضلك',
  pleaseFill: 'يرجى ملء',
  noEntitiesFound: 'لا يمكن العثور على أي عناصر.',
  addCustom: 'إنشاء عنصر:',
  typeToSearchText: 'الرجاء إدخال حرفين أو أكثر...',
  loading: 'جارٍ تحميل البيانات...',
  offline: 'غير متصل على الانترنت',
  youAreOffline: 'انت غير متصل!',
  by: 'من',
  date: 'تاريخ',
  time: 'وقت',
  searchTitle: 'يطلب',
  searchTerm: 'مصطلح البحث',
  searchLoginUser: 'موظفين البحث',
  searchLoginUserPlaceholder: 'البحث بالاسم أو الاسم الأول',
  location: 'هدف',
  searchLocation: 'كائن البحث',
  searchLocationPlaceholder: 'البحث حسب اسم الكائن أو الرقم أو العلامات',
  deactivate: 'إلغاء التنشيط',
  active: 'نشيط',
  inactive: 'غير نشط',
  tag: 'يوم - نهار',
  tags: 'العلامات',
  color: 'لون',
  icon: 'أيقونة',
  language: 'لغة',
  general: 'عمومًا',
  yes: 'نعم',
  no: 'لا',
  searchThrough: 'يبحث',
  name: 'اسم العائلة',
  create: 'يخلق',
  save: 'يحفظ',
  home: 'بيت',
  profile: 'حساب تعريفي',
  templates: 'قوالب',
  noAssignment: 'غير مخصصة',
  sort: {
    change: 'لتغيير الترتيب',
    save: 'حفظ النظام',
    saved: 'تم حفظ الطلب.'
  },
  signature: {
    signature: 'إمضاء',
    pleaseSign: 'الرجاء تسجيل الدخول',
    clickHereToSign: 'انقر هنا للتوقيع',
    addSignature: 'إضافة التوقيع',
    save: 'يتولى',
    delete: 'حذف'
  },
  formError: {
    min: 'يجب أن تكون القيمة أكبر من {{value}}.',
    min2: 'يجب أن تكون القيمة أكبر من أو تساوي {{value}}.',
    max: 'يجب أن تكون القيمة أقل من أو تساوي {{value}}.',
    requiredBecause: 'القيمة مطلوبة لأن {{name}} مملوء.',
    isRequired: '{{name}} مطلوب اسم.',
    numberInUse: 'رقم الموظف قيد الاستخدام بالفعل.',
    maxlength: 'لا يمكن أن يحتوي {{name}} على أكثر من {{value}} حرفًا.',
    email: 'يرجى إدخال عنوان بريد إلكتروني صالح.',
    faultyBlinkId: 'خطأ في معرف وميض'
  },
  scan: {
    choose: 'تعيين معرف Blink جديد',
    text: 'قم بمسح رمز QR الموجود على بطاقة التسجيل.',
    scan_now: 'إفحص الآن',
    enter_manually: 'أدخل يدويا'
  },
  removeImage: 'صورة واضحة',
  tickets: { assignee: 'محرر', dueTo: 'صالح حتى' },
  ngSelect: {
    typeToSearch: 'الرجاء إدخال 3 أحرف أو أكثر.',
    loading: 'تحميل البيانات...',
    notFound: 'لم يتم العثور على {{title}}.',
    clearAll: 'إعادة تعيين إلى الافتراضي'
  },
  help: {
    pageTitle: 'يساعد',
    text1: 'سيكون فريق الدعم لدينا سعيدًا بالإجابة على أي أسئلة قد تكون لديكم:',
    phone: 'هاتف',
    mail: 'بريد إلكتروني',
    information: 'معلومات',
    documentation: 'التوثيق عبر الإنترنت',
    documentationText: 'ستجد في وثائقنا عبر الإنترنت تعليمات ومقاطع فيديو لجميع المجالات المهمة في Blink.',
    support: 'يدعم'
  },
  REGISTRATION: {
    WELCOME: 'اهلا وسهلا!',
    chooseMethod: 'ما هي الطريقة التي ترغب في استخدامها لتسجيل الدخول؟',
    withBlinkId: 'وميض بطاقة الهوية',
    withCredentials: 'اسم المستخدم كلمة المرور',
    REGISTER: 'للتسجيل',
    ALREADY_REGISTERED: 'لدي حساب بلينك.',
    INVALID_CARD: 'البطاقة غير صالحة.',
    noBlinkId: 'رمز QR الممسوح ضوئيًا ليس معرف Blink صالحًا',
    scanBlinkId: 'مسح معرف وميض',
    ERROR: 'لا يمكن إكمال التسجيل. الرجاء إدخال عنوان بريد إلكتروني أو رقم هاتف محمول مختلف.',
    NO_INTERNET_EXPLORER: 'لسوء الحظ، Blink لا يدعم Internet Explorer. يرجى استخدام Microsoft Edge أو Firefox أو Chrome. شكرا لتفهمك.',
    loginNow: 'سجل الآن',
    help: 'مساعدة في التسجيل',
    helpText: 'يتيح لك تطبيق Blink Manager إدارة الوقت والموظفين وتكوين النظام. Blink Manager مخصص فقط للمشرفين ومديري الكائنات والإدارة والإدارة! لتتبع الوقت تمامًا، استخدم "تطبيق Blink Time"!<br/><br/>هناك طريقتان يمكنك استخدامهما لتسجيل الدخول:<br/><br/><b>تسجيل الدخول باستخدام بطاقة هوية Blink< / b><br/><br/>إذا كان لديك بطاقة هوية Blink، فاختر هذه الطريقة. عند التسجيل لأول مرة، سوف يطلب منك عنوان البريد الإلكتروني أو رقم الهاتف الخليوي. نحتاج إلى ذلك لنرسل لك كلمة مرور لمرة واحدة للتسجيل. لا تحتاج إلى تذكر كلمة المرور لمرة واحدة. إذا قمت بتسجيل الدخول مرة أخرى، فسنرسل إليك كلمة مرور جديدة تستخدم لمرة واحدة.<br/><br/><b>تسجيل الدخول باستخدام اسم المستخدم وكلمة المرور</b><br/><br/>سيتم إرسال اسم المستخدم وكلمة المرور الخاصين بك سيتم إعطاؤك فقط إذا طلبت ذلك من مسؤول النظام لديك. هذه الطريقة متاحة فقط للمستخدمين الذين لديهم حقوق إدارية.<br/><br/><b>الدعم</b><br/><br/>إذا كانت لديك مشكلات في تسجيل الدخول، أرسل الدعم عبر البريد الإلكتروني @blink-time.de أو اتصل بالرقم 09112403300.',
    userOffline: 'يرجى إنشاء اتصال بالإنترنت لاستخدام Blink me.',
    login: 'سجل الآن',
    codeError: 'يرجى التحقق من الإدخال وحاول مرة أخرى.'
  },
  REGISTRATION_SUCCESS: {
    CONGRATULATIONS: 'تهانينا!',
    YOU_DID_IT: 'أنت فعلت ذلك.',
    SIGNED_IN: 'مسجل',
    SIGNED_IN_TEXT: 'لقد قمت بالتسجيل بنجاح ويمكنك الآن استخدام التطبيق.',
    START_USAGE: 'الآن يمكنك استخدام التطبيق.',
    START: 'يبدأ'
  },
  FORGOT_PASSWORD: {
    emailSent: 'تم إرسال بريد إلكتروني إليك يمكنك من خلاله تغيير بيانات الوصول الخاصة بك.',
    FORGOT_PASSWORD: 'نسيت كلمة السر؟',
    errors: {
      notAllowed: 'لا يمكن إعادة تعيين كلمة المرور لهذا المستخدم.',
      invalidToken: 'لقد انتهت صلاحية رمز إعادة تعيين كلمة المرور الخاصة بك. الرجاء طلب رمز جديد.'
    },
    reset: {
      backToRoot: 'العودة إلى صفحة تسجيل الدخول',
      renewCode: 'طلب رمز جديد',
      resetPassword: 'إعادة تعيين كلمة المرور',
      success: 'لقد تم إعادة تعيين كلمة المرور الخاصة بك بنجاح.',
      password1: 'كلمة سرك الجديدة',
      password2: 'اعد كلمة السر',
      validationError: 'كلمة المرور لا تتوافق مع سياسة كلمة المرور الخاصة بنا!',
      passwordsNotEqual: 'كلمات المرور ليست متطابقة! يرجى التحقق من الإدخال الخاص بك.',
      passwordValidation: 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل وتحتوي على حرف ورقم ورمز خاص.'
    },
    FORGOT_PASSWORD_TEXT: 'الرجاء إدخال عنوان بريدك الإلكتروني والنظام المستهدف لإعادة تعيين كلمة المرور الخاصة بك.',
    FORGOT_PASSWORD_PLACEHOLDER: 'بريد إلكتروني'
  },
  CHECK_DATA_PAGE: {
    PAGE_TITLE: 'التحقق من البيانات',
    TEXT: 'إذا كنا بحاجة إلى إعادة تعيين كلمة المرور الخاصة بك، فنحن بحاجة إلى عنوان بريدك الإلكتروني ورقم هاتفك المحمول بشكل اختياري.',
    EMAIL: 'عنوان البريد الإلكتروني',
    MOBILE: 'رقم الهاتف الجوال'
  },
  ENTER_CODE_PAGE: {
    PAGE_TITLE: 'ادخل الرمز',
    EMAIL_TEXT: 'أدخل الرمز الذي أرسلناه إلى عنوان البريد الإلكتروني التالي:',
    SMS_TEXT: 'أدخل الرمز الذي أرسلناه إلى رقم الجوال التالي:',
    NO_CODE: 'لم أتلق رمزا.',
    CODE_LENGTH_ERROR: 'الرمز الذي تم إدخاله أطول من ستة أحرف. الرجاء إعادة إدخال الرمز الخاص بك.',
    CODE_SENT: 'تم إرسال الرمز.'
  },
  NO_CODE_PAGE: {
    PAGE_TITLE: 'لا يوجد رمز',
    TEXT1: 'لم تحصل على رمز؟',
    RESEND_CODE: 'إعادة إرسال الرمز',
    TEXT2: 'إذا تغير عنوان بريدك الإلكتروني أو رقم هاتفك المحمول المخزن في النظام، فيرجى الاتصال بالمسؤول.'
  },
  REGISTER_MANUALLY_PAGE: {
    TEXT: 'الرجاء إدخال معرف ورمز Blink الخاص بك. ستجد كافة المعلومات على بطاقة التسجيل الخاصة بك.',
    textCredentials: 'يجب أن يزودك مسؤول النظام باسم المستخدم وكلمة المرور.',
    BLINK_ID_INVALID: 'خطأ في معرف وميض',
    loginCardNotFound: 'تم تعيين معرف Blink بالفعل أو أنه غير موجود',
    WRONG_CREDENTIALS: 'البيانات المدخلة غير صحيحة. يرجى التحقق من الإدخال الخاص بك.',
    loginViaBlinkId: 'تسجيل الدخول باستخدام BlinkID',
    loginViaCredentials: 'التسجيل مع بيانات الوصول',
    CODE: 'شفرة',
    codePlaceholder: 'رمز الوصول الشخصي الخاص بك',
    company: 'نظام',
    password: 'كلمة المرور',
    FORGOT_PASSWORD: 'لقد نسيت كلمة السر',
    username: 'بريد إلكتروني'
  },
  REGISTER_SET_AUTH_MODE_PAGE: {
    PAGE_TITLE: 'بطاقة تعريف',
    TEXT_1: 'حتى نتمكن من التعرف عليك بوضوح، سنرسل لك رمزًا لمرة واحدة عبر الرسائل القصيرة أو البريد الإلكتروني.',
    TEXT_2: 'الرجاء إدخال رقم هاتفك المحمول أو عنوان البريد الإلكتروني.'
  },
  update: {
    update: 'للتحديث',
    latestVersion: 'إصدار جديد متوفر. يرجى تحديث التطبيق الخاص بك للاستفادة من جميع المزايا.',
    warnVersion: 'أنت تستخدم نسخة قديمة من هذا التطبيق. يرجى التحديث في أقرب وقت ممكن.',
    blockVersion: 'أنت تستخدم إصدارًا قديمًا من هذا التطبيق ولم يعد مدعومًا. يرجى إجراء التحديث الآن.'
  },
  inProgress: 'في تَقَدم',
  video_offline: 'لا يمكن مشاهدة مقاطع الفيديو في وضع عدم الاتصال، يرجى الاتصال بالإنترنت.',
  location_offline: 'لم يتم حفظ الكائن في وضع عدم الاتصال، يرجى الاتصال بالإنترنت لاسترداد رمز الاستجابة السريعة.',
  invalidObjectCode: 'لا يوجد رمز كائن صالح.',
  invalidValue: 'قيمة غير صالحة',
  ERROR: {
    ERROR_400: 'حدث خطأ. الرجاء معاودة المحاولة في وقت لاحق. (رمز الخطأ: 400)',
    ERROR_403: 'ليس لديك إذن لتنفيذ هذا الإجراء',
    REFRESH_TOKEN_403: 'بيانات الوصول الخاصة بك لم تعد صالحة. الرجاء تسجيل الدخول مرة أخرى!',
    ERROR_500: 'حدث خطأ. الرجاء معاودة المحاولة في وقت لاحق. (رمز الخطأ: 500)'
  },
  list: {
    entityPluralDefault: 'نتائج',
    emptyState: 'لا يوجد {{entityPlural}} موجود.',
    noResults: 'لا يمكن العثور على {{entityPlural}}.',
    selectAll: 'حدد الكل {{entityPlural}}',
    massAction: 'الترويج لـ {{count}} {{entityPlural}}',
    massActionAllGlobal: 'حدد الكل {{count}}',
    massActionDeSelectAllGlobal: 'قم بإلغاء تحديد الكل {{count}}'
  },
  languages: {
    ar: 'عربي',
    de: 'ألماني',
    es: 'الأسبانية',
    fr: 'فرنسي',
    hr: 'الكرواتية',
    it: 'ايطالي',
    pt: 'البرتغالية',
    ru: 'الروسية',
    bg: 'البلغارية',
    en: 'إنجليزي',
    fa: 'اللغة الفارسية',
    el: 'اليونانية',
    hu: 'المجرية',
    pl: 'تلميع',
    ro: 'روماني',
    tr: 'اللغة التركية',
    uk: 'الأوكرانية',
    cs: 'التشيكية',
    sk: 'السلوفاكية'
  },
  now: 'الآن',
  allCompanies: 'جميع العملاء',
  createDate: 'تاريخ الإنشاء',
  company: 'عميل',
  new: 'جديد',
  of: 'من',
  request: {
    deleteTitle: 'حذف {{item}}',
    confirmDelete: "هل تريد حقًا حذف الإدخال '{{itemName}}'؟",
    itemAdded: 'تم إنشاء {{item}}.',
    itemAddedError: 'لا يمكن إنشاء {{item}}.',
    itemSaved: 'تم حفظ {{item}}.',
    itemSavedError: 'تعذر حفظ {{item}}.',
    itemRemoved: 'تم حذف {{item}}.',
    itemRemovedError: 'لا يمكن حذف {{item}}.'
  },
  news: { pageTitle: 'أخبار', edit: 'تحرير التقارير الإخبارية' },
  profiles: {
    loginProfiles: 'ملفات تعريف تسجيل الدخول',
    add: 'الملف الشخصي لتسجيل الدخول',
    logout: 'إذا قمت بتسجيل الخروج، فسيتم حذف جميع البيانات والملفات الشخصية المحلية! هل تريد إلغاء الاشتراك؟',
    login: 'يسجل',
    deleteConfirmation: 'هل تريد حقًا حذف الملف الشخصي؟',
    changed: 'تم تغيير الملف الشخصي بنجاح',
    deleted: 'تم حذف الملف الشخصي بنجاح'
  },
  deleteAttachment: {
    confirmTitle: 'حذف المرفق',
    confirmText: 'هل أنت متأكد أنك تريد حذف هذا المرفق؟'
  },
  comments: {
    addComment: 'أضف تعليق',
    addCommentInfo: 'التعليق مرئي لأي شخص يمكنه مشاهدة هذه التذكرة.',
    comment: 'تعليق',
    deleteComment: 'حذف تعليق',
    deleteCommentConfirmation: 'هل تريد حقا حذف التعليق؟'
  },
  confirm: 'يتأكد',
  firstName: 'الاسم الأول',
  lastName: 'اسم العائلة',
  status: 'حالة',
  attachment: 'مرفق'
};
