import { inject, Injectable } from '@angular/core';
import { ApiManager, BlinkService, TypedQuery } from '../../shared';
import { map } from "rxjs/operators";
import { displayName } from "../display-name";
import { firstValueFrom, Observable } from 'rxjs';
import { CompanyRepository } from '../company/company.repository';
import { UiInputSelectItem } from '@blink/ui';
import { BlinkEmployee } from '@blink/shared-blink-types';

@Injectable({ providedIn: 'root' })
export class BlinkEmployeeApi {
  companyRepository = inject(CompanyRepository);
  private api = this.apiManager
    .createApiForEndpoint<BlinkEmployee>(
      'odata/v1',
      'Employees',
      BlinkService.Core
    );
  private apiV2 = this.apiManager
    .createApiForEndpoint<BlinkEmployee>(
      'odata/v2',
      'Employees',
      BlinkService.Core
    );

  constructor(private apiManager: ApiManager) {
  }

  getEmployeesForCheck(): Promise<BlinkEmployee[]> {
    const query: TypedQuery<BlinkEmployee> = {
      filter: {
        CompanyId: this.companyRepository.getActiveCompanyId()
      },
      select: [
        'Id',
        'LoginUserId',
        'CompanyId',
        'Number',
        'CostCenterNumber',
        'ExternalId',
        'FirstName',
        'LastName'
      ],
      expand: {
        LoginUser: {
          select: ['Id', 'Active']
        }
      }
    };
    return firstValueFrom(this.api.get<BlinkEmployee[]>(query).pipe(map(employees => employees.map(employee => ({
      ...employee,
      displayName: displayName(employee.FirstName, employee.LastName)
    })))));
  }

  getEmployeeForCheckFilter(employeeId: number) {
    const query: TypedQuery<BlinkEmployee> = {
      key: employeeId,
      select: [
        'Id',
        'LoginUserId',
        'CompanyId',
        'Number',
        'CostCenterNumber',
        'ExternalId',
        'FirstName',
        'LastName'
      ]
    };
    return firstValueFrom(this.api.get<BlinkEmployee>(query).pipe(
      map(employee => ({
        ...employee,
        displayName: displayName(employee.FirstName, employee.LastName)
      }))
    ));
  }

  employeeAutocompleteSearch = (companyId?: number) => (term: string): Observable<Array<UiInputSelectItem>> => {
    return this.autocompleteSearch(term, companyId);
  }

  employeeAutocompleteSearchLoginUserId = (companyId?: number) => (term: string): Observable<Array<UiInputSelectItem>> => {
    return this.autocompleteSearch(term, companyId, 'loginUserId');
  }

  private autocompleteSearch(term: string, companyId?: number, idType: 'id' | 'loginUserId' = 'id'): Observable<Array<UiInputSelectItem>> {
    const query: TypedQuery<BlinkEmployee> = {
      filter: {
        LoginUser: {
          Active: true
        },
        and: {
          or: [
            { FirstName: { contains: term } },
            { LastName: { contains: term } },
            { Number: { contains: term } },
            { LoginUser: { LoginCards: { any: { Number: { contains: term } } } } }
          ]
        }
      },
      select: [
        'Id',
        'LoginUserId',
        'FirstName',
        'LastName'
      ],
      expand: {
        LoginUser: {
          select: ['Id', 'Active'],
          expand: [
            {
              LoginCards: {
                select: ['Number']
              }
            }
          ]
        },
      }
    };

    if (companyId) {
      query['filter'].CompanyId = companyId
    } else if (this.companyRepository.getActiveCompanyId()) {
      query['filter'].CompanyId = this.companyRepository.getActiveCompanyId();
    }

    return this.apiV2.get<BlinkEmployee[]>(query).pipe(
      map(employees =>
        employees.map(employee => ({
          id: idType === 'id' ? employee.Id : employee.LoginUserId,
          name: `${employee.LastName.trim()}, ${employee.FirstName.trim()}`
        } as UiInputSelectItem))
      ))
  }
}
